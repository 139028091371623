<template>
    <div class="bg-white rounded-4 px-2">
        <ul class="nav nav-tabs pt-3 px-2 mb-4 d-xl-none">
            <!-- <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'IndexSetting' }">حسابي</router-link>
            </li> -->
            <div class="row">
                <div class="col-6">
                    <li class="nav-item">
                    <router-link class="nav-link active" :to="{ name: 'OfficeSetting' }"
                        >المكاتب المسجل بها</router-link
                    >
                    </li>
                    
                </div>
                <div class="col-6">
                    <li class="nav-item">
                        <router-link class="nav-link active" :to="{ name: 'OfficeUpdate' }"
                            >تعديل بيانات المكتب</router-link
                        >
                    </li>
                </div>
            </div>
            <!-- <li class="nav-item">
            <a class="nav-link" href="/account/notifications.html"
                >الإشعارات</a
            >
            </li>
            <li class="nav-item">
            <a class="nav-link" href="/account/help.html">المساعدة</a>
            </li>
            <li class="nav-item">
            <a class="nav-link" href="/account/privacy-policy.html"
                >سياسة الخصوصية</a
            >
            </li> -->
        </ul>
        <div class="row">
            <div
            class="col-auto border-end py-2 d-none d-xl-block"
            style="width: 320px"
            >
            <ul class="list-unstyled mb-0">
                <!-- <li class="acc-nav-item">
                <i class="bi bi-person-fill"></i>
                <div>
                    <router-link :to="{ name: 'IndexSetting' }" class="link-overlay">حسابي</router-link>
                    <br />
                    <small
                    >و سأعرض مثال حي لهذا، من منا لم يتحمل جهد بدني شاق إلا
                    من أجل الحصول على ميزة أو</small
                    >
                </div>
                </li> -->
                <li class="acc-nav-item active">
                <i class="bi bi-diagram-2-fill"></i>
                <div>
                    <router-link :to="{ name: 'OfficeSetting' }" class="link-overlay"
                    >المكاتب المسجل بها</router-link
                    >
                    <br />
                    <small>
                        يتم عرض المكاتب المسجل بها حسابك، ويمكن اختيار مكتب أخر وعند تسجيل الدخول سيتم الدخول لهذا المكتب
                    </small>
                </div>
                </li>
                <li class="acc-nav-item">
                <i class="bi bi-app-indicator"></i>
                <div>
                    <router-link :to="{ name: 'OfficeUpdate' }" class="link-overlay"
                    >تعديل بيانات المكتب</router-link
                    >
                    <br />
                    <small>
                        يمكن تعديل بيانات المكتب
                    </small>
                </div>
                </li>
                <!-- 
                <li class="acc-nav-item">
                <i class="bi bi-info-circle"></i>
                <div>
                    <a href="/account/help.html" class="link-overlay"
                    >المساعدة</a
                    >
                    <br />
                    <small
                    >و سأعرض مثال حي لهذا، من منا لم يتحمل جهد بدني شاق إلا
                    من أجل الحصول على ميزة أو</small
                    >
                </div>
                </li>
                <li class="acc-nav-item">
                <i class="bi bi-shield-shaded"></i>
                <div>
                    <a
                    href="/account/privacy-policy.html"
                    class="link-overlay"
                    >سياسة الخصوصية</a
                    >
                    <br />
                    <small
                    >و سأعرض مثال حي لهذا، من منا لم يتحمل جهد بدني شاق إلا
                    من أجل الحصول على ميزة أو</small
                    >
                </div>
                </li> -->
            </ul>
                <!-- <div class="px-3 mt-4">
                    <address>
                    <p class="mb-1">و سأعرض مثال لعنوان</p>
                    <small class="text-muted">
                        و سأعرض مثال حي لهذا، من منا لم يتحمل جهد بدني شاق إلا من
                        أجل الحصول على ميزة أو
                    </small>
                    </address>
                    <label for="lang" class="text-muted mb-1 visually-hidden"
                    >اللغة</label
                    >
                    <select name="" id="lang" class="form-select py-2 mb-2">
                    <option value="1">العربية (السعودية)</option>
                    </select>
                    <div class="form-check form-switch mb-2">
                    <input
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="darkMod"
                    />
                    <label class="form-check-label" for="darkMod"
                        >الوضع الداكن</label
                    >
                    </div>
                    <div class="form-check form-switch">
                    <input
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="notifications"
                    />
                    <label class="form-check-label" for="notifications"
                        >الإشعارات</label
                    >
                    </div>
                </div> -->
            </div>
            <div class="col p-4">
            <h2 class="fs-4 fw-bold mb-4">المكاتب المسجل بها</h2>
            <div class="table-responsive d-none d-sm-block">
                <table class="table table-borderless bg-white rounded-4">
                <thead class="border-bottom">
                    <tr>
                    <th scope="col">#</th>
                    <th scope="col">اسم المكتب</th>
                    <th scope="col">الإفتراضي</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="office,index in offices">
                    <th scope="row">{{ index + 1 }}</th>
                    <td>{{office.name}}</td>
                    <td>
                        
                        <input type="hidden" v-if="office.default == 1 ? activeDefault = office.id : null">
                        <input type="radio" name="default" id="default" value="1" @click.prevent="updateOffice(office.id)" :checked="office.default == 1">
                    </td>
                    </tr>
                </tbody>
                </table>
            </div>

            <div class="card bg-white rounded-2 mb-2 d-lg-none .d-xl-block" v-for="office, index in offices">
                <div class="card-header bg-white">
                    <div class="row">
                        <div class="col-6">
                            ( {{ index + 1 }} )
                        </div>
                        <div class="col-4">
                            <lable v-if="office.default == 1" class="text-success"><strong> الإفتراضي </strong></lable>
                        </div>
                        <div class="col-2">
                            <input type="hidden" v-if="office.default == 1 ? activeDefault = office.id : null">
                            <input type="radio" name="defaultOffice" id="defaultOffice" value="1" @click.prevent="updateOffice(office.id)" :checked="office.default == 1">
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <blockquote class="blockquote mb-0">
                    <div class="text-primary">اسم المكتب <span class="text-dark">{{office.name}}</span></div>                                  
                    </blockquote>
                </div>
                </div>
            </div>
        </div>
        </div>
</template>

<script>
import {mapGetters} from 'vuex';
import axios from 'axios'

export default{
    name: 'OfficeSetting',
    data(){
        return {
            activeDefault: null,
            offices:null,
            user_id: null,
        }
    },
    computed:{
        ...mapGetters({
            authenticated: 'auth/authenticated',
            user: 'auth/user'
        }),
    },
    created(){
        this.getOffices()
    },
    methods: {
            async getOffices(){
                let loader = this.$loading.show({
                color: '#0d6efd',
                loader: 'dots',
                backgroundColor: '#e4e4e4',
            });
        
            const user = await axios.get(`${localStorage.getItem('default_office')}/user/` + this.user.id)
            this.offices = user.data.data[0].offices
            this.user_id = this.user.id
            loader.hide();
            // console.log(user.data.data[0].offices)
        },
        async updateOffice(officeId){
            // alert(this.user_id)
            // alert(officeId)
            const officeUpdate = await axios.get(`${localStorage.getItem('default_office')}/setOfficeDefault?office_id=${officeId}&user_id=` + this.user_id)
            this.getOffices()
            
        }
    }
}

</script>