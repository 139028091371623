<template>
    <div>
        <div class="p-4 w-full text-center bg-white rounded-lg border shadow-md sm:p-8 dark:bg-gray-800 dark:border-gray-700 mt-2">
            <ion-icon class="w-6 h-6 dark:text-white" name="person-outline"></ion-icon>
            <h5 class="mb-2 text-3xl font-bold text-gray-900 dark:text-white"> مستأجر رقم # {{ rent.customer.id }}</h5>
            <p class="mb-2 text-base text-gray-500 sm:text-lg dark:text-gray-400">الهوية: {{ rent.customer.id_card }}</p>
            <p class="mb-2 text-base text-gray-500 sm:text-lg dark:text-gray-400">الإسم: {{ rent.customer.name }}</p>
            <p class="mb-2 text-base text-gray-500 sm:text-lg dark:text-gray-400">الجوال: {{ rent.customer.mobile }}</p>
            <div class="justify-center items-center space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
                
                <!-- <div class="text-left m-2">
                    <div class="relative">
                        <input type="text" id="disabled_filled" class="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " disabled />
                        <label for="disabled_filled" class="absolute text-sm text-gray-400 dark:text-gray-500 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4"></label>
                    </div>
                </div> -->
            </div>
        </div>

        
        <div class="grid grid-cols-2 gap-4">
            <div class="...">
                    <div class="p-4 w-full text-center bg-white rounded-lg border shadow-md sm:p-8 dark:bg-gray-800 dark:border-gray-700 mt-2">
                    <ion-icon class="w-6 h-6 dark:text-white" name="home-outline"></ion-icon>
                    <h5 class="mb-2 text-3xl font-bold text-gray-900 dark:text-white"> العقار رقم # {{ rent.realestate.id }}</h5>
                    <p v-if="rent.realestate.type == 'building'" class="mb-4 text-base text-gray-500 sm:text-lg dark:text-gray-400">نوع الوحدة: عمارة</p>
                    <p v-if="rent.realestate.type == 'villa'" class="mb-4 text-base text-gray-500 sm:text-lg dark:text-gray-400">نوع الوحدة: فيلا</p>
                    <p v-if="rent.realestate.type == 'apartment'" class="mb-4 text-base text-gray-500 sm:text-lg dark:text-gray-400">نوع الوحدة: شقة</p>
                    <p v-if="rent.realestate.type == 'shop'" class="mb-4 text-base text-gray-500 sm:text-lg dark:text-gray-400">نوع الوحدة: محل</p>
                    <p class="mb-3 text-base text-gray-500 sm:text-lg dark:text-gray-400">سعر التأجير: {{ rent.realestate.price }}</p>
                    <p class="mb-3 text-base text-gray-500 sm:text-lg dark:text-gray-400">العنوان: {{ rent.realestate.city + ' - ' + rent.realestate.neighborhood + ' - ' + rent.realestate.street}}</p>
                    <div class="justify-center items-center space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
                        
                        <!-- <div class="text-left m-2">
                            <div class="relative">
                                <input type="text" id="disabled_filled" class="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " disabled />
                                <label for="disabled_filled" class="absolute text-sm text-gray-400 dark:text-gray-500 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4"></label>
                            </div>
                        </div>
                        <div class="text-left m-2">
                            <div class="relative">
                                <input type="text" id="disabled_filled" class="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " disabled />
                                <label for="disabled_filled" class="absolute text-sm text-gray-400 dark:text-gray-500 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4"></label>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="...">
                <div class="p-4 w-full text-center bg-white rounded-lg border shadow-md sm:p-8 dark:bg-gray-800 dark:border-gray-700 mt-2">
                <ion-icon class="w-6 h-6 dark:text-white" name="person-circle-outline"></ion-icon>
                <h5 class="mb-2 text-3xl font-bold text-gray-900 dark:text-white"> المالك رقم # {{ rent.realestate.owner.id }}</h5> 
                <p class="mb-2 text-base text-gray-500 sm:text-lg dark:text-gray-400">صاحب الوحدة: {{ rent.realestate.owner.name }}</p>
                <p class="mb-2 text-base text-gray-500 sm:text-lg dark:text-gray-400">الهوية: {{ rent.realestate.owner.id_card }}</p>
                <p class="mb-2 text-base text-gray-500 sm:text-lg dark:text-gray-400">الجوال: {{ rent.realestate.owner.mobile }}</p>
                <p class="mb-2 text-base text-gray-500 sm:text-lg dark:text-gray-400">العنوان: {{ rent.realestate.owner.address }}</p>
                <div class="justify-center items-center space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
                    
                    <!-- <div class="text-left m-2">
                        <div class="relative">
                            <input type="text" id="disabled_filled" class="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " disabled />
                            <label for="disabled_filled" class="absolute text-sm text-gray-400 dark:text-gray-500 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4"></label>
                        </div>
                    </div>
                    <div class="text-left m-2">
                        <div class="relative">
                            <input type="text" id="disabled_filled" class="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " disabled />
                            <label for="disabled_filled" class="absolute text-sm text-gray-400 dark:text-gray-500 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4"></label>
                        </div>
                    </div> -->
                </div>
            </div>
            </div>
        </div>

        
    </div>

</template>

<script>
    import axios from 'axios';

    export default{
        name: 'RentDetails',
        data(){
            return {
               rent: {}, 
               fullPage: false,
            }
        },
        created(){
            this.getRent();
        },
        methods: {
            getRent(){
                let loader = this.$loading.show({
                    color: '#0d6efd',
                    loader: 'dots',
                    backgroundColor: '#e4e4e4',
                });
                let url = `${localStorage.getItem('default_office')}/rentOrder/${this.$route.params.id}`;
                axios.get(url).then(response => {
                // console.log(response.data.data);
                this.rent = response.data.data
                loader.hide();
            });
            }
        }
    }
</script>

<style>

</style>