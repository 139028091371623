<template>
  
</template>

<script>

export default{
    name: 'IndexSetting',
    data(){
        return {

        }
    }
}

</script>