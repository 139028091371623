<template>
    <div class="w-full px-6 py-6 pt-1 pb-0 mx-auto">
            
    </div>
</template>

<script>
import axios from 'axios'

export default{
    name: 'MyOffices',
    data(){
        return {
            offices: {}
        }
    },

    methods: {
        async getMyOffices(){
            let response = axios.get('user/myOffices')
            // console.log(response);
            this.offices = response.data.data.user
        }
    }
}
</script>