<template>
    <div class="container mx-auto mt-0 bg-clip-content p-4 pt-1  border-1 border-gray-100 border-dashed">
        <a href="#" class="block p-2 max-w-sm bg-gray rounded-lg border border-gray-200 shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
            <h5 class="mb-1  text-2xl font-bold tracking-tight text-gray-900 dark:text-white">مدينة جديد</h5>
        </a>
    <div class="p-6">
        <form @submit.prevent="storeCity" novalidate>
            <div class="mb-3">
                <label for="name" class="">اسم المدينة</label>
                <input type="text" placeholder="" id= "name" v-model="city.name" class="focus:shadow-soft-primary-outline text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none">
            </div>
            
            <div>
                <button type="submit" class="mr-3 inline-block px-6 py-3 font-bold text-center bg-gradient-to-tl from-purple-700 to-pink-500 uppercase align-middle transition-all rounded-lg cursor-pointer leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs text-white">حفظ</button>
            </div>
        </form>
    </div>
</div>
</template>

<script>
import axios from 'axios';

export default{
    name: 'AddCity',
    data(){ 
        return {
            city: {},
            fullPage: false,
        }
    },
    created(){
        let loader = this.$loading.show({
                color: '#0d6efd',
                loader: 'dots',
                backgroundColor: '#e4e4e4',
            });
        this.loadDefault();
        loader.hide();
    },
    methods: {
        loadDefault(){
            this.city.name = '';
        },
        async storeCity(){
            let formData = new FormData();
            formData.append('name', this.city.name);
            
            let url = `${localStorage.getItem('default_office')}/city`;
            await axios.post(url, formData).then(response => {
                this.$swal.fire({
                    toast: true,
                    position: 'top',
                    icon: 'success',
                    title: 'تم حفظ البيانات بنجاح',
                    showConfirmButton: false,
                    timer: 2500,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })
                this.city.name = '';
            });
        }
    },
    
}
</script>

<style>

</style>