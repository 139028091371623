<template>
    <div class="login-page">
        <div class="container">
            <div class="row flex-row-reverse">
                <div class="col-lg-6 position-relative">
                    <div class="h-100 d-lg-flex align-items-center rounded-4" style="background-color: rgba(255, 255, 255, 0.5); box-shadow: 2px 0 10px rgb(0 0 0 / 10%)">
                        <div class="login-form-container py-5 w-100 p-4">
                            <div class="text-center mb-5">
                                <img src="assets/images/logo-base.png" width="171" height="54" alt="Bill"/>
                            </div>
                            <li v-for="error in errors" class="text-danger fs-6">{{ error[0] }}</li>
                            <form @submit.prevent="handelRegister()">
                                <fieldset class="mb-4">
                                    <!-- <legend class="fs-6">بيانات المكتب</legend> -->
                                    
                                        <input
                                            v-model="office.name"
                                            placeholder="*اسم المكتب"
                                            aria-label="اسم المكتب"
                                            class="form-control text-start mb-2 bg-light"
                                        />
                                        <div class="d-sm-flex gap-2">
                                        <input
                                                v-model="office.mobile"
                                                type="tel"
                                                placeholder="9665 رقم الجوال*"
                                                aria-label="رقم الجوال"
                                                class="form-control text-start mb-2 bg-light"
                                        />
                                        <input
                                                v-model="user.password"
                                                type="password"
                                                placeholder="*كلمة المرور"
                                                aria-label="كلمة المرور"
                                                class="form-control text-start mb-2 bg-light"
                                        />
                                        <!-- <input
                                                v-model="office.whatsapp"
                                                type="tel"
                                                placeholder="واتساب"
                                                aria-label="واتساب"
                                                required=""
                                                class="form-control text-start mb-2 bg-light"
                                        /> -->
                                    </div>
                                    <input
                                            v-model="office.address"
                                            placeholder="*عنوان المكتب"
                                            aria-label="عنوان المكتب"
                                            
                                            class="form-control text-start mb-2 bg-light"
                                    />
                                    <input
                                                v-model="office.mobile"
                                                type="hidden"
                                                placeholder="رقم جوال المستخدم"
                                                aria-label="رقم جوال المستخدم"
                                                class="form-control text-start mb-2 bg-light"
                                        />
                                </fieldset>
                                <!-- <fieldset class="mb-4">
                                    <legend class="fs-6">بيانات المستخدم</legend>
                                    <div class="d-sm-flex gap-2">
                                        <input
                                                v-model="user.name"
                                                placeholder="اسم المستخدم"
                                                aria-label="اسم المستخدم"
                                                required=""
                                                class="form-control text-start mb-2 bg-light"
                                        />
                                        <input
                                                v-model="user.mobile"
                                                type="hidden"
                                                placeholder="رقم جوال المستخدم"
                                                aria-label="رقم جوال المستخدم"
                                                required=""
                                                class="form-control text-start mb-2 bg-light"
                                        />
                                    </div>
                                    <div class="d-sm-flex gap-2">
                                        <input
                                                v-model="user.email"
                                                type="email"
                                                placeholder="البريد الإلكتروني"
                                                aria-label="البريد الإلكتروني"
                                                required=""
                                                class="form-control text-start mb-2 bg-light"
                                        />
                                        
                                    </div>
                                </fieldset> -->
                                <button type="submit" class="btn btn-arrow btn-primary w-75">
                                    <span>إنشاء حساب</span>
                                    <i class="bi bi-arrow-left"></i>
                                </button>
                            </form>
                            <p class="text-muted mt-3">
                                <small>
                                    لديك الحساب؟ <router-link :to="{ name: 'Login' }">تسجيل الدخول</router-link>
                                </small>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 d-lg-block align-self-center pb-lg-4 pb-xl-0 d-none">
                    <img src="assets/images/login-graphics.png" alt="Login Image" class="img-fluid" width="1622"
                        height="2020" style="width: 520px;"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    
    export default{
        name: 'Register',
        data(){
            return {
                office:{
                    name: '',
                    status: 1,
                },
                user:{
                    name: '',
                    mobile: '',
                    email: '',
                    password: '',
                },
                isOTP: false,
                OTP: {
                    first: null,
                    second: null,
                    third: null,
                    forth: null,
                },
                errors: null
            }
        },
        methods: {
            async sendOTP(){
                let response = await axios.post('auth/sendOTP')
                // console.log(split(response.data.OTP))
            },

            async handelRegister(){
                // this.sendOTP()
                // this.isOTP = true

                try {
                    this.errors = []
                    await axios.post('office/register', this.office).then(response => {
                        if(!response.data.error){
                            axios.post('auth/register', {
                            'name': this.user.name,
                            'mobile': this.office.mobile,
                            'email': this.user.email,
                            'password': this.user.password,
                            'office_id': response.data.data.id,
                            'status': 1
                            }).then(userResponse => {
                                // console.log(userResponse)
                                if(!userResponse.data.error){
                                    this.$swal.fire({
                                    toast: true,
                                    position: 'top',
                                    icon: 'success',
                                    title: 'تم إنشاء الحساب بنجاح',
                                    showConfirmButton: false,
                                    timer: 2500,
                                    timerProgressBar: true,
                                    didOpen: (toast) => {
                                        toast.addEventListener('mouseenter', Swal.stopTimer)
                                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                                    }
                                })
                                this.$router.push('/'); 
                                }else{
                                    this.$swal.fire({
                                        toast: true,
                                        position: 'top',
                                        icon: 'success',
                                        title: 'هذا المستخدم مسجل من قبل في مكتب عقاري آخر، يرجى استخدام نفس كلمة المرور القديمة',
                                        showConfirmButton: false,
                                        timer: 8500,
                                        timerProgressBar: true,
                                        didOpen: (toast) => {
                                            toast.addEventListener('mouseenter', Swal.stopTimer)
                                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                                        }
                                    })
                                    this.$router.push('/'); 
                                }
                            })
                            
                        }else{
                            // this.errors = response.data.error
                            this.$swal.fire({
                                toast: true,
                                position: 'top',
                                icon: 'error',
                                title: response.data.error,
                                showConfirmButton: false,
                                timer: 2500,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', Swal.stopTimer)
                                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                                }
                            })
                        }
                        
                    })
                    
                } catch (error) {
                    // console.log(error.response.data)
                    this.errors = error.response.data.errors
                }
            },
            computed: {
                ValidationError(){
                    this.errors = error.response.data.errors
                }
            }
        }
    }
</script>