<template>
        <div
          class="d-flex flex-md-row flex-column gap-3 align-items-md-center justify-content-between"
        >
        <div class="d-flex align-self-start bg-white rounded-3">
            <router-link :to="{ name: 'IndexContract' }" class="btn btn-link link-dark text-decoration-none">عقود</router-link>
            <router-link :to="{ name: 'IndexService' }" class="btn btn-primary">خدمات</router-link>
        </div>
        <!-- <input
                type="search"
                placeholder="بحث"
                class="form-control border-white w-auto ms-md-auto"
        /> -->
        <div>
            <!-- <button class="btn py-2 btn-success mx-1">
                <i class="bi bi-file-earmark-spreadsheet-fill fs-5"></i>
            </button> -->
            <router-link :to="{ name: 'AddService' }" class="btn py-2 btn-primary mb-2">
                <i class="bi bi-plus-circle fs-5"></i>
            </router-link>
        </div>
    </div>
    <div class="table-responsive-lg mt-4 d-none d-sm-block">
        <table class="table table-borderless bg-white rounded-4">
            <thead class="border-bottom">
            <tr>
                <th scope="col">ID</th>
                <th scope="col">الخدمة</th>
                <th scope="col">التعديل</th>
            </tr>
            </thead>
            <tbody>
              <tr v-for="(service, index) in services.data" :key="service.id">
                  <th scope="row">{{ index + 1 }}</th>
                  <td>{{ service.name }}</td>
                  <td class="actions">
                      <router-link
                              :to="{ name: 'EditService', params: {id: service.id} }"
                              class="btn btn-link bg-primary py-1 px-2 me-2"
                              style="--bs-bg-opacity: 0.08"
                      >
                          <i class="bi bi-pencil-square"></i>
                      </router-link>
                      <!-- <button
                              @click.prevent="deleteService(service.id)"
                              class="btn btn-link bg-danger link-danger py-1 px-2"
                              style="--bs-bg-opacity: 0.08"
                      >
                          <i class="bi bi-trash"></i>
                      </button> -->
                  </td>
              </tr>
            </tbody>
        </table>
    </div>

    <div class="card bg-white rounded-2 mb-2 d-lg-none border border-0  .d-xl-block" v-for="(service, index) in services.data" :key="service.id">
          <div class="card-header bg-white">
            ( {{ index + 1 }} )
            <router-link :to="{ name: 'EditService', params: {id: service.id} }"
                            class="btn btn-link bg-primary py-1 px-2 me-2"
                            style="--bs-bg-opacity: 0.08"
                    >
                        <i class="bi bi-pencil-square"></i>
                    </router-link>
          </div>
          <div class="card-body">
            <blockquote class="blockquote mb-0">
              <div class="text-primary">الخدمة <span class="text-dark">{{ service.name }}</span></div>      
                    
            </blockquote>
          </div>
        </div>

</template>

<script>
import LaravelVuePagination from 'laravel-vue-pagination'
import axios from 'axios'
import {mapGetters} from 'vuex'

export default{
    name: 'IndexService',
    components: {
      'Pagination': LaravelVuePagination
    },
    data:function(){
      return {
        services: {},
        fullPage: false,
        search: '',
        searchField: '',
        sortField: 'created_at',
        sort: 'DESC',
        paginateNumber: 5,
      }
    },
    async mounted(){
      this.getServices();
    },
    methods:{
      async getServices(page = 1){
        let loader = this.$loading.show({
            color: 'Maroon',
            loader: 'dots',
            backgroundColor: '#e4e4e4',
        });
        
        const response = await axios.get(`${localStorage.getItem('default_office')}/service?page= ${page}`)
        this.services = response.data;
        loader.hide();
      },

      deleteService(id){
        let url = `${localStorage.getItem('default_office')}/service/${id}`;
        this.$swal.fire({
          // toast: true,
          title: 'هل تريد الحذف',
          icon: 'question',
          iconHtml: '؟',
          confirmButtonText: 'نعم',
          cancelButtonText: 'لا',
          showCancelButton: true,
          showCloseButton: true
        }).then(result=>{
          if(result.isConfirmed){
              const response = axios.delete(url)
              this.getServices();
          }
        })
      },
      async filter(page = 1){
        const response = await axios.get(`${localStorage.getItem('default_office')}/serviceFilter?paginateNumber=` + this.paginateNumber + '&sortField=' + this.sortField + '&sort=' + this.sort + '&search=' + this.search + '&page=' + page)
        this.services = response.data
      },
      async serviceExport(){
        const response = await axios.get(`${localStorage.getItem('default_office')}/serviceExport`)
        window.location.href = response.config.baseURL + `${localStorage.getItem('default_office')}/serviceExport`
      }
    },
    computed: {
      ...mapGetters({
        authenticated: 'auth/authenticated',
        user: 'auth/user'
      }),
      
      
    }
}
</script>

<style>

</style>