<template>
        <div
                class="d-flex flex-md-row flex-column gap-3 align-items-md-center justify-content-between"
        >
        <form @submit.prevent="filter" novalidate>
        <div
                class="d-flex align-items-sm-center flex-column flex-sm-row gap-2"
        >
            
            
            <select v-model="sortField"
                    class="form-select border-white w-auto"
                    style="min-width: 120px"
            >
                <option :selected="selected">الترتيب حسب</option>
                <option value="name">الاسم</option>
                <option value="mobile">رقم الجوال</option> 
            </select>
            <select v-model="sort"
                    class="form-select border-white w-auto"
                    style="min-width: 120px"
            >
                <option selected>الترتيب حسب الإضافة</option>
                <option value="DESC">الأحدث</option>
                <option value="ASC">الأقدم</option>
            </select>
            <select v-model="paginateNumber"
                    class="form-select border-white w-auto"
                    style="min-width: 120px"
            >
                <option selected>الصفوف المعروضة</option>
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
            </select>
            
            <input
                    v-model="search"
                    type="search"
                    placeholder="اكتب كلمة للبحث"
                    class="form-control border-white"
            />
            <button class="btn btn-primary col-lg-2">
              بحث <i class="bi bi-search"></i>
            </button>
        </div>
      </form>
        <div>
            <button class="btn py-2 btn-success mb-2" @click.prevent="customerExport">
                <i class="bi bi-file-earmark-spreadsheet-fill fs-5"></i>
            </button>
            <router-link :to="{name: 'AddCustomer'}" class="btn py-2 ms-1 btn-primary mb-2">
                <i class="bi bi-plus-circle fs-5"></i>
            </router-link>
            <!-- <form @submit.prevent="filter" class="flex">   
                <div class="flex justify-center">
                <div class="xl:w-96 ml-4">
                  <div class="input-group relative flex flex-wrap items-stretch w-full mb-4">
                    <input type="search" v-model="search" class="form-control relative flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" placeholder="اكتب كلمة البحث" aria-label="Search" aria-describedby="button-addon2">
                    <button class="mr-1 inline-block px-4 py-2 font-bold text-center text-white uppercase align-middle transition-all bg-transparent rounded-sm cursor-pointer leading-pro text-xs ease-soft-in shadow-soft-md bg-150 bg-gradient-to-tl from-gray-900 to-slate-800 hover:shadow-soft-xs active:opacity-85 hover:scale-102 tracking-tight-soft bg-x-25" id="button-addon2">
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" class="w-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
                      </svg>
                    </button>
                    
                  </div>
                </div>
              </div>
            </form> -->
        </div>
    </div>
    <div class="table-responsive-lg mt-4 d-none d-sm-block">
        <table class="table table-borderless bg-white rounded-4">
            <thead class="border-bottom">
            <tr>
                <th scope="col">ID</th>
                <th scope="col">المستأجر</th>
                <th scope="col">الهوية</th>
                <th scope="col">الجوال</th>
                <th scope="col">التعديل</th>
            </tr>
            </thead>
            <tbody>
              <tr v-if="customers" v-for="customer, index in customers.data" :key="customer.id">
                <th scope="row">{{ index + 1 }}</th>
                <td>{{ customer.name }}</td>
                <td>{{ customer.id_card }}</td>
                <td dir="ltr" class="text-start">{{ customer.mobile }}</td>
                <td class="actions">
                    <router-link :to="{ name: 'EditCustomer', params: {id: customer.id} }"
                            class="btn btn-link bg-primary py-1 px-2 me-2"
                            style="--bs-bg-opacity: 0.08"
                    >
                        <i class="bi bi-pencil-square"></i>
                    </router-link>
                    <button @click.prevent="deleteCustomer(customer.id)"
                            class="btn btn-link bg-danger link-danger py-1 px-2"
                            style="--bs-bg-opacity: 0.08"
                    >
                        <i class="bi bi-trash"></i>
                    </button>
                </td>
            </tr>
            </tbody>
        </table>
      </div>

      

      <div class="card bg-white rounded-2 mb-2 d-lg-none border border-0  
      " v-if="customers" v-for="customer, index in customers.data" :key="customer.id">
          <div class="card-header bg-white">
            <div class="row">
              <div class="col-8">
                ( {{ index + 1 }} )
              </div>
              <div class="col-4">
                <router-link :to="{ name: 'EditCustomer', params: {id: customer.id} }"
                        class="btn btn-link bg-primary py-1 px-2 me-2"
                        style="--bs-bg-opacity: 0.08"
                >
                    <i class="bi bi-pencil-square"></i>
                </router-link>
                <button @click.prevent="deleteCustomer(customer.id)"
                            class="btn btn-link bg-danger link-danger py-1 px-2"
                            style="--bs-bg-opacity: 0.08"
                    >
                        <i class="bi bi-trash"></i>
                    </button>
            </div>
          </div>
      </div>
          <div class="card-body">
            <blockquote class="blockquote mb-0">
              <div class="text-primary">المستأجر <span class="text-dark">{{ customer.name }}</span></div>      
              <div class="text-primary">الهوية <span class="text-dark">{{ customer.id_card }}</span></div>      
              <div class="text-primary">الجوال <span class="text-dark">{{ customer.mobile }}</span></div>    
            </blockquote>
          </div>
        </div>
      <!-- <footer
              class="mt-3 d-flex flex-column flex-lg-row align-items-center justify-content-between gap-2"
          >
          <p class="text-primary">
              إظهار <strong>1-6</strong> من <strong>100</strong> بيانات
          </p>
          <nav aria-label="Page navigation example">
              <ul class="pagination bg-white gap-2 rounded-3 py-1">
                  <li class="page-item">
                      <a class="page-link" href="#" aria-label="Previous">
                          <i class="bi bi-chevron-double-right"></i>
                      </a>
                  </li>
                  <li class="page-item active">
                      <a class="page-link" href="#">1</a>
                  </li>
                  <li class="page-item"><a class="page-link" href="#">2</a></li>
                  <li class="page-item"><a class="page-link" href="#">3</a></li>
                  <li class="page-item">
                      <a class="page-link" href="#" aria-label="Next">
                          <i class="bi bi-chevron-double-left"></i>
                      </a>
                  </li>
              </ul>
          </nav>
      </footer> -->
      <div>
        <Pagination :data="customers" align="right" @pagination-change-page="getCustomers"/>
      </div>
</template>

<script>
import LaravelVuePagination from 'laravel-vue-pagination'
import axios from 'axios'
import {mapGetters} from 'vuex'
import {SaveAs} from 'file-saver'

export default{
    name: 'IndexCustomer',
    components: {
      'Pagination': LaravelVuePagination
    },
    data:function(){
      return {
        customers: {},
        fullPage: false,
        search: '',
        searchField: '',
        sortField: 'mobile',
        sort: 'DESC',
        paginateNumber: 20,
      }
    },
    async mounted(){
      this.getCustomers();
    },
    methods:{
      async getCustomers(page = 1){
        let loader = this.$loading.show({
            color: '#0d6efd',
            loader: 'dots',
            backgroundColor: '#e4e4e4',
        });
        
        const response = await axios.get(`${localStorage.getItem('default_office')}/customer?page= ${page}`)
        this.customers = response.data;
        loader.hide();
      },

      deleteCustomer(id){
        let url = `${localStorage.getItem('default_office')}/customer/${id}`;
        this.$swal.fire({
          // toast: true,
          title: 'هل تريد الحذف',
          icon: 'question',
          iconHtml: '؟',
          confirmButtonText: 'نعم',
          cancelButtonText: 'لا',
          showCancelButton: true,
          showCloseButton: true
        }).then(result=>{
          if(result.isConfirmed){
              const response = axios.delete(url)
              this.getCustomers();
          }
        })
      },
      async filter(page = 1){
        const response = await axios.get(`${localStorage.getItem('default_office')}/customerFilter?paginateNumber=` + this.paginateNumber + '&sortField=' + this.sortField + '&sort=' + this.sort + '&search=' + this.search + '&page=' + page)
        this.customers = response.data
      },
      async customerExport(){
        const response = await axios.get(`${localStorage.getItem('default_office')}/customerExport`)
        window.location.href = response.config.baseURL + `${localStorage.getItem('default_office')}/customerExport`
      }
    },  
    dowanloadItem(url){
      axios.get(url, {responseType: 'blob'}).then(response => {
        SaveAs(response.data.data, 'dowanloadfile.pdf')
      });
    },
    computed: {
      ...mapGetters({
        authenticated: 'auth/authenticated',
        user: 'auth/user'
      }),
      
      statusUpdate(){
        let url = `${localStorage.getItem('default_office')}/customer/${id}`;
        axios.put(url, this.customer.status).then(response => {
        });
      },
    }
}
</script>

<style>

</style>