<template>
  <div
            class="d-flex flex-md-row flex-column gap-3 align-items-md-center justify-content-between"
          >
            <!-- <input
              type="search"
              placeholder="بحث"
              class="form-control border-white w-auto"
            /> -->
            <div>
              <!-- <button class="btn py-2 btn-success">
                <i class="bi bi-file-earmark-spreadsheet-fill fs-5"></i>
              </button> -->
              <router-link :to="{ name: 'AddUser' }" class="btn py-2 ms-1 btn-primary mb-2">
                <i class="bi bi-plus-circle fs-5"></i>
              </router-link>
            </div>
          </div>
          <div class="table-responsive-lg mt-4 d-none d-sm-block">
            <table class="table table-borderless bg-white rounded-4">
              <thead class="border-bottom">
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">الاسم</th>
                  <th scope="col">البريد الإلكتروني</th>
                  <th scope="col">رقم الجوال</th>
                  <th scope="col">التفعيل</th>
                  <!-- <th scope="col">التعديل</th> -->
                </tr>
              </thead>
              <tbody>
                <tr v-for="(user, index) in users.data" :key="user.id">
                  <th scope="row">1</th>
                  <td>{{ user.name }}</td>
                  <td>{{ user.email }}</td>
                  <td dir="ltr" class="text-start">{{ user.mobile }}</td>
                  <!-- <td>
                    <div class="form-check form-switch fs-4">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="officeActive"
                      />
                      <label
                        class="form-check-label visually-hidden"
                        for="officeActive"
                        >Office active</label
                      >
                    </div>
                  </td> -->
                  <td class="actions">
                    <router-link
                      :to="{ name: 'EditUser', params: {id: user.id} }"
                      class="btn btn-link bg-primary py-1 px-2 me-2"
                      style="--bs-bg-opacity: 0.08"
                    >
                      <i class="bi bi-pencil-square"></i>
                    </router-link>
                    <!-- <button
                      @click.prevent="deleteUser(user.id)"
                      class="btn btn-link bg-danger link-danger py-1 px-2"
                      style="--bs-bg-opacity: 0.08"
                    >
                      <i class="bi bi-trash"></i>
                    </button> -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="card bg-white rounded-2 border border-0 mb-2 d-lg-none .d-xl-block" v-for="(user, index) in users.data" :key="user.id">
          <div class="card-header bg-white">
            <div class="row">
              <div class="col-10">
                ( {{ index + 1 }} )
              </div>
              <div class="col-2">
                <router-link :to="{ name: 'EditUser', params: {id: user.id} }"
                            class="btn btn-link bg-primary py-1 px-2 me-2"
                            style="--bs-bg-opacity: 0.08"
                    >
                        <i class="bi bi-pencil-square"></i>
                    </router-link>
            </div>
            <!-- <div class="col-2">
              <button @click.prevent="deleteUser(user.id)"
                        class="btn btn-link bg-danger link-danger py-1 px-2"
                        style="--bs-bg-opacity: 0.08"
                >
                    <i class="bi bi-trash"></i>
                </button>
            </div> -->
          </div>
        </div>
          
          <div class="card-body">
            <blockquote class="blockquote mb-0">
              <div class="text-primary">الإسم <span class="text-dark">{{ user.name }}</span></div>      
              <div class="text-primary">الإيميل <span class="text-dark">{{ user.email }}</span></div>      
              <div class="text-primary">الجوال <span class="text-dark">{{ user.mobile }}</span></div>    
            </blockquote>
          </div>
        </div>
        <div>
          <Pagination :data="users" align="right" @pagination-change-page="getUsers" />
        </div>


</template>

<script>
import LaravelVuePagination from 'laravel-vue-pagination'
import { mapGetters } from 'vuex'
import axios from 'axios';

    export default{
        name: 'IndexUser',
        components: {
          'Pagination': LaravelVuePagination
        },
        data: function(){
            return {
                users: {},
                search: '',
                searchField: '',
                sortField: 'created_at',
                sort: 'DESC',
                paginateNumber: 5,
            }
        },
        created(){
            this.getUsers();
        },
        methods: {
            async getUsers(page = 1){
                let loader = this.$loading.show({
                    color: '#0d6efd',
                    loader: 'dots',
                    backgroundColor: '#e4e4e4',
                });
                const response = await axios.get(`${localStorage.getItem('default_office')}/user?page=${page}`)
                // console.log(response)
                this.users = response.data
                loader.hide()
            },
            async deleteUser(id){
              this.$swal.fire({
              // toast: true,
              title: 'هل تريد الحذف',
              icon: 'question',
              iconHtml: '؟',
              confirmButtonText: 'نعم',
              cancelButtonText: 'لا',
              showCancelButton: true,
              showCloseButton: true
            }).then(result=>{
              if(result.isConfirmed){
                  const response = axios.delete(`${localStorage.getItem('default_office')}/user/${id}`)
                    this.getUsers()
                }
            })
          },
          async filter(page = 1){
            const response = await axios.get(`${localStorage.getItem('default_office')}/userFilter?paginateNumber=` + this.paginateNumber + '&sortField=' + this.sortField + '&sort=' + this.sort + '&search=' + this.search + '&page=' + page)
            this.users = response.data
          }
        },
        computed: {
          ...mapGetters({
            authenticated: 'auth/authenticated',
            user: 'auth/user'
          }),

}
    }
</script>