<template>
  <master-page></master-page> 
</template>

<script>
import MasterPage from './components/office/layout/MasterPage.vue'
import Login from './components/office/auth/Login.vue'
import Register from './components/office/auth/Register.vue'

export default {
  name: 'App',
  components: {
    MasterPage,
    Login,
    Register,
  },
}
</script>

<style>

</style>
