<template>
  <div class="w-full px-6 py-6 pt-1 pb-0 mx-auto"> 
        <div class="flex flex-wrap -mx-3">
                <div class="flex-auto px-0 pt-0 pb-0">
                <div v-if="neighborhoods != false" class="p-0 overflow-x-auto">
                  
                    <div class="flex-none w-1/2 max-w-full px-3 mb-2 text-right">
                        <router-link class="inline-block px-2 py-2 font-bold text-center text-white uppercase align-middle transition-all bg-transparent rounded-lg cursor-pointer leading-pro text-xs ease-soft-in shadow-soft-md bg-150 bg-gradient-to-tl from-gray-900 to-slate-800 hover:shadow-soft-xs active:opacity-85 hover:scale-102 tracking-tight-soft bg-x-25" :to="{ name: 'AddNeighborhood' }"> <i class="fas fa-plus"> </i>&nbsp;&nbsp;إضاقة حي</router-link>
                    </div>   
                <div>

                      <!-- <div class="flex flex-nowrap">
                            <div class="pr-4">
                              <label for="" class="font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">عدد الصفوف</label>
                                <select v-model="paginateNumber" class="mr-4 focus:shadow-soft-primary-outline text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none">
                                  <option value="5">5</option>
                                  <option value="10">10</option>
                                  <option value="15">15</option>
                                  <option value="20">20</option>
                                </select>
                            </div>
                            <div class="pr-4">
                              <label for="" class="font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">الفرز بـ</label>
                              <select v-model="sortField" class="mr-4 focus:shadow-soft-primary-outline text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none">
                                <option value="name">الاسم</option>
                                <option value="created_at">تاريخ الإنشاء</option>
                              </select>
                            </div>
                            <div class="pr-4 pl-4">
                              <label for="" class="font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">ترتيب</label>
                              <select v-model="sort" class="mr-4 focus:shadow-soft-primary-outline text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none">
                                <option value="DESC">الأخير</option>
                                <option value="ASC">الأول</option>
                              </select>
                            </div>
                            <div class="pr-4">
                              <label for="" class="font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70"></label>
                              <div class="row-span-2 mt-0 flex flex-row">
                                    <div>
                                      <form @submit.prevent="filter" class="flex">   
                                          <div class="flex justify-center">
                                          <div class="xl:w-96 ml-4">
                                            <div class="input-group relative flex flex-wrap items-stretch w-full mb-4">
                                              <input type="search" v-model="search" class="form-control relative flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" placeholder="اكتب كلمة البحث" aria-label="Search" aria-describedby="button-addon2">
                                              <button class="mr-1 inline-block px-4 py-2 font-bold text-center text-white uppercase align-middle transition-all bg-transparent rounded-sm cursor-pointer leading-pro text-xs ease-soft-in shadow-soft-md bg-150 bg-gradient-to-tl from-gray-900 to-slate-800 hover:shadow-soft-xs active:opacity-85 hover:scale-102 tracking-tight-soft bg-x-25" id="button-addon2">
                                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" class="w-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                  <path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
                                                </svg>
                                              </button>
                                              
                                            </div>
                                          </div>
                                        </div>
                                      </form>
                                    </div>
                                </div>
                            </div>
                            <div class="pr-4 pl-4">
                              <label for="" class="font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70"></label>
                              <img @click.prevent="neighborhoodExport" src="@/assets/images/ex_excel.png" class="h-10 cursor-pointer" alt="">
                            </div>
                          </div> -->
                  
                          
                  <table class="items-center w-full mt-0 align-top border-gray-200 text-slate-500">
                    <thead class="align-bottom">
                      <tr>
                        
                        <th class="px-6 py-3 pl-2 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">اسم الحي </th>
                        <th class="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">المدينة  </th>
                        <th class="px-6 py-3 font-semibold text-center capitalize align-middle bg-transparent border-b border-gray-200 border-solid shadow-none tracking-none whitespace-nowrap text-slate-400 opacity-70">
                          
                        </th>
                      </tr>
                    </thead>
                    <tbody >
                        
                        <tr  v-for="(neighborhood, index) in neighborhoods.data" :key="neighborhood.id">

                          <td class="p-2 align-middle text-center bg-transparent border-b whitespace-nowrap shadow-transparent">
                            <p class="mb-0 font-semibold leading-tight text-xs">{{ neighborhood.name }}</p>
                            <!-- <p class="mb-0 leading-tight text-xs text-slate-400">Organization</p> -->
                          </td>
                          <td class="p-2 align-middle text-center bg-transparent border-b whitespace-nowrap shadow-transparent">
                            <p class="mb-0 font-semibold leading-tight text-xs">{{ neighborhood.city.name }}</p>
                            <!-- <p class="mb-0 leading-tight text-xs text-slate-400">Organization</p> -->
                          </td>
                       
                        <td class="p-2 align-middle text-center bg-transparent border-b whitespace-nowrap shadow-transparent">
                          <div class="ml-auto">
                            <router-link class="inline-block px-4 py-3 mb-0 font-bold text-center uppercase align-middle transition-all bg-transparent border-0 rounded-lg shadow-none cursor-pointer leading-pro text-xs ease-soft-in bg-150 hover:scale-102 active:opacity-85 bg-x-25 text-slate-700" :to="{ name: 'EditNeighborhood', params: {id: neighborhood.id} }"><i class="mr-2 fas fa-pencil-alt text-slate-700" aria-hidden="true"></i>تعديل</router-link>
                            <button class="relative z-10 inline-block px-4 py-3 mb-0 font-bold text-center text-transparent uppercase align-middle transition-all border-0 rounded-lg shadow-none cursor-pointer leading-pro text-xs ease-soft-in bg-150 bg-gradient-to-tl from-red-600 to-rose-400 hover:scale-102 active:opacity-85 bg-x-25 bg-clip-text" @click.prevent="deleteNeighborhood(neighborhood.id)"><i class="mr-2 far fa-trash-alt bg-150 bg-gradient-to-tl from-red-600 to-rose-400 bg-x-25 bg-clip-text"></i>حذف</button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div>
                    <Pagination :data="neighborhoods" align="left" @pagination-change-page="getNeighborhoods" />
                  </div>
                </div>
              </div>
              <div v-else class="flex items-center justify-center">
                  <div>
                      <router-link :to="{ name: 'AddnNighborhood' }"><img  width="200" src="@/assets/images/nodata.png"></router-link>
                      <p class="text-center">لا يوجد أحياء</p>
                  </div>
              </div>
              </div>
            </div>
        </div>
</template>

<script>
import LaravelVuePagination from 'laravel-vue-pagination'
import axios from 'axios'
import {mapGetters} from 'vuex'

export default{
    name: 'IndexNeighborhood',
    components: {
      'Pagination': LaravelVuePagination
    },
    data:function(){
      return {
        neighborhoods: {},
        fullPage: false,
        search: '',
        searchField: '',
        sortField: 'created_at',
        sort: 'DESC',
        paginateNumber: 5,
      }
    },
    async mounted(){
      this.getNeighborhoods();
    },
    methods:{
      async getNeighborhoods(page = 1){
        let loader = this.$loading.show({
            color: '#0d6efd',
            loader: 'dots',
            backgroundColor: '#e4e4e4',
        });
        
        const response = await axios.get(`${localStorage.getItem('default_office')}/neighborhood?page= ${page}`)
        
        this.neighborhoods = response.data;
        loader.hide();
      },

      deleteNeighborhood(id){
        let url = `${localStorage.getItem('default_office')}/neighborhood/${id}`;
        this.$swal.fire({
          toast: true,
          title: 'هل تريد الحذف',
          icon: 'question',
          iconHtml: '؟',
          confirmButtonText: 'نعم',
          cancelButtonText: 'لا',
          showCancelButton: true,
          showCloseButton: true
        }).then(result=>{
          if(result.isConfirmed){
              const response = axios.delete(url)
              this.getNeighborhoods();
          }
        })
      },
      async filter(page = 1){
        const response = await axios.get(`${localStorage.getItem('default_office')}/neighborhoodFilter?paginateNumber=` + this.paginateNumber + '&sortField=' + this.sortField + '&sort=' + this.sort + '&search=' + this.search + '&page=' + page)
        this.neighborhoods = response.data
      },
      async neighborhoodExport(){
        const response = await axios.get(`${localStorage.getItem('default_office')}/neighborhoodExport`)
        window.location.href = response.config.baseURL + `${localStorage.getItem('default_office')}/neighborhoodExport`
      }
    },
    computed: {
      ...mapGetters({
        authenticated: 'auth/authenticated',
        user: 'auth/user'
      }),
      
      statusUpdate(){
        let url = `${localStorage.getItem('default_office')}/neighborhood/${id}`;
        axios.put(url, this.neighborhood.status).then(response => {
        });
      },
    }
}
</script>

<style>

</style>