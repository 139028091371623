<template>
  <div class="login-page">
    <div class="container">
      <div class="row">
          <div class="col-lg-6 position-relative pb-5 pt-4 ">
              <div class="h-100 d-lg-flex align-items-center rounded-4" style="background-color: rgba(255, 255, 255, 0.5); box-shadow: 2px 0 10px rgb(0 0 0 / 10%)">
                  <div class="login-form-container w-100 p-4" >

                      <div class="text-center mb-5">
                          <img src="assets/images/logo-base.png" width="171" height="54" alt="Bill"/>
                      </div>
                      <nav class="d-flex gap-2 mb-4">
                        <button 
                                  @click.prevent="loginForm = 'mobile'; email=''"
                                  class="btn btn-sm btn-outline-primary w-100"
                                  id="show-phone-login"
                          >
                              إستعادة بالجوال
                          </button>
                          <button
                                  @click.prevent="loginForm = 'mail'; mobile=''"
                                  class="btn btn-sm btn-outline-secondary w-100"
                                  id="show-email-login"
                          >
                              إستعادة بالبريد
                          </button>
                      </nav>
                      <li v-for="error in errors" class="text-danger fs-6">{{ error[0] }}</li>
                      <li v-if="error" class="text-danger fs-6">{{ error }}</li>

                      <span v-if="loginForm == 'mobile'" class="text-primary">رقم الجوال</span>
                      <span v-if="loginForm == 'mail'">البريد الالكتروني</span>
                      
                      <form @submit.prevent="submitMobile" v-if="loginForm == 'mobile'" id="phone-based-login">
                          <input
                                  v-model="mobile"
                                  type="tel"
                                  placeholder="9665xxxxxxxx"
                                  aria-label="رقم الجوال"
                                  class="form-control text-start mb-4 bg-light"
                          />
                          

                          <br/>
                          <button type="submit" class="btn btn-arrow btn-primary w-75">
                              <span>إرسال كلمة المرور</span>
                              <i class="bi bi-arrow-left"></i>
                          </button>
                      </form>
                      
                      <form @submit.prevent="submitEmail" v-if="loginForm == 'mail'" id="phone-based-login">
                          <input
                                  v-model="email"
                                  type="email"
                                  placeholder="mail@mail.com"
                                  aria-label="البريد الالكتروني"
                                  class="form-control text-start mb-4 bg-light"
                          />
                          
                          <br/>
                          <button type="submit" class="btn btn-arrow btn-primary w-75">
                              <span>إرسل كلمة المرور</span>
                              <i class="bi bi-arrow-left"></i>
                          </button>
                      </form>

                      <p class="text-muted mt-3">
                          <small>
                              ليس لديك حساب؟ <router-link :to="{ name: 'Register' }">إنشاء حساب</router-link>
                          </small>
                          <small>
                            <router-link :to="{ name: 'Login' }"> | تسجيل دخول </router-link>
                          </small>
                      </p>
                  </div>
              </div>
              <footer class="text-muted">جميع الحقوق محفوظة © 2023</footer>
          </div>
          <div class="col-lg-6 d-lg-block d-none">
              <img src="assets/images/login-graphics.png" alt="Login Image" class="img-fluid" width="1622"
                  height="2020" style="width: 540px;"/>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

  export default{
    name: 'ForgotPassword',
    data(){
      return {
        form: {
          loginName: '',
          password: '',
        },
        loginType: 'mobile',
        errors: [],
        error: '',
        loginForm: 'mobile',
        search: "mobile",
        mobile: '',
        email: ''
      }
    },
    created(){
      
    },
    methods: {
      
      async submitMobile(){
            this.errors = []
            this.error = ''
            this.search = 'mobile'
            if(this.mobile != ''){
                  try{
                  await axios.get(`/auth/forgot-password?search=` + this.search + `&mobile=` + this.mobile).then(response => {
                    
                    if(response.data.error){
                      this.error = response.data.error
                    }else{
                      const data = {
                        sender: '966500186476',
                        receiver: this.mobile,
                        body:  'لتغيير كلمة المرور في حسابك' +  ' ' +  'https://' + window.location.hostname + '/reset/' + response.data.data.token
                      }
                      try{
                        axios.post(`https://api.barq.wide-techno.com/api/crm/whatsapp-instances/send-text-message`, data , {
                            headers:{
                              'Client-Id': '88DNAcsAp3Ko1ror3GSf5xauDPYbIONAkcB1Eh06QMJelOv2X6',
                              'Client-Secret': 'Vl3VGPo0JzSkfGF3HbbPVHrTs36XvxD8TVo24clR6m05ILsfdpF1xYP1N2UBWhdzoDBbONzfTdR5rEcZpSJ6uJJfD1wCI9gBPN0GWpm4VY4AL7jTjKOdhGqly1THFzL47IFkBohKTA42EGviZsthgn'
                            }
                          }
                        ).then(res => {
                          this.$swal.fire({
                            toast: true,
                            position: 'top',
                            icon: 'success',
                            title: res.data.message + ' سيتم ارسال الرابط على الواتساب',
                            showConfirmButton: false,
                            timer: 2500,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', Swal.stopTimer)
                                toast.addEventListener('mouseleave', Swal.resumeTimer)
                            }
                          })
                        })
                      }catch(err){
                        this.errors = err.response.data.errors
                      }
                    }
                  })
                }catch(error){
                  this.errors = error.response.data.errors
                  // console.log(error.response.data.errors)
                }
            }else{
              this.error = 'حقل الجوال مطلوب'
            }
      },

      async submitEmail(){
            this.search = 'email'
            if(this.search = 'email'){
                try{
                this.errors = []
                this.error = ''
                
                await axios.get(`/auth/forgot-password?search=` + this.search + `&email=` + this.email).then(response => {
                  
                  if(response.data.error){
                    this.error = response.data.error
                  }else{
                    const data = {
                        to: [this.email],
                        sender_name: 'Reminder',
                        subject: 'Reset Password',
                        body: 'قمنا بإرسال هذه الرسالة بناء على طلبك' + `<a href="https://` + window.location.hostname + `/reset/${response.data.data.token}"> بتغير </a>` + 'كلمة المرور الخاصة بك'
                        // body: 'قمنا بإرسال هذه الرسالة بناء على طلبك' + `<a href="https://reminder.wide-techno.com/reset/${response.data.data.token}"> بتغير </a>` + 'كلمة المرور الخاصة بك'
                      }
                    try{
                      axios.post(`https://email.wide-techno.com/api/send-mail`, data , {
                          headers:{
                                    'Client-Id': '4KurVcg22t5fcrpW1LkueCAUOklGXu8UZJyyiAQybLl1l5NhBr',
                                    'Client-Secret': 'mnCPG1410Xm43ePeAnXLHvbRugeC3XPcj3UJRPwmdBYNDMis1s5TP3eEqhn7H7mP5QoLimJm2Rk5VIBTmPjh24gAJ1'
                                  }
                        }
                      ).then(res => {
                        this.$swal.fire({
                            toast: true,
                            position: 'top',
                            icon: 'success',
                            title: res.data.message + ' سيتم ارسال الرابط على البريد الالكتروني',
                            showConfirmButton: false,
                            timer: 2500,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', Swal.stopTimer)
                                toast.addEventListener('mouseleave', Swal.resumeTimer)
                            }
                          })
                      })
                    }catch(err){
                      this.errors = err.response.data.errors
                    }
                  }
                })
              }catch(error){
                this.errors = error.response.data.errors
                // console.log(error.response.data.errors)
              }
            }
        }
    }
  }
</script>


