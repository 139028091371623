<template>
  <div
        class="d-flex flex-xxl-row flex-column gap-3 align-items-xxl-center justify-content-between"
        >
          <!-- <div
            class="d-flex align-items-sm-center flex-column flex-sm-row gap-2"
          >
            <input
              type="search"
              placeholder="بحث"
              class="form-control border-white"
            />
            <select
              class="form-select border-white w-auto"
              style="min-width: 120px"
            >
              <option value="">الترتيب</option>
            </select>
            <select
              class="form-select border-white w-auto"
              style="min-width: 120px"
            >
              <option value="">نوع الوحدة</option>
            </select>
            <input
              type="date"
              placeholder="تاريخ البداية - تاريخ النهاية"
              class="form-control border-white"
            />
          </div> -->
          <div>
            <button
              @click.prevent="sendReminder()"
              class="btn btn-primary mb-2"
              data-bs-toggle="modal"
              data-bs-target="#notificationConf"
            >
              إرسال رسائل التنبيهات
            </button>
            <button @click.prevent="rentExport" class="btn py-2 btn-success mx-1 mb-2">
              <i class="bi bi-file-earmark-spreadsheet-fill fs-5"></i>
            </button>
            <router-link :to="{ name: 'AddRentOrder' }" class="btn py-2 btn-primary mx-1 mb-2">
              <i class="bi bi-plus-circle fs-5"></i>
            </router-link>
            <a class="btn btn-primary mb-2" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
              <i class="bi bi-search"></i>
            </a>
            
            
            <div class="collapse" id="collapseExample">
              <div class="card card-body">
                <form @submit.prevent="filter" novalidate v-if="rentOrders">
                    <div class="mb-2">
                      <input type="radio" id="realestate" name="realestate" value="realestate" v-model="type" class="ms-2">
                      <label for="realestate" class="ms-2">العقار</label>
                      <input type="radio" id="customer" name="customer" value="customer" v-model="type" class="ms-2">
                      <label for="customer" class="ms-2">المستأجر</label>
                      <input type="radio" id="owner" name="owner" value="owner" v-model="type" class="ms-2">
                      <label for="owner" class="ms-2">المالك</label>
                      <input type="radio" id="date" name="date" value="date" v-model="type" class="ms-2">
                      <label for="date" class="ms-2">تاريخ</label>
                      <input type="radio" id="nearRent" name="nearRent" value="nearRent" v-model="type" class="ms-2">
                      <label for="nearRent" class="ms-2">العقود التي ستنتهي</label>
                      <input type="radio" id="expiredRent" name="expiredRent" value="expiredRent" v-model="type" class="ms-2">
                      <label for="expiredRent" class="ms-2">العقود المنتهية</label>
                    </div>
                    
                      <div class="">
                        <div v-if="type == 'nearRent'" class="row"></div>
                  
                        <div v-if="type == 'expiredRent'" class="row"></div>

                        <div v-if="type == 'date'" class="row">
                          <div class="col">
                            <lable>الفترة من</lable>
                            <input type="date" v-model="date_from" class="form-control">
                          </div>
                          <div class="col">
                            <lable>الفترة إلى</lable>
                            <input type="date" v-model="date_to" class="form-control">
                          </div>
                        </div>
                        <input
                                v-if="type == 'customer'"
                                v-model="search"
                                type="search"
                                placeholder="ابحث بإسم المستأجر"
                                class="form-control border"
                        />
                        <input
                                v-if="type == 'realestate'"
                                v-model="search"
                                type="search"
                                placeholder="ابحث بإسم العقار"
                                class="form-control border"
                        />
                        <input
                                v-if="type == 'owner'"
                                v-model="search"
                                type="search"
                                placeholder="ابحث بإسم المالك"
                                class="form-control border"
                        />
                        <button class="btn btn-primary col-lg-2 mt-2">
                          بحث <i class="bi bi-search"></i>
                        </button>
                    </div>
                </form>

              </div>
            </div>
          </div>
          
        </div>
        <div class="table-responsive-lg mt-4 d-none d-sm-block">
          <table class="table table-borderless bg-white rounded-4">
            <thead class="border-bottom">
              <tr>
                <th scope="col">الوحدة العقارية</th>
                <th scope="col">نوع الوحدة</th>
                <!-- <th scope="col">الحي</th> -->
                <!-- <th scope="col">المستأجر</th> -->
                <th scope="col">الفترة من</th>
                <th scope="col">الفترة إلى</th>
                <th scope="col">سعر التأجير</th>
                <th scope="col">المدفوع</th>
                <th scope="col">حالة السداد</th>
                <th scope="col">التفاصيل</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="rentOrderItem in rentOrders.data" :key="rentOrderItem.id">
                <!-- {{ rentOrderItem }} -->
              <tr>
                <th scope="row" v-if="rentOrderItem.realestate">{{ rentOrderItem.realestate.name }}</th>
                <th scope="row" v-else></th>
                <template v-if="rentOrderItem.realestate">
                  <td v-if="rentOrderItem.realestate.type == 'building'">عمارة</td>
                  <td v-if="rentOrderItem.realestate.type == 'villa'">فيلا</td>
                  <td v-if="rentOrderItem.realestate.type == 'apartment'">شقة</td>
                  <td v-if="rentOrderItem.realestate.type == 'shop'">محل</td>
                </template>
                <template v-else>
                  <td></td>
                </template>
                <!-- <td>{{ rentOrderItem.realestate.neighborhood.name }}</td> -->
                <!-- <td><span v-if="rentOrderItem.customer">{{ rentOrderItem.customer.name }}</span></td> -->
                <td>{{ rentOrderItem.date_from }}</td>
                <td>{{ rentOrderItem.date_to }}</td>
                <td>{{ rentOrderItem.rent_price }} ريال</td>
                
                <td>
                  <span class="hide">{{ total = 0 }}</span>
                  <span class="hide" v-for="installment in rentOrderItem.installments">
                    <span class="hide" v-if="installment.paid == 1">{{ total += installment.amount }}</span>
                  </span>
                  <strong>{{ total }}</strong>
                </td>
                
                <td v-if="total == rentOrderItem.rent_price"><strong class="text-success">كلي</strong></td>
                <td v-if="total != rentOrderItem.rent_price"><strong class="text-danger">جزئي</strong></td>
                <td class="actions">
                  <router-link
                    :to="{ name: 'SetReminder', params: {id: rentOrderItem.id} }"
                    class="btn btn-icon btn-primary me-2"
                    style="--bs-bg-opacity: 0.08"
                  >
                    <i class="bi bi-bell-fill"></i>
                  </router-link>
                  <router-link
                    :to="{ name: 'SetInstallment', params: {id: rentOrderItem.id} }"
                    class="btn btn-icon btn-primary me-2"
                    style="--bs-bg-opacity: 0.08"
                  >
                    <i class="bi bi-patch-plus"></i>
                    
                  </router-link>
                  <button
                    class="btn collapsed btn-icon btn-light me-2"
                    style="--bs-bg-opacity: 0.08"
                    data-bs-toggle="collapse"
                    :data-bs-target="'#data' + rentOrderItem.id"
                    aria-expanded="false"
                    aria-controls="data-2"
                  >
                    <i class="bi bi-chevron-down"></i>
                  </button>
                  <router-link
                          :to="{ name: 'EditRentOrder', params: {id: rentOrderItem.id} }"
                          class="btn btn-link bg-primary py-1 px-2 me-2"
                          style="--bs-bg-opacity: 0.08"
                  >
                      <i class="bi bi-pencil-square"></i>
                  </router-link>
                  <button
                          @click.prevent="deleteRentOrder(rentOrderItem.id)"
                          class="btn btn-link bg-danger link-danger py-1 px-2"
                          style="--bs-bg-opacity: 0.08"
                  >
                      <i class="bi bi-trash"></i>
                  </button>
                </td>
              </tr>
              <tr class="collapse bg-light" :id="'data' + rentOrderItem.id" colspan="9">
                <td colspan="9">
                  <div class="row">
                    <div
                                      class="col-xxl-4 col-md-4 d-flex align-items-center gap-3 mb-4 mb-xxl-0"
                              >
                                  <div class="d-flex bg-dark bg-opacity-25 text-white p-4 rounded-3"
                                       style="font-size: 3.75rem">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                                           viewBox="0 0 32 32" fill="currentColor">
                                          <title>building-person</title>
                                          <path d="M9.334 13.334h-2.666v-12c0-0 0-0.001 0-0.002 0-0.736 0.596-1.332 1.332-1.332 0.001 0 0.001 0 0.002 0h16c0 0 0.001 0 0.002 0 0.736 0 1.332 0.596 1.332 1.332 0 0.001 0 0.001 0 0.002v-0 8h-2.668v-6.668h-13.334z"></path>
                                          <path d="M31.126 32h-4.559c-0.224-1.529-0.752-2.9-1.522-4.102l0.025 0.041h1.996v-2.707h-4.061v0.31c-1.468-1.276-3.32-2.146-5.36-2.419l-0.053-0.006v-0.403c2.798-0.609 4.862-3.064 4.862-6.002 0-2.811-1.891-5.181-4.47-5.907l-0.043-0.010c0.248-0.276 0.605-0.448 1.003-0.448 0.003 0 0.005 0 0.008 0h12.178c0.744 0 1.347 0.603 1.347 1.347v0 18.951c0 0.002 0 0.004 0 0.007 0 0.744-0.603 1.347-1.347 1.347-0.001 0-0.002 0-0.004 0h0zM23.005 19.821v2.705h4.061v-2.705zM23.005 14.408v2.707h4.061v-2.707zM5.912 32h-4.564c-0.744 0-1.347-0.603-1.347-1.347v0-13.538c0-0.744 0.603-1.347 1.347-1.347v0h8.758c-0.049 0.286-0.077 0.615-0.077 0.951v0c0.050 2.913 2.090 5.335 4.817 5.967l0.042 0.008v0.436c-2.094 0.3-3.943 1.178-5.426 2.467l0.013-0.011v-0.354h-4.061v2.707h2.021c-0.755 1.158-1.29 2.529-1.509 4.003l-0.007 0.056zM5.406 19.821v2.705h4.067v-2.705z"></path>
                                          <path d="M16.281 11.944c0.027-0.001 0.059-0.001 0.092-0.001 2.758 0 4.994 2.236 4.994 4.994s-2.236 4.994-4.994 4.994c-0.032 0-0.064-0-0.097-0.001l0.005 0c-0.005 0-0.011 0-0.018 0-2.775 0-5.030-2.225-5.079-4.987l-0-0.005c0.048-2.768 2.304-4.994 5.079-4.994 0.006 0 0.012 0 0.019 0h-0.001z"></path>
                                          <path d="M16.281 24.426c4.408 0.038 8.044 3.294 8.676 7.53l0.006 0.049h-17.448c0.674-4.297 4.336-7.549 8.763-7.579l0.003-0z"></path>
                                      </svg>
                                  </div>
                                  <div>
                                      <div class="my-3 d-flex align-items-center">
                                          <h5 class="fs-6 fw-bold mb-0">
                                              
                                          </h5>
                                          <small class="text-muted ms-1">المستأجر</small>
                                          <span v-if="rentOrderItem.customer">{{ rentOrderItem.customer.name }}</span>
                                      </div>
                                      <dl class="mb-0 lh-lg">
                                          <dt>البطاقة:</dt>
                                          <dd><span v-if="rentOrderItem.customer">{{ rentOrderItem.customer.id_card }}</span></dd>
                                          <br>
                                          <dt>الجوال:</dt>
                                          <dd class="text-starrt" dir="ltr">
                                            <span v-if="rentOrderItem.customer">{{ rentOrderItem.customer.mobile }}</span>
                                          </dd>
                                      </dl>
                                  </div>
                    </div>
                    <!-- end col -->
                    <div
                                      class="col-xxl-4 col-md-4 d-flex align-items-center gap-3 mb-4 mb-xxl-0"
                              >
                                  <div class="d-flex bg-dark bg-opacity-25 text-white p-4 rounded-3"
                                       style="font-size: 3.75rem">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                                           viewBox="0 0 32 32" fill="currentColor">
                                          <title>building</title>
                                          <path d="M9.332 13.334h-2.666v-12c0-0 0-0.001 0-0.002 0-0.736 0.596-1.332 1.332-1.332 0.001 0 0.001 0 0.002 0h16c0 0 0.001 0 0.002 0 0.736 0 1.332 0.596 1.332 1.332 0 0.001 0 0.001 0 0.002v-0 8h-2.668v-6.668h-13.334z"></path>
                                          <path d="M30.666 10.666h-12c-0 0-0.001 0-0.002 0-0.736 0-1.332 0.596-1.332 1.332 0 0.001 0 0.001 0 0.002v-0 17.332h-2.666v-12c0-0 0-0.001 0-0.002 0-0.736-0.596-1.332-1.332-1.332-0.001 0-0.001 0-0.002 0h-11.998c-0 0-0.001 0-0.002 0-0.736 0-1.332 0.596-1.332 1.332 0 0.001 0 0.001 0 0.002v-0 13.334c0 0 0 0.001 0 0.002 0 0.736 0.596 1.332 1.332 1.332 0.001 0 0.001 0 0.002 0h29.332c0 0 0.001 0 0.002 0 0.736 0 1.332-0.596 1.332-1.332 0-0.001 0-0.001 0-0.002v0-18.666c0-0.001 0-0.001 0-0.002 0-0.736-0.596-1.332-1.332-1.332-0.001 0-0.001 0-0.002 0h0zM9.334 28h-4v-2.668h4zM9.334 22.666h-4v-2.666h4zM26.668 28h-4.002v-2.668h4zM26.668 22.666h-4.002v-2.666h4zM26.668 17.332h-4.002v-2.666h4z"></path>
                                      </svg>
                                  </div>
                                  <div>
                                      <div class="my-2 d-flex align-items-center">
                                          <h5 class="fs-6 fw-bold mb-0" v-if="rentOrderItem.realestate">
                                              {{ rentOrderItem.realestate.street }}
                                          </h5>
                                      </div>
                                      <dl class="mb-0" v-if="rentOrderItem.realestate">
                                          <dt>رقم الوحدة:</dt>
                                          <dd v-if="rentOrderItem.realestate">{{ rentOrderItem.realestate.realestate_number }}</dd>
                                          <br>
                                          <dt>رقم العداد:</dt>
                                          <dd v-if="rentOrderItem.realestate">{{ rentOrderItem.realestate.electric_meter }}</dd>
                                          <br>
                                          <dt>المساحة:</dt>
                                          <dd class="text-starrt" dir="ltr" v-if="rentOrderItem.realestate">
                                            {{ rentOrderItem.realestate.area }}
                                          </dd>
                                      </dl>
                                  </div>
                              </div>
                              <!-- end col -->
                              <div
                                      class="col-xxl-4 col-md-4 d-flex align-items-center gap-3 mb-4 mb-xxl-0"
                              >
                                  <div class="d-flex bg-dark bg-opacity-25 text-white p-4 rounded-3"
                                       style="font-size: 3.75rem">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                                           viewBox="0 0 32 32" fill="currentColor">
                                          <title>building-person</title>
                                          <path d="M9.334 13.334h-2.666v-12c0-0 0-0.001 0-0.002 0-0.736 0.596-1.332 1.332-1.332 0.001 0 0.001 0 0.002 0h16c0 0 0.001 0 0.002 0 0.736 0 1.332 0.596 1.332 1.332 0 0.001 0 0.001 0 0.002v-0 8h-2.668v-6.668h-13.334z"></path>
                                          <path d="M31.126 32h-4.559c-0.224-1.529-0.752-2.9-1.522-4.102l0.025 0.041h1.996v-2.707h-4.061v0.31c-1.468-1.276-3.32-2.146-5.36-2.419l-0.053-0.006v-0.403c2.798-0.609 4.862-3.064 4.862-6.002 0-2.811-1.891-5.181-4.47-5.907l-0.043-0.010c0.248-0.276 0.605-0.448 1.003-0.448 0.003 0 0.005 0 0.008 0h12.178c0.744 0 1.347 0.603 1.347 1.347v0 18.951c0 0.002 0 0.004 0 0.007 0 0.744-0.603 1.347-1.347 1.347-0.001 0-0.002 0-0.004 0h0zM23.005 19.821v2.705h4.061v-2.705zM23.005 14.408v2.707h4.061v-2.707zM5.912 32h-4.564c-0.744 0-1.347-0.603-1.347-1.347v0-13.538c0-0.744 0.603-1.347 1.347-1.347v0h8.758c-0.049 0.286-0.077 0.615-0.077 0.951v0c0.050 2.913 2.090 5.335 4.817 5.967l0.042 0.008v0.436c-2.094 0.3-3.943 1.178-5.426 2.467l0.013-0.011v-0.354h-4.061v2.707h2.021c-0.755 1.158-1.29 2.529-1.509 4.003l-0.007 0.056zM5.406 19.821v2.705h4.067v-2.705z"></path>
                                          <path d="M16.281 11.944c0.027-0.001 0.059-0.001 0.092-0.001 2.758 0 4.994 2.236 4.994 4.994s-2.236 4.994-4.994 4.994c-0.032 0-0.064-0-0.097-0.001l0.005 0c-0.005 0-0.011 0-0.018 0-2.775 0-5.030-2.225-5.079-4.987l-0-0.005c0.048-2.768 2.304-4.994 5.079-4.994 0.006 0 0.012 0 0.019 0h-0.001z"></path>
                                          <path d="M16.281 24.426c4.408 0.038 8.044 3.294 8.676 7.53l0.006 0.049h-17.448c0.674-4.297 4.336-7.549 8.763-7.579l0.003-0z"></path>
                                      </svg>
                                  </div>
                                  <div>
                                      <div class="my-2 d-flex align-items-center">
                                        <small class="text-muted ms-1">المالك:</small>
                                        <h5 class="fs-6 fw-bold mb-0" v-if="rentOrderItem.realestate && rentOrderItem.realestate.owner">
                                          {{ rentOrderItem.realestate.owner.name }}
                                        </h5>
                                      </div>
                                      <dl class="mb-0">
                                          <dt>العنوان:</dt>
                                          <dd v-if="rentOrderItem.realestate && rentOrderItem.realestate.owner">{{ rentOrderItem.realestate.owner.address }}</dd>
                                          <br>
                                          <dt>البطاقة:</dt>
                                          <dd v-if="rentOrderItem.realestate && rentOrderItem.realestate.owner">{{ rentOrderItem.realestate.owner.id_card }}</dd>
                                          <br>
                                          <dt>الجوال:</dt>
                                          <dd class="text-starrt" dir="ltr" v-if="rentOrderItem.realestate && rentOrderItem.realestate.owner">
                                              {{ rentOrderItem.realestate.owner.mobile }}
                                          </dd>
                                      </dl>
                                  </div>
                              </div>
                              <!-- end col -->
                  </div>
                  <!-- end row -->
                </td>
                
              </tr>
            </template>
            </tbody>
          </table>
        </div>

        <div class="card bg-white rounded-2 border border-0 mb-2 d-lg-none .d-xl-block" v-for="rentOrderItem, index in rentOrders.data" :key="rentOrderItem.id">
        <div class="card-header bg-white">
          <div class="row">
            <div class="col-4">
              ( {{ index + 1 }} )
            </div>
            <div class="col-8">
              <td class="actions">
                  <router-link
                    :to="{ name: 'SetReminder', params: {id: rentOrderItem.id} }"
                    class="btn btn-icon btn-primary me-2"
                    style="--bs-bg-opacity: 0.08"
                  >
                    <i class="bi bi-bell-fill"></i>
                  </router-link>
                  <router-link
                    :to="{ name: 'SetInstallment', params: {id: rentOrderItem.id} }"
                    class="btn btn-icon btn-primary me-2"
                    style="--bs-bg-opacity: 0.08"
                  >
                  
                    <i class="bi bi-patch-plus"></i>
                  </router-link>
                  <button
                    class="btn collapsed btn-icon btn-light me-2"
                    style="--bs-bg-opacity: 0.08"
                    data-bs-toggle="collapse"
                    :data-bs-target="'#data' + rentOrderItem.id"
                    aria-expanded="false"
                    aria-controls="data-2"
                  >
                    <i class="bi bi-chevron-down"></i>
                  </button>
                  <router-link
                          :to="{ name: 'EditRentOrder', params: {id: rentOrderItem.id} }"
                          class="btn btn-link bg-primary py-1 px-2 me-2"
                          style="--bs-bg-opacity: 0.08"
                  >
                      <i class="bi bi-pencil-square"></i>
                  </router-link>
                  <button
                          @click.prevent="deleteRentOrder(rentOrderItem.id)"
                          class="btn btn-link bg-danger link-danger py-1 px-2"
                          style="--bs-bg-opacity: 0.08"
                  >
                      <i class="bi bi-trash"></i>
                  </button>
                </td>
            </div>
          </div>
        </div>
        <div class="card-body">
          <blockquote class="blockquote mb-0">
               <div class="text-primary">الوحدة العقارية <span class="text-dark" v-if="rentOrderItem.realestate">{{ rentOrderItem.realestate.name }}</span></div>      
               <div class="text-primary">نوع الوحدة <span class="text-dark" v-if="rentOrderItem.realestate">{{ rentOrderItem.realestate.type }}</span></div>  
               <div class="text-primary">الحي <span class="text-dark" v-if="rentOrderItem.realestate">{{ rentOrderItem.realestate.neighborhood.name }}</span></div>    
               <!-- <div class="text-primary">المستأجر <span class="text-dark" v-if="rentOrderItem.customer">{{ rentOrderItem.customer.name }}</span></div>     -->
               <div class="text-primary">الفترة من <span class="text-dark">{{ rentOrderItem.date_from }}</span></div>    
               <div class="text-primary">الفترة إلى <span class="text-dark">{{ rentOrderItem.date_to }}</span></div>    
               <div class="text-primary">سعر التأجير <span class="text-dark">{{ rentOrderItem.rent_price }}</span></div>    
               <div class="text-primary">المدفوع <span class="text-dark">
                <span class="hide">{{ total = 0 }}</span>
                  <span class="hide" v-for="installment in rentOrderItem.installments">
                    <span class="hide" v-if="installment.paid == 1">{{ total += installment.amount }}</span>
                  </span>
                  <strong>{{ total }}</strong>
               </span></div>    

               <div class="text-primary">حالة السداد <span class="text-dark">
                <td v-if="total == rentOrderItem.rent_price"><strong class="text-success">كلي</strong></td>
                <td v-if="total != rentOrderItem.rent_price"><strong class="text-danger">جزئي</strong></td>
               </span></div>   

               
                <tr class="collapse bg-light" :id="'data' + rentOrderItem.id" colspan="9">
                <td colspan="9">
                  <div class="row">
                    <div
                                      class="col-xxl-4 col-md-4 d-flex align-items-center gap-3 mb-4 mb-xxl-0"
                              >
                                  <div class="d-flex bg-dark bg-opacity-25 text-white p-4 rounded-3"
                                       style="font-size: 3.75rem">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                                           viewBox="0 0 32 32" fill="currentColor">
                                          <title>building-person</title>
                                          <path d="M9.334 13.334h-2.666v-12c0-0 0-0.001 0-0.002 0-0.736 0.596-1.332 1.332-1.332 0.001 0 0.001 0 0.002 0h16c0 0 0.001 0 0.002 0 0.736 0 1.332 0.596 1.332 1.332 0 0.001 0 0.001 0 0.002v-0 8h-2.668v-6.668h-13.334z"></path>
                                          <path d="M31.126 32h-4.559c-0.224-1.529-0.752-2.9-1.522-4.102l0.025 0.041h1.996v-2.707h-4.061v0.31c-1.468-1.276-3.32-2.146-5.36-2.419l-0.053-0.006v-0.403c2.798-0.609 4.862-3.064 4.862-6.002 0-2.811-1.891-5.181-4.47-5.907l-0.043-0.010c0.248-0.276 0.605-0.448 1.003-0.448 0.003 0 0.005 0 0.008 0h12.178c0.744 0 1.347 0.603 1.347 1.347v0 18.951c0 0.002 0 0.004 0 0.007 0 0.744-0.603 1.347-1.347 1.347-0.001 0-0.002 0-0.004 0h0zM23.005 19.821v2.705h4.061v-2.705zM23.005 14.408v2.707h4.061v-2.707zM5.912 32h-4.564c-0.744 0-1.347-0.603-1.347-1.347v0-13.538c0-0.744 0.603-1.347 1.347-1.347v0h8.758c-0.049 0.286-0.077 0.615-0.077 0.951v0c0.050 2.913 2.090 5.335 4.817 5.967l0.042 0.008v0.436c-2.094 0.3-3.943 1.178-5.426 2.467l0.013-0.011v-0.354h-4.061v2.707h2.021c-0.755 1.158-1.29 2.529-1.509 4.003l-0.007 0.056zM5.406 19.821v2.705h4.067v-2.705z"></path>
                                          <path d="M16.281 11.944c0.027-0.001 0.059-0.001 0.092-0.001 2.758 0 4.994 2.236 4.994 4.994s-2.236 4.994-4.994 4.994c-0.032 0-0.064-0-0.097-0.001l0.005 0c-0.005 0-0.011 0-0.018 0-2.775 0-5.030-2.225-5.079-4.987l-0-0.005c0.048-2.768 2.304-4.994 5.079-4.994 0.006 0 0.012 0 0.019 0h-0.001z"></path>
                                          <path d="M16.281 24.426c4.408 0.038 8.044 3.294 8.676 7.53l0.006 0.049h-17.448c0.674-4.297 4.336-7.549 8.763-7.579l0.003-0z"></path>
                                      </svg>
                                  </div>
                                  <div>
                                      <div class="my-3 d-flex align-items-center">
                                          <h5 class="fs-6 fw-bold mb-0">
                                              
                                          </h5>
                                          <small class="text-muted ms-1">المستأجر</small>
                                          <span v-if="rentOrderItem.customer">{{ rentOrderItem.customer.name }}</span>
                                      </div>
                                      <dl class="mb-0 lh-lg">
                                          <dt>البطاقة:</dt>
                                          <dd><span v-if="rentOrderItem.customer">{{ rentOrderItem.customer.id_card }}</span></dd>
                                          <br>
                                          <dt>الجوال:</dt>
                                          <dd class="text-starrt" dir="ltr">
                                            <span v-if="rentOrderItem.customer">{{ rentOrderItem.customer.mobile }}</span>
                                          </dd>
                                      </dl>
                                  </div>
                    </div>
                    <!-- end col -->
                    <div
                                      class="col-xxl-4 col-md-4 d-flex align-items-center gap-3 mb-4 mb-xxl-0"
                              >
                                  <div class="d-flex bg-dark bg-opacity-25 text-white p-4 rounded-3"
                                       style="font-size: 3.75rem">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                                           viewBox="0 0 32 32" fill="currentColor">
                                          <title>building</title>
                                          <path d="M9.332 13.334h-2.666v-12c0-0 0-0.001 0-0.002 0-0.736 0.596-1.332 1.332-1.332 0.001 0 0.001 0 0.002 0h16c0 0 0.001 0 0.002 0 0.736 0 1.332 0.596 1.332 1.332 0 0.001 0 0.001 0 0.002v-0 8h-2.668v-6.668h-13.334z"></path>
                                          <path d="M30.666 10.666h-12c-0 0-0.001 0-0.002 0-0.736 0-1.332 0.596-1.332 1.332 0 0.001 0 0.001 0 0.002v-0 17.332h-2.666v-12c0-0 0-0.001 0-0.002 0-0.736-0.596-1.332-1.332-1.332-0.001 0-0.001 0-0.002 0h-11.998c-0 0-0.001 0-0.002 0-0.736 0-1.332 0.596-1.332 1.332 0 0.001 0 0.001 0 0.002v-0 13.334c0 0 0 0.001 0 0.002 0 0.736 0.596 1.332 1.332 1.332 0.001 0 0.001 0 0.002 0h29.332c0 0 0.001 0 0.002 0 0.736 0 1.332-0.596 1.332-1.332 0-0.001 0-0.001 0-0.002v0-18.666c0-0.001 0-0.001 0-0.002 0-0.736-0.596-1.332-1.332-1.332-0.001 0-0.001 0-0.002 0h0zM9.334 28h-4v-2.668h4zM9.334 22.666h-4v-2.666h4zM26.668 28h-4.002v-2.668h4zM26.668 22.666h-4.002v-2.666h4zM26.668 17.332h-4.002v-2.666h4z"></path>
                                      </svg>
                                  </div>
                                  <div>
                                      <div class="my-2 d-flex align-items-center">
                                          <h5 class="fs-6 fw-bold mb-0" v-if="rentOrderItem.realestate">
                                              {{ rentOrderItem.realestate.street }}
                                          </h5>
                                      </div>
                                      <dl class="mb-0" v-if="rentOrderItem.realestate">
                                          <dt>رقم الوحدة:</dt>
                                          <dd v-if="rentOrderItem.realestate">{{ rentOrderItem.realestate.realestate_number }}</dd>
                                          <br>
                                          <dt>رقم العداد:</dt>
                                          <dd v-if="rentOrderItem.realestate">{{ rentOrderItem.realestate.electric_meter }}</dd>
                                          <br>
                                          <dt>المساحة:</dt>
                                          <dd class="text-starrt" dir="ltr" v-if="rentOrderItem.realestate">
                                            {{ rentOrderItem.realestate.area }}
                                          </dd>
                                      </dl>
                                  </div>
                              </div>
                              <!-- end col -->
                              <div
                                      class="col-xxl-4 col-md-4 d-flex align-items-center gap-3 mb-4 mb-xxl-0"
                              >
                                  <div class="d-flex bg-dark bg-opacity-25 text-white p-4 rounded-3"
                                       style="font-size: 3.75rem">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                                           viewBox="0 0 32 32" fill="currentColor">
                                          <title>building-person</title>
                                          <path d="M9.334 13.334h-2.666v-12c0-0 0-0.001 0-0.002 0-0.736 0.596-1.332 1.332-1.332 0.001 0 0.001 0 0.002 0h16c0 0 0.001 0 0.002 0 0.736 0 1.332 0.596 1.332 1.332 0 0.001 0 0.001 0 0.002v-0 8h-2.668v-6.668h-13.334z"></path>
                                          <path d="M31.126 32h-4.559c-0.224-1.529-0.752-2.9-1.522-4.102l0.025 0.041h1.996v-2.707h-4.061v0.31c-1.468-1.276-3.32-2.146-5.36-2.419l-0.053-0.006v-0.403c2.798-0.609 4.862-3.064 4.862-6.002 0-2.811-1.891-5.181-4.47-5.907l-0.043-0.010c0.248-0.276 0.605-0.448 1.003-0.448 0.003 0 0.005 0 0.008 0h12.178c0.744 0 1.347 0.603 1.347 1.347v0 18.951c0 0.002 0 0.004 0 0.007 0 0.744-0.603 1.347-1.347 1.347-0.001 0-0.002 0-0.004 0h0zM23.005 19.821v2.705h4.061v-2.705zM23.005 14.408v2.707h4.061v-2.707zM5.912 32h-4.564c-0.744 0-1.347-0.603-1.347-1.347v0-13.538c0-0.744 0.603-1.347 1.347-1.347v0h8.758c-0.049 0.286-0.077 0.615-0.077 0.951v0c0.050 2.913 2.090 5.335 4.817 5.967l0.042 0.008v0.436c-2.094 0.3-3.943 1.178-5.426 2.467l0.013-0.011v-0.354h-4.061v2.707h2.021c-0.755 1.158-1.29 2.529-1.509 4.003l-0.007 0.056zM5.406 19.821v2.705h4.067v-2.705z"></path>
                                          <path d="M16.281 11.944c0.027-0.001 0.059-0.001 0.092-0.001 2.758 0 4.994 2.236 4.994 4.994s-2.236 4.994-4.994 4.994c-0.032 0-0.064-0-0.097-0.001l0.005 0c-0.005 0-0.011 0-0.018 0-2.775 0-5.030-2.225-5.079-4.987l-0-0.005c0.048-2.768 2.304-4.994 5.079-4.994 0.006 0 0.012 0 0.019 0h-0.001z"></path>
                                          <path d="M16.281 24.426c4.408 0.038 8.044 3.294 8.676 7.53l0.006 0.049h-17.448c0.674-4.297 4.336-7.549 8.763-7.579l0.003-0z"></path>
                                      </svg>
                                  </div>
                                  <div>
                                      <div class="my-2 d-flex align-items-center">
                                        <small class="text-muted ms-1">المالك</small>
                                        <h5 class="fs-6 fw-bold mb-0"  v-if="rentOrderItem.realestate && rentOrderItem.realestate.owner">
                                          {{ rentOrderItem.realestate.owner.name }}
                                        </h5>
                                      </div>
                                      <dl class="mb-0">
                                          <dt>العنوان:</dt>
                                          <dd v-if="rentOrderItem.realestate && rentOrderItem.realestate.owner">{{ rentOrderItem.realestate.owner.address }}</dd>
                                          <br>
                                          <dt>البطاقة:</dt>
                                          <dd v-if="rentOrderItem.realestate && rentOrderItem.realestate.owner">{{ rentOrderItem.realestate.owner.id_card }}</dd>
                                          <br>
                                          <dt>الجوال:</dt>
                                          <dd class="text-starrt" dir="ltr" v-if="rentOrderItem.realestate && rentOrderItem.realestate.owner">
                                              {{ rentOrderItem.realestate.owner.mobile }}
                                          </dd>
                                      </dl>
                                  </div>
                              </div>
                              <!-- end col -->
                  </div>
                  <!-- end row -->
                </td>
              </tr>
          </blockquote>
        </div>
      </div>

      <div>
        <Pagination :data="rentOrders" align="right" @pagination-change-page="getRentOrders"/>
      </div>

</template>

<script>
import LaravelVuePagination from 'laravel-vue-pagination'
import axios from 'axios';

export default{
  name: 'IndexRentOrder',
  components:{
    'Pagination': LaravelVuePagination
  },
  data: function(){
      return {
          fullPage: false,
          rentOrders: {},
          date_from: '',
          date_to: '',
          search: '',
          type: 'realestate',
      }
  },
  created(){
    
    this.getRentOrders();
    
  },
  methods: {
      async paidTotal(){

      },
      async getRentOrders(page = 1){
        let loader = this.$loading.show({
            color: '#0d6efd',
            loader: 'dots',
            backgroundColor: '#e4e4e4',
        });
          let url = `${localStorage.getItem('default_office')}/rentOrder?page=${page}`;
          await axios.get(url)
          .then(response => {
            // console.log(response);
            this.rentOrders = response.data;
            loader.hide();
          });
      },
      async sendReminder(){
        this.$swal.fire({
        // toast: true,
        title: 'هل تريد إرسال التنبيهات لهذا اليوم',
        icon: 'question',
        iconHtml: '؟',
        confirmButtonText: 'نعم',
        cancelButtonText: 'لا',
        showCancelButton: true,
        showCloseButton: true
        }).then(result=>{
          if(result.isConfirmed){
            let url = `${localStorage.getItem('default_office')}/send`;
            axios.post(url).then(response => {
                this.$swal.fire({
                    toast: true,
                    position: 'top',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 2500,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })
            });
          }
        })
      },
      async filter(page = 1){
        const response = await axios.get(`${localStorage.getItem('default_office')}/rentFilter?date_from=` + this.date_from + '&date_to=' + this.date_to  + '&type=' + this.type + '&fieldSearch=' + this.search + '&page=' + page)
        this.rentOrders = response.data
        // console.log(response.data)
      },
      deleteRentOrder(id){
        this.$swal.fire({
        // toast: true,
        title: 'هل تريد الحذف',
        icon: 'question',
        iconHtml: '؟',
        confirmButtonText: 'نعم',
        cancelButtonText: 'لا',
        showCancelButton: true,
        showCloseButton: true
      }).then(result=>{
        if(result.isConfirmed){
            const response = axios.delete(`${localStorage.getItem('default_office')}/rentOrder/${id}`);
            this.getRentOrders()
        }
      })
        
      },
      
      cancel(){
        history.back()
      },

      async rentExport(){
        const response = await axios.get(`${localStorage.getItem('default_office')}/rentExport`)
        window.location.href = response.config.baseURL + `${localStorage.getItem('default_office')}/rentExport`
      }

      // getRent(rentId){
      //     let loader = this.$loading.show({
      //         color: 'Maroon',
      //         loader: 'dots',
      //         backgroundColor: '#e4e4e4',
      //     });
      //         let url = `${localStorage.getItem('default_office')}/rentOrder/1`;
      //         axios.get(url).then(response => {
      //         // console.log(response.data.data);
      //         this.rent = response.data.data
      //         loader.hide();
      //     });
      // }
  }
}
</script>

<style>
.hide{
visibility: hidden;
}
</style>