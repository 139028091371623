<template>
  <div class="container-fluid">
    <div class="row flex-nowrap mb-3">
        <div class="col-auto px-0 px-lg-2 sidebar-placeholder" v-if="authenticated">
            <!-- Nav -->
                    <main>
                      <side-bar></side-bar>
                    </main>
            <!-- End nav -->
        </div>
        <!-- end col-auto -->
        <main class="col ps-md-2 pt-4">
            <!-- nav bar -->
            <nav-bar v-if="authenticated"></nav-bar>
            <!-- pages -->
            <router-view></router-view>
        </main>
        
            
    </div>
    <!-- end row -->
</div>
<!-- end container -->
</template>

<script>
import NavBar from '@/components/office/layout/NavBar.vue'
import SideBar from '@/components/office/layout/SideBar.vue'
import { mapGetters } from 'vuex'

export default{
    name: 'MasterPage',
    components: {
        NavBar,
        SideBar 
    },
    props: ['appName'],
    computed: {

    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user'
    }),
  }
}
</script>

<style>
  /* a.router-link-active {
    background-color: #0d6efd;
    border-radius: 10px;
    color: white;
  }

  a.router-link-exact-active{
    background-color: #0d6efd;
      border-radius: 10px;
      color: white;
  } */
  </style>