<template>
        <div class="bg-white px-4 pt-4 pb-5 rounded-4 mb-3">
            <h3 class="fs-5 mb-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                width="1em"
                viewBox="0 0 24 15"
              >
                <g
                  id="Arrow_-_Right"
                  data-name="Arrow - Right"
                  transform="translate(0 -9)"
                >
                  <path
                    id="Arrow_-_Right-2"
                    data-name="Arrow - Right"
                    d="M13.411,15h0a2.824,2.824,0,0,1-1.213-.275A2.41,2.41,0,0,1,11.12,13.6a11.846,11.846,0,0,1-.348-1.331,25.874,25.874,0,0,1-.323-4.314l0-.442a32.417,32.417,0,0,1,.288-4.61l.151-.68a4.454,4.454,0,0,1,.311-.98A2.434,2.434,0,0,1,13.334,0h.077A6.865,6.865,0,0,1,15.2.509,30.162,30.162,0,0,1,22.66,5.246l.505.5a4.551,4.551,0,0,1,.373.417A2.128,2.128,0,0,1,24,7.49a2.223,2.223,0,0,1-.5,1.422l-.518.525-.116.113a29.575,29.575,0,0,1-7.855,5.03l-.325.117A6.012,6.012,0,0,1,13.411,15ZM2.005,9.4h0A1.956,1.956,0,0,1,0,7.5,1.956,1.956,0,0,1,2.005,5.6l4.932.41A1.534,1.534,0,0,1,8.509,7.5,1.534,1.534,0,0,1,6.937,8.988Z"
                    transform="translate(0 9)"
                    fill="currentColor"
                  />
                </g>
              </svg>
              <li v-for="error in errors" class="text-danger fs-6">{{ error[0] }}</li>
              <b class="align-middle">تعديل مستخدم</b>
            </h3>
            <form @submit.prevent="updateUser" novalidate style="max-width: 580px">
              <label class="mb-1 text-muted" for="name">الاسم</label>
              <input
                v-model="user.name"
                type="text"
                id="name"
                required
                placeholder=""
                class="form-control py-2 mb-4"
              />
              <label class="mb-1 text-muted" for="email"
                >البريد الإلكتروني</label
              >
              <input
                v-model="user.email"
                type="email"
                id="email"
                required
                placeholder="البريد الإلكتروني"
                class="form-control py-2 mb-4 text-start"
              />
              <label class="mb-1 text-muted" for="phone">رقم الجوال</label>
              <input
                v-model="user.mobile"
                type="text"
                required
                id="phone"
                class="form-control py-2 mb-4"
              />
              <label class="mb-1 text-muted" for="password">كلمة المرور</label>
              <input
                v-model="password"
                type="password"
                id="password"
                autocomplete="off"
                class="form-control py-2 mb-4"
              />
              <div class="d-flex gap-2 pt-2">
                <button
                  type="submit"
                  class="btn btn-sm btn-arrow btn-primary w-100"
                >
                  <span>تعديل</span>
                  <i class="bi bi-arrow-left"></i>
                </button>
                <button
                  @click.prevent="cancel"
                  type="button"
                  class="btn btn-sm btn-outline-primary w-100"
                >
                  رجوع
                </button>
              </div>
            </form>
          </div>
</template>

<script>
    import axios from 'axios';
    import { mapGetters } from 'vuex';

    export default{
        name: 'EditUser',
        data: function(){
            return {
                user: {},
                password: '',
                errors: []
            }
        },
        created(){
            this.getUser();
        },
        methods: {
            async getUser(){
                const response = await axios.get(`${localStorage.getItem('default_office')}/user/${this.$route.params.id}`)
                this.user = response.data.data[0]

            },
            async updateUser(){
                try {
                  this.errors = []
                  const response = await axios.put(`${localStorage.getItem('default_office')}/user/${this.$route.params.id}`, {
                    name: this.user.name,
                    email: this.user.email,
                    mobile: this.user.mobile,
                    password: this.password
                    })
                    this.$swal.fire({
                        toast: true,
                        position: 'top',
                        icon: 'success',
                        title: 'تم تعديل البيانات بنجاح',
                        showConfirmButton: false,
                        timer: 2500,
                        timerProgressBar: true,
                            didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })
                    this.$router.push('/user')
                } catch (error) {
                  this.errors = error.response.data.errors
                }
            },
            cancel(){
              history.back();
            }
        },
        computed: {
            
        }
    }
</script>