<template>
        <div
                class="d-flex flex-md-row flex-column gap-3 align-items-md-center justify-content-between"
        >
        <li v-for="error in errors" class="text-danger fs-6">{{ error[0] }}</li>
        <!-- <form>
        <div
                class="d-flex align-items-sm-center flex-column flex-sm-row gap-2"
        >
            
            
            <select v-model="sortField"
                    class="form-select border-white w-auto"
                    style="min-width: 120px"
            >
                <option :selected="selected">الترتيب حسب</option>
                <option value="name">الاسم</option>
                <option value="mobile">رقم الجوال</option> 
            </select>
            <select v-model="sort"
                    class="form-select border-white w-auto"
                    style="min-width: 120px"
            >
                <option selected>الترتيب حسب الإضافة</option>
                <option value="DESC">الأحدث</option>
                <option value="ASC">الأقدم</option>
            </select>
            <select v-model="paginateNumber"
                    class="form-select border-white w-auto"
                    style="min-width: 120px"
            >
                <option selected>الصفوف المعروضة</option>
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
            </select>
            
            <input
                    v-model="search"
                    type="search"
                    placeholder="اكتب كلمة للبحث"
                    class="form-control border-white"
            />
            <button class="btn btn-primary col-lg-2">
              بحث <i class="bi bi-search"></i>
            </button>
        </div>
      </form> -->
        <div>
            <!-- <button class="btn py-2 btn-success mb-2">
                <i class="bi bi-file-earmark-spreadsheet-fill fs-5"></i>
            </button> -->
            <button
              @click.prevent="sendReminder()"
              class="btn btn-success mb-2"
              data-bs-toggle="modal"
              data-bs-target="#notificationConf"
            >
              إرسال التنبيهات السريعة
            </button>
            <router-link :to="{ name: 'AddQReminder' }" class="btn py-2 ms-1 btn-primary mb-2">
                <i class="bi bi-plus-circle fs-5"></i>
            </router-link>
        </div>
    </div>
    <div class="table-responsive-lg mt-4 d-none d-sm-block">
        <table class="table table-borderless bg-white rounded-4">
            <thead class="border-bottom">
            <tr>
                <th scope="col">ID</th>
                <th scope="col">تاريخ التذكير</th>
                <th scope="col">الجوال</th>
                <th scope="col">الرسالة</th>
                <th scope="col">التعديل</th>
            </tr>
            </thead>
            <tbody>
              <tr v-for="item, index in quickReminders.data">
                <th scope="row">{{ index + 1 }}</th>
                <td>{{ item.date }}</td>
                <td>{{ item.mobile }}</td>
                <td dir="ltr" class="text-start">{{ item.reminderOrder.message }}</td>
                <td class="actions">
                    <router-link :to="{ name: 'EditQReminder', params: { id: item.id } }"
                            class="btn btn-link bg-primary py-1 px-2 me-2"
                            style="--bs-bg-opacity: 0.08"
                    >
                        <i class="bi bi-pencil-square"></i>
                    </router-link>
                    <button 
                            @click.prevent="deleteQReminder(item.id)"
                            class="btn btn-link bg-danger link-danger py-1 px-2"
                            style="--bs-bg-opacity: 0.08"
                    >
                        <i class="bi bi-trash"></i>
                    </button>
                </td>
            </tr>
            </tbody>
        </table>
      </div>

      

      <div class="card bg-white rounded-2 mb-2 d-lg-none border border-0  
      " v-for="item, index in quickReminders.data">
          <div class="card-header bg-white">
            <div class="row">
              <div class="col-8">
                ( {{ item.id + 1 }} )
              </div>
              <div class="col-4">
                <router-link :to="{ name: 'EditQReminder', params: { id: item.id } }"
                        class="btn btn-link bg-primary py-1 px-2 me-2"
                        style="--bs-bg-opacity: 0.08"
                >
                    <i class="bi bi-pencil-square"></i>
                </router-link>
                <button 
                            @click.prevent="deleteQReminder(item.id)"
                            class="btn btn-link bg-danger link-danger py-1 px-2"
                            style="--bs-bg-opacity: 0.08"
                    >
                        <i class="bi bi-trash"></i>
                    </button>
            </div>
          </div>
      </div>
          <div class="card-body">
            <blockquote class="blockquote mb-0">
              <div class="text-primary">تاريخ التذكير <span class="text-dark">{{ item.date }}</span></div>      
              <div class="text-primary">الجوال <span class="text-dark">{{ item.mobile }}</span></div>      
              <div class="text-primary">الرسالة <span class="text-dark">{{ item.reminderOrder.message }}</span></div>    
            </blockquote>
          </div>
        </div>
      <div>
        <Pagination :data="customers" align="right" @pagination-change-page="getQReminders"/>
      </div>
</template>

<script>
import axios from 'axios'
export default{
    name: 'IndexQReminder',
    data(){
        return {
            quickReminders : {},
            errors: {}
        }
    },
    created(){
        this.getQReminders()
    },
    methods:{
        async getQReminders(){
            try{
                await axios.get(`${localStorage.getItem('default_office')}/quick-reminder`).then(response => {
                    this.quickReminders = response.data
                    console.log(response);
                })
            }catch(err){
                
            }
        },
        async deleteQReminder(id){
            this.$swal.fire({
                // toast: true,
                title: 'هل تريد الحذف',
                icon: 'question',
                iconHtml: '؟',
                confirmButtonText: 'نعم',
                cancelButtonText: 'لا',
                showCancelButton: true,
                showCloseButton: true
                }).then(result=>{
                if(result.isConfirmed){
                    const response = axios.delete(`${localStorage.getItem('default_office')}/quick-reminder/${id}`)
                        this.getQReminders()
                    }
                })
        },
        async sendReminder(){
            try{
                await axios.post(`${localStorage.getItem('default_office')}/sendQReminder`).then(response => {
                    console.log(response)
                    this.$swal.fire({
                    toast: true, 
                    position: 'top', 
                    icon: 'success', 
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 2500, timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })
                })
            }catch(err){
                
            }    
      },
    }
}
</script>
