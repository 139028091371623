<template>
  <div
            class="d-flex flex-md-row flex-column gap-3 align-items-md-center justify-content-between"
          >
            <!-- <div
              class="d-flex align-items-sm-center flex-column flex-sm-row gap-2"
            >
              <input
                type="search"
                placeholder="بحث"
                class="form-control border-white"
              />
              <select
                class="form-select border-white w-auto"
                style="min-width: 120px"
              >
                <option value="">نوع الطلب</option>
              </select>
            </div> -->
            <div>
              <!-- <button class="btn py-2 btn-success">
                <i class="bi bi-file-earmark-spreadsheet-fill fs-5"></i>
              </button> -->
              <router-link :to="{name: 'AddContact'}" class="btn py-2 ms-1 btn-primary mb-2">
                <i class="bi bi-plus-circle fs-5"></i>
            </router-link>

            </div>
          </div>
          <div class="table-responsive-lg mt-4 d-none d-sm-block">
            <table class="table table-borderless bg-white rounded-4">
              <thead class="border-bottom">
                <tr>
                  <th scope="col">رقم الطلب</th>
                  <th scope="col">نوع الطلب</th>
                  <th scope="col">الرسالة</th>
                  <!-- <th scope="col">الرد على الطلب</th> -->
                  <!-- <th scope="col">التعديل</th> -->
                </tr>
              </thead>
              <tbody>
                <tr v-for="(contact, index) in contacts.data" :key="contact.id">
                  <th scope="row">{{ contact.id }}</th>
                  <td v-if="contact.contact_type == 'support'">دعم</td>
                  <td v-if="contact.contact_type == 'suggest'">إقتراح</td>
                  <td style="min-width: 240px">
                    {{ contact.message }}
                  </td>
                  <td class="actions">
                    <!-- <a
                      class="btn btn-link bg-success link-success py-1 px-2 me-2"
                      style="--bs-bg-opacity: 0.08"
                      href="/support-reply.html"
                    >
                      <i class="bi bi-check-circle-fill"></i>
                    </a> -->
                    <!-- <button
                      @click.prevent="deleteContact(contact.id)"
                      class="btn btn-link bg-danger link-danger py-1 px-2"
                      style="--bs-bg-opacity: 0.08"
                    >
                      <i class="bi bi-trash"></i>
                    </button> -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="card bg-white rounded-2 border border-0 mb-2 d-lg-none .d-xl-block" v-for="(contact, index) in contacts.data" :key="contact.id">
          <div class="card-header bg-white">
            ( {{ index + 1 }} )
          </div>
          <div class="card-body">
            <blockquote class="blockquote mb-0">
              <div class="text-primary">النوع <span class="text-dark">{{ contact.contact_type }}</span></div>      
              <div class="text-primary">الرسالة <span class="text-dark">{{ contact.message }}</span></div>    
            </blockquote>
          </div>
        </div>

</template>

<script>
import LaravelVuePagination from 'laravel-vue-pagination'
import axios from 'axios'
import {mapGetters} from 'vuex'

export default{
    name: 'IndexContact',
    components: {
      'Pagination': LaravelVuePagination
    },
    data:function(){
      return {
        contacts: {},
        fullPage: false,
        search: '',
        searchField: '',
        sortField: 'created_at',
        sort: 'DESC',
        paginateNumber: 5,
      }
    },
    async mounted(){
      this.getContacts();
    },
    methods:{
      async getContacts(page = 1){
        let loader = this.$loading.show({
            color: '#0d6efd',
            loader: 'dots',
            backgroundColor: '#e4e4e4',
        });

        const response = await axios.get(`${localStorage.getItem('default_office')}/contact?page= ${page}`)

        // console.log(response)
        this.contacts = response.data;
        loader.hide();
      },

      deleteContact(id){
        let url = `${localStorage.getItem('default_office')}/contact/${id}`;
        this.$swal.fire({
          // toast: true,
          title: 'هل تريد الحذف',
          icon: 'question',
          iconHtml: '؟',
          confirmButtonText: 'نعم',
          cancelButtonText: 'لا',
          showCancelButton: true,
          showCloseButton: true
        }).then(result=>{
          if(result.isConfirmed){
              const response = axios.delete(url)
              this.getContacts();
          }
        })
      },
      async filter(page = 1){
        const response = await axios.get(`${localStorage.getItem('default_office')}/contactFilter?paginateNumber=` + this.paginateNumber + '&sortField=' + this.sortField + '&sort=' + this.sort + '&search=' + this.search + '&page=' + page)
        this.customers = response.data
      },
      async contactExport(){
        const response = await axios.get(`${localStorage.getItem('default_office')}/contactExport`)
        window.location.href = response.config.baseURL + `${localStorage.getItem('default_office')}/contactExport`
      }
    },
    computed: {
      statusUpdate(){
        let url = `${localStorage.getItem('default_office')}/contact/${id}`;
        axios.put(url, this.contact.status).then(response => {
          
        });
      },
    }
}
</script>

<style>

</style>