<template>
  <div class="bg-white px-4 pt-4 pb-5 rounded-4 mb-3">
          <h3 class="fs-5 mb-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              width="1em"
              viewBox="0 0 24 15"
            >
              <g
                id="Arrow_-_Right"
                data-name="Arrow - Right"
                transform="translate(0 -9)"
              >
                <path
                  id="Arrow_-_Right-2"
                  data-name="Arrow - Right"
                  d="M13.411,15h0a2.824,2.824,0,0,1-1.213-.275A2.41,2.41,0,0,1,11.12,13.6a11.846,11.846,0,0,1-.348-1.331,25.874,25.874,0,0,1-.323-4.314l0-.442a32.417,32.417,0,0,1,.288-4.61l.151-.68a4.454,4.454,0,0,1,.311-.98A2.434,2.434,0,0,1,13.334,0h.077A6.865,6.865,0,0,1,15.2.509,30.162,30.162,0,0,1,22.66,5.246l.505.5a4.551,4.551,0,0,1,.373.417A2.128,2.128,0,0,1,24,7.49a2.223,2.223,0,0,1-.5,1.422l-.518.525-.116.113a29.575,29.575,0,0,1-7.855,5.03l-.325.117A6.012,6.012,0,0,1,13.411,15ZM2.005,9.4h0A1.956,1.956,0,0,1,0,7.5,1.956,1.956,0,0,1,2.005,5.6l4.932.41A1.534,1.534,0,0,1,8.509,7.5,1.534,1.534,0,0,1,6.937,8.988Z"
                  transform="translate(0 9)"
                  fill="currentColor"
                />
              </g>
            </svg>
            <li v-for="error in errors" class="text-danger fs-6">{{ error[0] }}</li>
            <div v-if="error" class="text-danger fs-6">{{ error }} 
              <router-link :to="{ name: 'AddReminderOrder' }">
                اضغط هنا
              </router-link>
            </div>
            <b class="align-middle">تأجير وحدة جديدة</b>
          </h3>
          
          <form @submit.prevent="storeRentOrder()" novalidate style="max-width: 580px" enctype="multipart/form-data">
            <label class="mb-1 text-muted" for="renter">المستأجر</label>
                <select v-model="rentOrder.customer_id" type="text" id="renter" class="form-select py-2 mb-4">
                  <option value="">اختر</option>
                  <option v-for="customer in customers" :key="customer.id" :value="customer.id">{{ customer.name }}</option>
                </select>
            <label class="mb-1 text-muted" for="unit">الوحدة العقارية</label>
            <select v-model="realestate" type="text" id="unit" class="form-select py-2 mb-4">
              <option value="">اختر</option>
              <template v-for="(item, index) in realestates" :key="item.id">
                  <template v-if="item.status == 1">
                      <option :value="item">{{ (index+1) + '. ' + item.name}}</option>
                  </template>
              </template>
            </select>
            <label class="mb-1 text-muted" for="price">سعر التأجير</label>
            <input
              v-model="rentOrder.rent_price"
              type="text"
              id="price"
              required
              placeholder="سعر التأجير"
              class="form-control py-2 mb-4"
            />
            <label class="mb-1 text-muted" for="from">الفترة من</label>
            <input
              v-model="dateFrom" 
              type="date"
              id="from"
              required
              class="form-control py-2 mb-4"
            />
            <label class="mb-1 text-muted" for="to">الفترة إلى</label>
            <input
              v-model="dateTo" 
              type="date"
              id="to"
              required
              class="form-control py-2 mb-4"
            />
            
            <label class="mb-1 text-muted" for="to">عدد التذكيرات</label>
            <input
              v-model="rentOrder.reminder_count" 
              type="text"
              id="reminder_count"
              required
              class="form-control py-2 mb-4"
            />
            
            <label class="mb-1 text-muted" for="to">ملاحظة</label>
            <textarea
              v-model="rentOrder.note" 
              type="text"
              id="note"
              required
              class="form-control py-2 mb-4"
            ></textarea>

            <label class="mb-1 text-muted" for="to">رفع ملف</label>
            <file-pond 
              name= 'file'
              ref= 'pond'
              label-idle= 'Click to choose file or drage here...'
              accepted-file-types = "application/pdf"
              :allow-multiple="false "
              v-bind:files="myFiles"
              @init="filepondInitialized"
              :server= "{
                url: '',
                timeout: 7000,
                process: {
                  url: api_url + `/api/v1/${default_office}/upload`,
                  method:'POST',
                  withCredentials: false,
                  onload: handelFilepondLoad,
                  onerror: () => {}
                },
                remove: handelFilepondRemove,
                revert: handelFilepondRevert
              }"
              :file="myFiles"
              :init="handelFilepondInit"
            >
            </file-pond>

            <div class="d-flex gap-2 pt-2">
              <button
                type="submit"
                class="btn btn-sm btn-arrow btn-primary w-100"
              >
                <span>حفظ</span>
                <i class="bi bi-arrow-left"></i>
              </button>
              <button
                @click.prevent="cancel"
                type="button"
                class="btn btn-sm btn-outline-primary w-100"
              >
                رجوع
              </button>
            </div>
          </form>
        </div>
</template>

<script>
// Import Vue FilePond
import vueFilePond, {setOptions} from "vue-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import FilePond plugins
// Please note that you need to install these plugins separately

// Import image preview plugin styles
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import axios from 'axios';
import moment from 'moment';
import testUrl from 'vue'

// Create component
const FilePond = vueFilePond(
FilePondPluginFileValidateType,
FilePondPluginImagePreview,
);




export default{
  name: 'AddRentOrder',
  components: { 
      Datepicker,
      FilePond,
    },
  data(){
      return {
           officeId: localStorage.getItem('default_office'),
           dateFrom: {},
           dateTo: {},
           customers: {},
           realestates: {},
           realestate: {},
           rentOrder: {},
           fullPage: false,
           errors: null,
           error:'',
           file: '',
           myFiles: [],
           api_url: this.API_URL,
           default_office: localStorage.getItem('default_office')
      }
  },
  created(){
      this.getRenters();
      this.getRealestates();
  },
  methods: {
      dateTime(){
        return moment().format('YYYY-MM-DD')
      },
      handelFilepondInit(){

      },
      
      handelFilepondLoad(response){
        this.file = response
        // console.log(response)
      },
      handelFilepondRemove(source, load, error){
        this.file = ''
        load()
      },
      handelFilepondRevert(uniqueId, load, error){
        axios.post(`${localStorage.getItem('default_office')}/upload-revert`, {
          file: this.file
        })
        load()
        
      },
      async getRenters(){
        
          let loader = this.$loading.show({
              color: '#0d6efd',
              loader: 'dots',
              backgroundColor: '#e4e4e4',
          });
          let url = `${localStorage.getItem('default_office')}/customer?noPaginate=true`;
          await axios.get(url)
          .then(response => {
              this.customers = response.data.data;
              loader.hide();
          });
      },

      async getRealestates(){
          let url = `${localStorage.getItem('default_office')}/realestate?noPaginate=true`;
          await axios.get(url)
          .then(response => {
              this.realestates = response.data.data;
              
          })
      },

      async storeRentOrder(){
        
        try {
          this.errors = []
          if(this.dateFrom <= this.dateTime()){
            this.$swal.fire({
                  toast: true,
                  position: 'top',
                  icon: 'error',
                  title: ' تاريخ الفترة من، يجب ان يكون اكبر من تاريخ اليوم',
                  showConfirmButton: false,
                  // timer: 2500,
                  timerProgressBar: false,
                  didOpen: (toast) => {
                      toast.addEventListener('mouseenter', Swal.stopTimer)
                      toast.addEventListener('mouseleave', Swal.resumeTimer)
                  }
            });

            
            
        }else{

          if(this.dateTo < this.dateFrom){
            this.$swal.fire({
                  toast: true,
                  position: 'top',
                  icon: 'error',
                  title: ' تاريخ الفترة إلى، يجب ان يكون اكبر من تاريخ الفترة من',
                  showConfirmButton: false,
                  // timer: 2500,
                  timerProgressBar: false,
                  didOpen: (toast) => {
                      toast.addEventListener('mouseenter', Swal.stopTimer)
                      toast.addEventListener('mouseleave', Swal.resumeTimer)
                  }
            });
          }else{
            
              try{
                    const formData = new FormData();
                    formData.append('customer_id', this.rentOrder.customer_id);
                    formData.append('realestate_id', this.realestate.id);
                    formData.append('rent_price', this.rentOrder.rent_price);
                    formData.append('reminder_count', this.rentOrder.reminder_count);
                    formData.append('date_from', this.dateFrom);
                    formData.append('date_to', this.dateTo);
                    formData.append('note', this.rentOrder.note);
                    formData.append('file', this.file);
                    let url = `${localStorage.getItem('default_office')}/rentOrder`;
                    await axios.post(url, formData).then(response => {
                        if(response.data.error){
                          this.error = response.data.error
                        }else{
                                const realestateResponse = axios.put(`${localStorage.getItem('default_office')}/realestate/` + this.realestate.id, {
                                name: this.realestate.name,
                                type: this.realestate.type,
                                neighborhood_id: this.realestate.neighborhood.id,
                                street: this.realestate.street,
                                realestate_number: this.realestate.realestate_number,
                                area: this.realestate.area,
                                floor: this.realestate.floor,
                                electric_meter: this.realestate.electric_meter,
                                status: 2,
                                price: this.realestate.price,
                                owner_id: this.realestate.owner.id,
                              })    
                              this.$swal.fire({
                                toast: true,
                                position: 'top',
                                icon: 'success',
                                title: 'تم حفظ البيانات بنجاح',
                                showConfirmButton: false,
                                timer: 2500,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', Swal.stopTimer)
                                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                                }
                              });
                                // console.log(response)
                                this.reset();
                                this.$router.push('/rentOrder') 
                        }
                })
              }catch(error){
                // console.log(error.response.data.errors)

                this.errors = error.response.data.errors
              }


          }

        }


        } catch (error) {
          this.errors = error.response.data.errors
        }
      },
      cancel(){
          history.back();
      },
      reset(){
          this.rentOrder.customer_id = '';
          this.rentOrder.realestate_id = '';
          this.rentOrder.rent_price = '';
          this.dateFrom = '';
          this.dateTo = '';
          this.rentOrder.note = '';
      }
  }
}
</script>

<style>

</style>
