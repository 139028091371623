    <template>
        <div class="bg-white px-4 pt-4 pb-5 rounded-4 mb-3">
            <h3 class="fs-5 mb-4">
                <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        width="1em"
                        viewBox="0 0 24 15"
                >
                    <g
                            id="Arrow_-_Right"
                            data-name="Arrow - Right"
                            transform="translate(0 -9)"
                    >
                        <path
                                id="Arrow_-_Right-2"
                                data-name="Arrow - Right"
                                d="M13.411,15h0a2.824,2.824,0,0,1-1.213-.275A2.41,2.41,0,0,1,11.12,13.6a11.846,11.846,0,0,1-.348-1.331,25.874,25.874,0,0,1-.323-4.314l0-.442a32.417,32.417,0,0,1,.288-4.61l.151-.68a4.454,4.454,0,0,1,.311-.98A2.434,2.434,0,0,1,13.334,0h.077A6.865,6.865,0,0,1,15.2.509,30.162,30.162,0,0,1,22.66,5.246l.505.5a4.551,4.551,0,0,1,.373.417A2.128,2.128,0,0,1,24,7.49a2.223,2.223,0,0,1-.5,1.422l-.518.525-.116.113a29.575,29.575,0,0,1-7.855,5.03l-.325.117A6.012,6.012,0,0,1,13.411,15ZM2.005,9.4h0A1.956,1.956,0,0,1,0,7.5,1.956,1.956,0,0,1,2.005,5.6l4.932.41A1.534,1.534,0,0,1,8.509,7.5,1.534,1.534,0,0,1,6.937,8.988Z"
                                transform="translate(0 9)"
                                fill="currentColor"
                        />
                    </g>
                </svg>
                <li v-for="error in errors" class="text-danger fs-6">{{ error[0] }}</li>
                <b class="align-middle">إضافة وحدة عقارية جديدة</b>
            </h3>
            <form @submit.prevent="addRealestate" novalidate>
                <div class="row">
                    <div class="col-lg-6 mb-4">
                        <label class="mb-1 text-muted" for="name">اسم العقار</label>
                        <input
                                v-model="realestate.name"
                                type="text"
                                id="name"
                                placeholder=""
                                class="form-control py-2"
                        />
                    </div>
                    <div class="col-lg-6 mb-4">
                        <label class="mb-1 text-muted" for="type">نوع العقار</label>
                        <select v-model="realestate.type" type="text" id="type" class="form-select py-2">
                            <option>اختر</option>
                            <option value="apartment">شقة</option>
                            <option value="building">عمارة</option>
                            <option value="villa">فيلا</option>
                            <option value="shop">محل</option>
                        </select>
                    </div>
                    <div class="col-lg-6 mb-4">
                        <label class="mb-1 text-muted" for="city">المدينة</label>
                        <select v-model="realestate.city_id" @Change="onChangeCity" type="text" id="city" class="form-select py-2">
                            <option value="">اختر</option>
                            <option v-for="(city,index) in cities" :value="city.id" :key="index">{{city.name}}</option>
                        </select>
                    </div>
                    <div class="col-lg-6 mb-4">
                        <label class="mb-1 text-muted" for="city">الحي</label>
                        <select v-model="realestate.neighborhood_id" type="text" id="city" class="form-select py-2">
                            <option value="">اختر</option>
                            <option v-for="(neighborhood, index) in neighborhoods" :key="index" :value="neighborhood.id">{{ neighborhood.name }}</option>                                
                        </select>
                    </div>
                    <div class="col-lg-6 mb-4">
                        <label class="mb-1 text-muted" for="street">الشارع</label>
                        <input
                                v-model="realestate.street"
                                type="text"
                                id="street"
                                placeholder=""
                                class="form-control py-2"
                        />
                    </div>
                    <div v-if="realestate.type == 'villa' || realestate.type == 'building'" class="col-lg-6 mb-4">
                        <label class="mb-1 text-muted" for="floors"
                        >عدد الطوابق</label
                        >
                        <select v-model="realestate.floors" type="text" id="floors" class="form-select py-2">
                            <option value="">اختر</option>
                            <option v-for="i in 10" :value="i">{{ i }}</option>
                        </select>
                    </div>
                    <div class="col-lg-6 mb-4">
                        <label class="mb-1 text-muted" for="unit-no"
                        >رقم العقار</label
                        >
                        <input
                                v-model="realestate.realestate_number"
                                id="unit-no"
                                placeholder=""
                                class="form-control py-2"
                        />
                    </div>
                    <div v-if="realestate.type == 'apartment' || realestate.type == 'shop'" class="col-lg-6 mb-4">
                        <label class="mb-1 text-muted" for="unit-no"
                        >رقم الوحدة</label
                        >
                        <input
                                v-model="realestate.unit_number"
                                id="unit-no"
                                placeholder=""
                                class="form-control py-2"
                        />
                    </div>
                    <div v-if="realestate.type == 'apartment' || realestate.type == 'shop'" class="col-lg-6 mb-4">
                        <label class="mb-1 text-muted" for="floor"
                        >الدور</label
                        >
                        <select v-model="realestate.floor" type="text" id="floor" class="form-select py-2">
                            <option value="">اختر</option>
                            <option v-for="i in 10" :value="i">{{ i }}</option>
                        </select>
                    </div>
                    <div class="col-lg-6 mb-4">
                        <label class="mb-1 text-muted" for="owner">مالك العقار</label>
                        <span @click.prevent="addOwner" class="text-primary float-end">إضافة مالك</span>
                        <select v-model="realestate.owner_id" type="text" id="owner" class="form-select py-2">
                            <option value="">اختر</option>
                            <option v-for="item in owners" :key="item.id" :value="item.id" v-text="item.name"></option>
                        </select>
                    </div>
                    <div class="col-lg-6 mb-4">
                        <label class="mb-1 text-muted" for="area">المساحة</label>
                        <input
                                v-model="realestate.area"
                                id="area"
                                placeholder=""
                                class="form-control py-2"
                        />
                    </div>
                    <div class="col-lg-6 mb-4">
                        <label class="mb-1 text-muted" for="no">رقم العداد</label>
                        <input
                                v-model="realestate.electric_meter"
                                id="no"
                                placeholder=""
                                class="form-control py-2"
                        />
                    </div>
                    <div class="col-lg-6 mb-4">
                        <label class="mb-1 text-muted" for="price">السعر</label>
                        <input
                                v-model="realestate.price"
                                id="price"
                                placeholder=""
                                class="form-control py-2"
                        />
                    </div>
                    <div class="mb-4">
                        <label class="mb-1 text-muted" for="notes">ملاحظات</label>
                        <textarea
                                v-model="realestate.note"
                                id="notes"
                                placeholder=""
                                class="form-control py-2"
                        ></textarea>
                    </div>
                </div>
                <!-- <file-pond
                    name="image" 
                    ref="pond"
                    label-idle="Click to chose image or drag"
                    :files="image"
                    allow-multiple="true"
                    @init="filepondInitialized"
                    accepted-file-types="image/*"
                />  -->
                <div class="d-flex gap-2 pt-2" style="max-width: 50%">
                    <button
                            type="submit"
                            class="btn btn-sm btn-arrow btn-primary w-100"
                    >
                        <span>حفظ</span>
                        <i class="bi bi-arrow-left"></i>
                    </button>
                    <button
                            @click.prevent="cancel"
                            type="button"
                            class="btn btn-sm btn-outline-primary w-100"
                    >
                        رجوع
                    </button>
                </div>
            </form>
        </div>
    </template>
    
    <script>
    // import vueFilePond, { setOptions } from 'vue-filepond'
    // import "filepond/dist/filepond.min.css"
    // import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css"
    
    // import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
    // import FilePondPluginImagePreview from "filepond-plugin-image-preview"
    
    import axios from 'axios'
    
    
    // setOptions({
    //     server:{
    //         process:{
    //             url: 'http://127.0.0.1:8000/api/v1/image')
    
    // const FilePond = vueFilePond(
    //     FilePondPluginFileValidateType,
    //     FilePondPluginImagePreview
    // )
    export default{
        name: 'AddRealestate',
        components:{
            // FilePond
        },
        data(){
            return {
                owners: {},
                owner: {},
                realestate: {},
                fullPage: false,
                neighborhoods: '',
                cities: {},
                errors: [],
                // image: []
            }
        },
        created(){
            this.getOwners();
            this.getCities();
        },
        methods: {
             async getOwners(){
                let url = `${localStorage.getItem('default_office')}/owner?noPaginate=none`; 
                await axios.get(url).then(response => {
                    this.owners = response.data.data;
                });
            },
            async addOwner(){
                const { value: formValues } = await this.$swal.fire({
                // toast: true,
                title: '<p style="font-size:20px">تسجيل مالك عقار</p>',
                html:
                    '<label class="text-sm">رقم الهوية</label><strong class="text-red-500">*</strong><input type="input" id="swal-input1"  class="swal2-input text-blue-600" required><br>' +
                    '<label class="text-sm">اسم المالك</label><strong class="text-red-500">*</strong><input  type="input" id="swal-input2"  class="swal2-input"><br>' +
                    '<label class="text-sm">رقم الجوال</label><strong class="text-red-500">*</strong><input  type="number" id="swal-input3"  class="swal2-input"><br>' +
                    '<label class="text-sm">العنــــــوان</label><strong class="text-red-500">*</strong><input type="input" id="swal-input4"  class="swal2-input"><br>' +
                    '<input type="hidden" value="1" id="swal-input5"  class="swal2-input"><br>' ,
                focusConfirm: false,
                preConfirm: () => {
                    return [
                    document.getElementById('swal-input1').value,
                    document.getElementById('swal-input2').value,
                    document.getElementById('swal-input3').value,
                    document.getElementById('swal-input4').value,
                    document.getElementById('swal-input5').value,
                    ]
                }
                })
                if (formValues) {
                    this.owner.id_card = document.getElementById('swal-input1').value;
                    this.owner.name = document.getElementById('swal-input2').value;
                    this.owner.mobile = document.getElementById('swal-input3').value;
                    this.owner.address = document.getElementById('swal-input4').value;
                    this.owner.status = document.getElementById('swal-input5').value;
    
                    if(this.owner.id_card == '' || this.owner.name == '' || this.owner.mobile == '' || this.owner.address == ''){
                        this.$swal.fire({
                            toast: true,
                            position: 'top',
                            icon: 'error',
                            title: 'يرجى إكمال البيانات',
                            showConfirmButton: false,
                            timer: 2500,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', Swal.stopTimer)
                                toast.addEventListener('mouseleave', Swal.resumeTimer)
                            }
                        })
                    }else{
                        try{
                            const url = `${localStorage.getItem('default_office')}/owner`;
                            await axios.post(url, this.owner).then(response => {
                            this.getOwners();
                            this.$swal.fire({
                                    toast: true,
                                    position: 'top',
                                    icon: 'success',
                                    title: 'تم حفظ البيانات بنجاح',
                                    showConfirmButton: false,
                                    timer: 2500,
                                    timerProgressBar: true,
                                    didOpen: (toast) => {
                                        toast.addEventListener('mouseenter', Swal.stopTimer)
                                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                                    }
                                })
                            });
                        }catch(error){
                            this.errors = error.response.data.errors
                            // console.log(error.response.data.errors)
                            
                        }
    
                    
                    }
                }
            },
            
            async addRealestate(){
                try {
                    this.errors = []
                    let formData = new FormData();
                    formData.append('type', this.realestate.type);
                    formData.append('name', this.realestate.name);
                    formData.append('neighborhood_id', this.realestate.neighborhood_id);
                    formData.append('street', this.realestate.street ? this.realestate.street : '');
                    formData.append('floor', this.realestate.floor ? this.realestate.floor : '');
                    formData.append('floors', this.realestate.floors ? this.realestate.floors : '');
                    formData.append('realestate_number', this.realestate.realestate_number ? this.realestate.realestate_number : '');
                    formData.append('unit_number', this.realestate.unit_number ? this.realestate.unit_number : '');
                    formData.append('electric_meter', this.realestate.electric_meter ? this.realestate.electric_meter : '');
                    formData.append('rooms', this.realestate.rooms ? this.realestate.rooms : '');
                    formData.append('area', this.realestate.area ? this.realestate.area : '');
                    formData.append('electric_meter', this.realestate.electric_meter ? this.realestate.electric_meter : '');
                    formData.append('status', 1);
                    formData.append('price', this.realestate.price);
                    formData.append('note', this.realestate.note ? this.realestate.note : '');
                    formData.append('owner_id', this.realestate.owner_id);
                    let url = `${localStorage.getItem('default_office')}/realestate`;
                    await axios.post(url, formData).then(response => {
                        this.$swal.fire({
                        toast: true,
                                position: 'top',
                                icon: 'success',
                                title: 'تم حفظ البيانات بنجاح',
                                showConfirmButton: false,
                                timer: 2500,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', Swal.stopTimer)
                                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                                }
                            })
                            this.reset()
                            this.$router.push('/realestate')
                    });
                } catch (error) {
                    this.errors = error.response.data.errors
                }

            },
            cancel(){
                history.back();
            },
            reset(){
                this.realestate.type = '';
                this.realestate.name = '';
                this.realestate.city_id = '';
                this.realestate.neighborhood_id = '';
                this.realestate.rent_price = '';
                this.realestate.street = '';
                this.realestate.floors = '';
                this.realestate.realestate_number = '';
                this.realestate.electric_meter = '';
                this.realestate.area = '';
                this.realestate.price = '';
                this.realestate.note = '';
                this.realestate.owner_id = ''; 
                this.realestate.unit_number = ''; 
            },
            async getCities(){
                const response = await axios.get(`${localStorage.getItem('default_office')}/city`) 
                // console.log(response)
                this.cities = response.data.data
            },

            async onChangeCity(){
                const response = await axios.get(`${localStorage.getItem('default_office')}/city/${this.realestate.city_id}`)
                this.neighborhoods = response.data.data.neighborhoods
            }

            // filepondInitialized(){
            //     console.log('filepond is ready')
            //     console.log(  this.$refs.pond.image)
            // },
            
        },
        mounted(){
            
        }
    }
    </script>
    
    <style scoped>
        .title{
            font-size: small;
            color: blue;
        }
    </style>