<template>
  <div class="login-page">
    <div class="container">
      <div class="row">
          <div class="col-lg-6 position-relative pb-5 pt-4 ">
              <div class="h-100 d-lg-flex align-items-center rounded-4" style="background-color: rgba(255, 255, 255, 0.5); box-shadow: 2px 0 10px rgb(0 0 0 / 10%)">
                  <div class="login-form-container w-100 p-4" >
                    <div class="text-center mb-5">
                          <img src="/assets/images/logo-base.png" width="171" height="54" alt="Bill"/>
                      </div>
                      
                      <li v-if="errors.length" class="text-danger fs-6">{{ errors }}</li>
                      <p>تغيير كلمة المرور</p>
                      <form @submit.prevent="handelSubmit" id="phone-based-login">
                          <input
                                  v-model="password"
                                  type="password"
                                  placeholder="كلمة المرور"
                                  aria-label="كلمة المرور"
                                  class="form-control text-start mb-4 bg-light"
                          />
                          <br/>
                          <input
                                  v-model="password_confirm"
                                  type="password"
                                  placeholder="تأكيد كلمة المرور"
                                  aria-label="تأكيد كلمة المرور"
                                  class="form-control text-start mb-4 bg-light"
                          />
                          <br/>
                          <button type="submit" class="btn btn-arrow btn-primary w-75">
                              <span>حفظ</span>
                              <i class="bi bi-arrow-left"></i>
                          </button>
                      </form>

                      <p class="text-muted mt-3">
                          <small>
                              ليس لديك حساب؟ <router-link :to="{ name: 'Register' }">إنشاء حساب</router-link>
                          </small>
                          <small>
                            <router-link :to="{ name: 'Login' }"> | تسجيل دخول </router-link>
                          </small>
                      </p>
                  </div>
              </div>
              <footer class="text-muted">جميع الحقوق محفوظة © 2023</footer>
          </div>
          <div class="col-lg-6 d-lg-block d-none">
              <img src="/assets/images/login-graphics.png" alt="Login Image" class="img-fluid" width="1622"
                  height="2020" style="width: 540px;"/>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import {mapActions} from 'vuex'

  export default{
    name: 'ResetPassword',
    data(){
      return {
        password: '',
        password_confirm: '',
        errors: ''
      }
    },
    methods: {
      async handelSubmit(){
        await axios.post(`/auth/reset-password/${this.$route.params.token}?newPassword=` + this.password + `&confirm_newPassword=` + this.password_confirm, {
          password: this.password
        }).then(response => {
          if(response.data.error){
            this.errors = response.data.error
          }else{
            this.$swal.fire({
              toast: true,
              position: 'top',
              icon: 'success',
              title: 'تم تغير كلمة المرور بنجاح',
              showConfirmButton: false,
              timer: 2500,
              timerProgressBar: true,
              didOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
              }
            })
            window.location.href= "/"
          }
        })
      }
    }
  }
</script>


