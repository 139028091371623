<template>
  <div
      class="d-flex flex-md-row flex-column gap-3 align-items-md-center justify-content-between"
      >
    <form @submit.prevent="filter" novalidate>
      <div
        class="d-flex align-items-sm-center flex-column flex-sm-row gap-2"
      >
        
        
          <select v-model="sortField"
                  class="form-select border-white w-auto"
                  style="min-width: 120px"
          >
              <option value="">الفرز بـ</option>
              <option value="name">اسم العقار</option>
              <option value="created_at">تاريخ الإنشاء</option>
              <option value="type">نوع العقار</option>
              <option value="status">حالة العقار</option>
              <option value="realestate_number">رقم العقار</option>
              <option value="city">المدينة</option>
              <option value="neighborhood">الحي</option>
              <option value="electric_meter">رقم العداد</option>
              <option value="price">السعر</option>
              <option value="floors">عدد الطوابق</option>
              <option value="floor">الطابق</option>
          </select>
          <select v-model="sort"
                  class="form-select border-white w-auto"
                  style="min-width: 120px"
          >
              <option value="">الترتيب حسب الإضافة</option>
              <option value="DESC">الأحدث</option>
              <option value="ASC">الأقدم</option>
          </select>
        <select
          v-model="status"
          class="form-select border-white w-auto"
          style="min-width: 120px"
        >
          <option value="">حالة العقار</option>
            <option value="">الكل</option>
            <option value="1">غير مؤجر</option>
            <option value="2">مؤجر</option>
        </select>
        <select v-model="paginateNumber"
                  class="form-select border-white w-auto"
                  style="min-width: 60px"
          >
              <option value="">عدد الصفوف</option>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
          </select>
        <input
          v-model="search"
          type="search"
          placeholder="اكتب كلمة للبحث"
          class="form-control border-white"
        />
        <button class="btn btn-primary col-lg-2">
          بحث <i class="bi bi-search"></i>
        </button>
      </div>
    </form>
      <button class="btn py-2 btn-success mb-2" @click.prevent="realestateExport">
        <i class="bi bi-file-earmark-spreadsheet-fill fs-5"></i>
      </button>
      <router-link :to="{ name: 'AddRealestate' }" class="btn py-2 ms-1 btn-primary mb-2">
        <i class="bi bi-plus-circle fs-5"></i>
      </router-link>

    </div>
    <div class="table-responsive mt-4 d-none d-sm-block">
      <table class="table table-borderless bg-white rounded-4">
        <thead class="border-bottom">
          <tr>
            <th scope="col">ID</th>
            <th scope="col">نوع العقار</th>
            <th scope="col">اسم العقار</th>
            <th scope="col">الحي</th>
            <th scope="col">المساحة</th>
            <th scope="col">رقم العداد</th>
            <th scope="col">السعر</th>
            <th scope="col">مالك العقار</th>
            <th scope="col">حالة العقار</th>
            <th scope="col">التعديل</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="realestate, index in realestates.data" :key="realestate.id">
            <th scope="row">{{ index + 1 }}</th>
            <td v-if="realestate.type == 'building'" >عمارة</td>
            <td v-if="realestate.type == 'villa'" >فيلا</td>
            <td v-if="realestate.type == 'apartment'" >شقة</td>
            <td v-if="realestate.type == 'shop'" >محل</td>
            <td>{{ realestate.name}}</td>
            <td>{{ realestate.neighborhood.name }}</td>
            <td>{{ realestate.area }}</td>
            <td>{{ realestate.electric_meter }}</td>
            <td>{{ realestate.price }}</td>
            <td>
              <a
                v-if="realestate.owner" 
                href="#"
                data-bs-toggle="modal"
                :data-bs-target= "'#userDataModal' + realestate.id"
              >
                {{ realestate.owner.name }}
              </a>
            </td>
            <td  v-if="realestate.status == 1">
              <small
                  class="px-2 py-1 bg-success rounded-3 border border-success text-success"
                  style="--bs-bg-opacity: 0.08"
                >
                  غير مؤجر
                </small>
            </td>
            <td  v-if="realestate.status == 2">
              <small
                class="px-3 py-1 bg-danger rounded-3 border border-danger text-danger"
                style="--bs-bg-opacity: 0.08"
              >
                مؤجر
              </small>
            </td>
            <td class="actions">
              <router-link
                :to="{name: 'EditRealestate', params: {id: realestate.id}}"
                class="btn btn-link bg-primary py-1 px-2 me-2"
                style="--bs-bg-opacity: 0.08"
              >
                <i class="bi bi-pencil-square"></i>
              </router-link>
              <button
                @click.prevent="deleteRealesate(realestate.id)"
                class="btn btn-link bg-danger link-danger py-1 px-2"
                style="--bs-bg-opacity: 0.08"
              >
                <i class="bi bi-trash"></i>
              </button>
            </td>
            <!-- User data modal -->
          <div
            class="modal modal-sm fade"
            :id="'userDataModal' + realestate.id"
            tabindex="-1"
            role="dialog"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>

                <div class="modal-body d-flex align-items-center gap-4">
                  <img src="assets/images/profile.png" alt="" />
                  <div>
                    <h5 v-if="realestate.owner" class="fs-6 fw-bold mt-2 mb-3">{{ realestate.owner.name }}</h5>
                    <dl class="mb-0">
                      <dt>العنوان:</dt>
                      <dd v-if="realestate.owner" >{{ realestate.owner.address }}</dd>
                      <br />
                      <dt>البطاقة:</dt>
                      <dd v-if="realestate.owner" >{{ realestate.owner.id_card }}</dd>
                      <br />
                      <dt>الجوال:</dt>
                      <dd class="text-starrt" dir="ltr" v-if="realestate.owner" >{{ realestate.owner.mobile }}</dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="card bg-white rounded-2 mb-2 border border-0 d-lg-none .d-xl-block" v-for="realestate, index in realestates.data" :key="realestate.id">
          <div class="card-header bg-white">
              <div class="row">
                <div class="col-6">
                      ( {{ index + 1 }} )
                </div>
                <div>
                  <button @click.prevent="deleteRealesate(realestate.id)"
                            class="btn btn-link bg-danger link-danger py-1 px-2"
                            style="--bs-bg-opacity: 0.08"
                    >
                        <i class="bi bi-trash"></i>
                    </button>
                </div>
                <div class="col-2">
                  <router-link :to="{ name: 'EditRealestate', params: {id: realestate.id} }"
                            class="btn btn-link bg-primary py-1 px-2 me-2"
                            style="--bs-bg-opacity: 0.08"
                    >
                        <i class="bi bi-pencil-square"></i>
                    </router-link>
                </div>
                <div class="col-4">
                      <small v-if="realestate.status == 2"
                      class="px-3 py-1 bg-danger rounded-3 border border-danger text-danger"
                      style="--bs-bg-opacity: 0.08"
                    >
                    مؤجر
                    </small>
                    <small v-if="realestate.status == 1"
                        class="px-2 py-1 bg-success rounded-3 border border-success text-success"
                        style="--bs-bg-opacity: 0.08"
                      >
                        غير مؤجر
                    </small>
                </div>
              </div>
          </div>
          <div class="card-body">
            <blockquote class="blockquote mb-0">
                  <div class="row">
                    <div class="col-6">
                      <div class="text-primary" v-if="realestate.type == 'building'">نوع العقار <span class="text-dark" >عمارة</span></div>      
                      <div class="text-primary" v-if="realestate.type == 'villa'">نوع العقار <span class="text-dark" >فيلا</span></div>      
                      <div class="text-primary" v-if="realestate.type == 'apartment'">نوع العقار <span class="text-dark" v-if="realestate.type == 'apartment'">شقة</span></div>      
                      <div class="text-primary" v-if="realestate.type == 'shop'">نوع العقار <span class="text-dark" >محل</span></div>      
                    </div>
                    <div class="col-6">
                      <div class="text-primary">الحي <span class="text-dark">{{ realestate.neighborhood.name }}</span></div>    
                    </div>

                  </div>
                 <div class="text-primary">اسم العقار <span class="text-dark">{{ realestate.name }}</span></div>  
                 <div class="text-primary">المساحة <span class="text-dark">{{ realestate.area }}</span></div>    
                 <div class="text-primary">رقم العداد <span class="text-dark">{{ realestate.electric_meter }}</span></div>    
                 <div class="text-primary">السعر <span class="text-dark" v-if="realestate.owner" >{{ realestate.price }}</span></div>    
                 <div class="text-primary">مالك العقار <span class="text-dark" v-if="realestate.owner" >{{ realestate.owner.name }}</span></div>    
            </blockquote>
          </div>
        </div>

        <div>
          <Pagination :data="realestates" align="right" @pagination-change-page="getRealestates" />
        </div>

</template>

<script>
import LaravelVuePagination from 'laravel-vue-pagination'
import axios from 'axios';

export default{
    name: 'IndexRealestate',
    components: {
      'Pagination': LaravelVuePagination
    },
    data:function(){
      return {
        realestates: {},
        fullPage: false,
        status: '',
        search: '',
        sortField: 'created_at',
        sort: 'DESC',
        paginateNumber: 20,
      }
    },
    created(){
      this.getRealestates();
    },
    methods:{
      async getRealestates(page = 1){
        let loader = this.$loading.show({
            color: '#0d6efd', 
            loader: 'dots',
            backgroundColor: '#e4e4e4',
        });
        let url = 'realestate';
        const response = await axios.get(`${localStorage.getItem('default_office')}/realestate?page=${page}`);
        this.realestates = response.data;
        loader.hide();
      },

      async showOwner(id){
          let loader = this.$loading.show({
              color: 'Maroon',
              loader: 'dots',
              backgroundColor: '#e4e4e4',
          });
          let url = `${localStorage.getItem('default_office')}/owner/${id}`;
          axios.get(url).then(response => {
              loader.hide();
              const { value: formValues } = this.$swal.fire({
              title: '',
              html:
                "<label id=\"swal-label1\">الإسم: "+response.data.data.name+"</label><br>" +
                "<label id=\"swal-label1\">البطاقة: "+response.data.data.id_card+"</label><br>" +
                "<label id=\"swal-label1\">الجوال: "+response.data.data.mobile+"</label><br>" +
                "<label id=\"swal-label1\">العنوان: "+response.data.data.address+"</label><br>" ,

                // '<input id="swal-input1" class="swal2-input">' +
                // '<input id="swal-input2" class="swal2-input">',
              focusConfirm: false,
              preConfirm: () => {
                return [
                  document.getElementById('swal-label1').value,
                  // document.getElementById('swal-label2').value,
                  // document.getElementById('swal-label3').value,

                ]
              }
            })

            if (formValues) {
              Swal.fire(JSON.stringify(formValues))
            }

          });
      },

      async deleteRealesate(id){
            let url = `${localStorage.getItem('default_office')}/realestate/${id}`;
            this.$swal.fire({
                toast: false,
                title: 'هل تريد الحذف',
                icon: 'question',
                iconHtml: '؟',
                confirmButtonText: 'نعم',
                cancelButtonText: 'لا',
                showCancelButton: true,
                showCloseButton: true
                }).then(result=>{
                if(result.isConfirmed){
                    axios.delete(url).then(response => {
                        this.getRealestates();
                    })
                }
                })
        },
        async filter(page = 1){
          const response = await axios.get(`${localStorage.getItem('default_office')}/realestateFilter?paginateNumber=` + this.paginateNumber + '&sortField=' + this.sortField + '&sort=' + this.sort + '&status=' + this.status + '&search=' + this.search + '&page=' + page)
          this.realestates = response.data
        },
        async realestateExport(){
          const response = await axios.get(`${localStorage.getItem('default_office')}/realestateExport`)
          window.location.href = response.config.baseURL + `${localStorage.getItem('default_office')}/realestateExport`
        }
    },
    computed: {
      
    }
}
</script>

<style>

</style>