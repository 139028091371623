<template>
    <div
    class="d-flex flex-md-row flex-column gap-3 align-items-md-center justify-content-between"
    >
        <form @submit.prevent="filter" novalidate>
            <div
                    class="d-flex align-items-sm-center flex-column flex-sm-row gap-2"
            >
                
                <select v-model="paginateNumber"
                        class="form-select border-white w-auto"
                        style="min-width: 120px"
                >
                    <option value="">عدد الصفوف المعروضة</option>
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                </select>
                <select v-model="sortField"
                        class="form-select border-white w-auto"
                        style="min-width: 120px"
                >
                    <option value="">الترتيب حسب</option>
                    <option value="name">الإسم</option>
                    <option value="mobile">الجوال</option>
                    <option value="id_card">الهوية</option>
                </select>
                <select v-model="sort"
                        class="form-select border-white w-auto"
                        style="min-width: 120px"
                >
                    <option value="">الترتيب حسب الإضافة</option>
                    <option value="DESC">الأحدث</option>
                    <option value="ASC">الأقدم</option>
                </select>
                <input
                        v-model="search"
                        type="search"
                        placeholder="اكتب كلمة للبحث"
                        class="form-control border-white"
                />
                <button class="btn btn-primary col-lg-2">
                بحث <i class="bi bi-search"></i>
                </button>
            </div>
        </form>
        <div>
            <button @click.prevent="ownerExport" class="btn py-2 btn-success mb-2">
                <i class="bi bi-file-earmark-spreadsheet-fill fs-5"></i>
            </button>
            <router-link :to="{name: 'AddOwner'}" class="btn py-2 ms-1 btn-primary mb-2">
                <i class="bi bi-plus-circle fs-5"></i>
            </router-link>
        </div>
    </div>
    <div class="table-responsive-lg mt-4 d-none d-sm-block">
        <table class="table table-borderless bg-white rounded-4">
            <thead class="border-bottom">
            <tr>
                <th scope="col">ID</th>
                <th scope="col">اسم المالك</th>
                <th scope="col">الهوية</th>
                <th scope="col">الجوال</th>
                <th scope="col">العنوان</th>
                <th scope="col">التعديل</th>
            </tr>
            </thead>
            <tbody>
              <tr v-for="(owner, index) in owners.data" :key="owner.id">
                <th scope="row">{{ index + 1 }}</th>
                <td>{{ owner.name }}</td>
                <td>{{ owner.id_card }}</td>
                <td dir="ltr" class="text-start">{{ owner.mobile }}</td>
                <td>{{ owner.address }}</td>
                <td class="actions">
                    <router-link
                            :to="{ name: 'EditOwner', params: {id: owner.id} }"
                            class="btn btn-link bg-primary py-1 px-2 me-2"
                            style="--bs-bg-opacity: 0.08"
                    >
                        <i class="bi bi-pencil-square"></i>
                    </router-link>
                    <button
                            @click.prevent="deleteOwner(owner.id)"
                            class="btn btn-link bg-danger link-danger py-1 px-2"
                            style="--bs-bg-opacity: 0.08"
                    >
                        <i class="bi bi-trash"></i>
                    </button>
                </td>
            </tr>
            </tbody>
        </table>
    </div>

    <div class="card bg-white rounded-2 border border-0 mb-2 d-lg-none .d-xl-block" v-for="(owner, index) in owners.data" :key="owner.id">
          <div class="card-header bg-white">
            <div class="row">
              <div class="col-10">
                ( {{ index + 1 }} )
              </div>
              <div class="col-2">
                <router-link :to="{ name: 'EditOwner', params: {id: owner.id} }"
                        class="btn btn-link bg-primary py-1 px-2 me-2"
                        style="--bs-bg-opacity: 0.08"
                        >
                    <i class="bi bi-pencil-square"></i>
                </router-link>
            </div>
            <div class="col-2">
                <button @click.prevent="deleteOwner(owner.id)"
                            class="btn btn-link bg-danger link-danger py-1 px-2"
                            style="--bs-bg-opacity: 0.08"
                    >
                        <i class="bi bi-trash"></i>
                    </button>
            </div>
          </div>

            
                    
          </div>
          <div class="card-body">
            <blockquote class="blockquote mb-0">
                 <div class="text-primary">المالك <span class="text-dark">{{ owner.name }}</span></div>      
                 <div class="text-primary">الهوية <span class="text-dark">{{ owner.id_card }}</span></div>  
                 <div class="text-primary">الجوال <span class="text-dark">{{ owner.mobile }}</span></div>    
                 <div class="text-primary">العنوان <span class="text-dark">{{ owner.address }}</span></div>    
            </blockquote>
          </div>
        </div>
        
        <div>
          <Pagination :data="owners" align="right" @pagination-change-page="getOwners" />
        </div>
</template>

<script>
    import LaravelVuePagination from 'laravel-vue-pagination'
    import axios from 'axios'

    export default {
        name: 'IndexOwner',
        components: {
          'Pagination': LaravelVuePagination
        },
        data(){
            return {
                owners: {},
                search: '',
                sortField: 'name',
                sort: 'DESC',
                paginateNumber: 20,
            }
        },
        created(){
            this.getOwners();
        },
        methods:{
            async getOwners(page = 1){
              let loader = this.$loading.show({
                    color: '#0d6efd',
                    loader: 'dots',
                    backgroundColor: '#e4e4e4',
                });
                const response = await axios.get(`${localStorage.getItem('default_office')}/owner?page= ${page}`)
                this.owners = response.data
                loader.hide()
            },
            async deleteOwner(id){
                this.$swal.fire({
                // toast: true,
                title: 'هل تريد الحذف',
                icon: 'question',
                iconHtml: '؟',
                confirmButtonText: 'نعم',
                cancelButtonText: 'لا',
                showCancelButton: true,
                showCloseButton: true
                }).then(result=>{
                if(result.isConfirmed){
                    const response = axios.delete(`${localStorage.getItem('default_office')}/owner/${id}`)
                    this.getOwners()
                    }
                })
            },
            async filter(page = 1){
              const response = await axios.get(`${localStorage.getItem('default_office')}/ownerFilter?paginateNumber=` + this.paginateNumber + '&sortField=' + this.sortField + '&sort=' + this.sort + '&search=' + this.search + '&page=' + page)
              this.owners = response.data
            },
            async ownerExport(){
              const response = await axios.get(`${localStorage.getItem('default_office')}/ownerExport`)
              window.location.href = response.config.baseURL + `${localStorage.getItem('default_office')}/ownerExport`
            }
        }
    }
</script>