<template>
    <div class="bg-white rounded-4 px-2">
        <ul class="nav nav-tabs pt-3 px-2 mb-4 d-xl-none">
            <div class="row">
                <div class="col-6">
                    <li class="nav-item">
                    <router-link class="nav-link active" :to="{ name: 'OfficeSetting' }"
                        >المكاتب المسجل بها</router-link
                    >
                    </li>
                </div>
                <div class="col-6">
                    <li class="nav-item">
                        <router-link class="nav-link active" :to="{ name: 'OfficeUpdate' }"
                            >تعديل بيانات المكتب</router-link
                        >
                    </li>
                </div>
            </div>
        </ul>
        
        <div class="row">
            <div
            class="col-auto border-end py-2 d-none d-xl-block"
            style="width: 320px"
            >
            <ul class="list-unstyled mb-0">
                <li class="acc-nav-item">
                <i class="bi bi-diagram-2-fill"></i>
                <div>
                    <router-link :to="{ name: 'OfficeSetting' }" class="link-overlay"
                    >المكاتب المسجل بها</router-link
                    >
                    <br />
                    <small>
                        يتم عرض المكاتب المسجل بها حسابك، ويمكن اختيار مكتب أخر وعند تسجيل الدخول سيتم الدخول لهذا المكتب
                    </small>
                </div>
                </li>
                <li class="acc-nav-item active">
                <i class="bi bi-app-indicator"></i>
                <div>
                    <router-link to="" class="link-overlay"
                    >تعديل بيانات المكتب</router-link
                    >
                    <br />
                    <small>
                        يمكن تعديل بيانات المكتب
                    </small>
                </div>
                </li>
            </ul>
            </div>
            <div class="col p-4">
            <li v-for="error in errors" class="text-danger fs-6">{{ error[0] }}</li>
            <h2 class="fs-4 fw-bold mb-4">تعديل بيانات المكتب</h2>
                <form style="max-width: 580px" @submit.prevent="officeUpdate" novalidate>
                    <label class="mb-1 text-muted" for="name">اسم المكتب*</label>
                    <input
                            v-model="office.name"
                            type="text"
                            id="name"
                            required
                            placeholder=""
                            class="form-control py-2 mb-4"
                    /> 
                    <label class="mb-1 text-muted" for="id">الجوال*</label>
                    <input
                            v-model="office.mobile"
                            type="text"
                            id="id"
                            required
                            class="form-control py-2 mb-4"
                    />
                    <div class="d-flex align-items-center justify-content-between gap-2">
                        <label class="text-muted" for="phone">العنوان*</label>
                    </div>
                    <input type="text" v-model="office.address" id="address" placeholder="" class="form-control text-start py-2 mb-4"/>
                    <div class="d-flex gap-2 pt-2">
                        <button
                                type="submit"
                                class="btn btn-sm btn-arrow btn-primary w-50"
                        >
                            <span>تعديل</span>
                            <i class="bi bi-arrow-left"></i>
                        </button>
                        <!-- <button
                                @click.prevent="cancel"
                                type="button"
                                class="btn btn-sm btn-outline-primary w-100"
                        >
                            رجوع
                        </button> -->
                    </div>
                </form>
            </div>
        </div>
    </div>    
</template>

<script>
import {mapGetters} from 'vuex';
import axios from 'axios'

export default{
    name: 'OfficeUpdate',
    data(){
        return {
            activeDefault: null,
            offices:null,
            user_id: null,
            office: {},
            errors: null
        }
    },
    computed:{
        ...mapGetters({
            authenticated: 'auth/authenticated',
            user: 'auth/user'
        }),
        
    },
    created(){
        this.getOffice()
    },
    methods: {
            async getOffice(){
                let loader = this.$loading.show({
                    color: '#0d6efd',
                    loader: 'dots',
                    backgroundColor: '#e4e4e4',
                });
        
            const office = await axios.get(`office/` + localStorage.getItem('default_office'))
            this.office = office.data.data
            loader.hide();
            // console.log(office)
        },
        async officeUpdate(){
            try{
                const officeUpdate = await axios.put(`office/` + localStorage.getItem('default_office'), {
                    'name': this.office.name,
                    'mobile': this.office.mobile,
                    'address': this.office.address,
                    'status': this.office.status,
                })
                this.$swal.fire({
                    toast: true,
                    position: 'top',
                    icon: 'success',
                    title: 'تم حفظ البيانات بنجاح',
                    showConfirmButton: false,
                    timer: 2500,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })
                this.errros = null
                this.$router.push('/office-update')
            }catch(error){
                this.errors = error.response.data.errors
                // console.log(error.response.data.errors)
            }
            
        }
    }
}

</script>