<template>
  <div
        class="d-flex flex-xxl-row flex-column gap-3 align-items-xxl-center justify-content-between"
        >
        <div class="d-flex align-self-start bg-white rounded-3">
            <router-link :to="{ name: 'IndexContract' }" class="btn btn-primary">عقود</router-link>
            <router-link :to="{ name: 'IndexService' }" class="btn btn-link link-dark text-decoration-none">خدمات</router-link>
        </div>
        <!-- <div
                class="d-flex align-items-sm-center flex-column flex-sm-row gap-2 ms-xxl-auto"
        >
            <input
                    type="search"
                    placeholder="بحث"
                    class="form-control border-white"
            />
            <select
                    class="form-select border-white w-auto"
                    style="min-width: 120px"
            >
                <option value="">الترتيب</option>
            </select>
            <select
                    class="form-select border-white w-auto"
                    style="min-width: 120px"
            >
                <option value="">نوع الخدمة</option>
            </select>
            <input
                    type="date"
                    placeholder="تاريخ البداية - تاريخ النهاية"
                    class="form-control border-white"
            />
        </div> -->
        <div>
            <!-- <button class="btn py-2 btn-success mx-1">
                <i class="bi bi-file-earmark-spreadsheet-fill fs-5"></i>
            </button> -->
            <router-link :to="{ name: 'AddContract' }" class="btn py-2 btn-primary mb-2">
                <i class="bi bi-plus-circle fs-5"></i>
            </router-link>
        </div>
    </div>
    <div class="table-responsive mt-4 d-none d-sm-block">
        <table class="table table-borderless bg-white rounded-4">
            <thead class="border-bottom">
            <tr>
                <th scope="col">الوحدة العقارية</th>
                <th scope="col">المالك</th>
                <th scope="col">نوع الخدمة</th>
                <th scope="col">الفترة من</th>
                <th scope="col">الفترة إلى</th>
                <th scope="col">قيمة الخدمة</th>
                <!-- <th scope="col">التعديل</th> -->
            </tr>
            </thead>
            <tbody>
            <template v-for="item in owners">
              <template v-for="service in item.services">
                <tr>
                  <th scope="row">{{ service.pivot.realestate }}</th>
                  <td>
                      <a
                              href="#"
                              data-bs-toggle="modal"
                              data-bs-target="#userDataModal"
                      >
                      {{item.name}}
                      </a>
                  </td>
                  <td>{{service.name}}</td>
                  <td>{{service.pivot.date_from}}</td>
                  <td>{{service.pivot.date_to}}</td>
                  <td>{{service.pivot.price}}</td>
                  <!-- <td class="actions">
                      <button
                              class="btn btn-link bg-primary py-1 px-2 me-2"
                              style="--bs-bg-opacity: 0.08"
                      >
                          <i class="bi bi-pencil-square"></i>
                      </button>
                      <button
                              class="btn btn-link bg-danger link-danger py-1 px-2"
                              style="--bs-bg-opacity: 0.08"
                      >
                          <i class="bi bi-trash"></i>
                      </button>
                  </td> -->
                </tr>
              </template>
            </template>
            </tbody>
        </table>
        <!-- Loading -->
        <loading v-model:active="isLoading"
              :is-full-page="fullPage"/>
        <!-- End Loading -->
    </div>

        <div class="card bg-white rounded-2 mb-0 d-lg-none border border-0" v-for="item, index in owners">
                <template v-for="service in item.services">
                    <div class="card-header bg-white">
                        ( {{ index + 1 }} )

                    </div>
                    <div class="card-body">
                        <blockquote class="blockquote mb-0">
                            <div class="text-primary">الوحدة العقارية <span class="text-dark">{{ service.pivot.realestate }}</span></div>      
                            <div class="text-primary">المالك <span class="text-dark">{{ item.name }}</span></div>      
                            <div class="text-primary">نوع الخدمة <span class="text-dark">{{ service.name }}</span></div>      
                            <div class="text-primary">الفترة من <span class="text-dark">{{ service.pivot.date_from }}</span></div>      
                            <div class="text-primary">الفترة إلى <span class="text-dark">{{ service.pivot.date_to }}</span></div>      
                            <div class="text-primary">قيمة الخدمة <span class="text-dark">{{ service.pivot.price }}</span></div>      
                        </blockquote>
                    </div>
                </template>
        </div>
</template>

<script>
import axios from 'axios';

export default{
    name: 'IndexRentOrder',
    components:{
      
    },
    data: function(){
        return {
            fullPage: false,
            owners: {},
            realestate_type: 'building',
        }
    },
    created(){
      
      this.getContracts();
      
    },
    methods: {
        async getContracts(){
          let loader = this.$loading.show({
              color: '#0d6efd',
              loader: 'dots',
              backgroundColor: '#e4e4e4',
          });
            const response = await axios.get(`${localStorage.getItem('default_office')}/contract`);
            this.owners = response.data.data;
            // console.log(response.data.data);
            loader.hide();
        },

        async onChange(){
          this.getRentOrders();
        }
    }
}
</script>

<style>

</style>