import store from '@/store'
import DashBoard from '@/components/office/layout/DashBoard.vue'
import IndexCustomer from '@/components/office/customer/IndexCustomer.vue'
import AddCustomer from '@/components/office/customer/AddCustomer.vue'
import EditCustomer from '@/components/office/customer/EditCustomer.vue'
import IndexRealestate from '@/components/office/realestate/IndexRealestate.vue'
import AddRealestate from '@/components/office/realestate/AddRealestate.vue'
import EditRealestate from '@/components/office/realestate/EditRealestate.vue'
import IndexReminderOrder from '@/components/office/ReminderOrder/IndexReminderOrder.vue'
import AddReminderOrder from '@/components/office/ReminderOrder/AddReminderOrder.vue'
import EditReminderOrder from '@/components/office/ReminderOrder/EditReminderOrder.vue'
import IndexRentOrder from '@/components/office/RenteOrder/IndexRentOrder.vue'
import AddRentOrder from '@/components/office/RenteOrder/AddRentOrder.vue'
import EditRentOrder from '@/components/office/RenteOrder/EditRentOrder.vue'
import SetReminder from '@/components/office/RenteOrder/SetReminder.vue'
import SetInstallment from '@/components/office/RenteOrder/SetInstallment.vue'
import RentDetails from '@/components/office/RenteOrder/RentDetails.vue'
import IndexQReminder from '@/components/office/QuickReminder/IndexQReminder.vue'
import AddQReminder from '@/components/office/QuickReminder/AddQReminder.vue'
import EditQReminder from '@/components/office/QuickReminder/EditQReminder.vue'
import IndexContact from '@/components/office/contact/IndexContact.vue'
import AddContact from '@/components/office/contact/AddContact.vue'
import EditContact from '@/components/office/contact/EditContact.vue'
import Login from '@/components/office/auth/Login.vue'
import ForgotPassword from '@/components/office/auth/ForgotPassword.vue'
import ResetPassword from '@/components/office/auth/ResetPassword.vue'
import Register from '@/components/office/auth/Register.vue'
import IndexUser from '@/components/office/user/IndexUser.vue'
import AddUser from '@/components/office/user/AddUser.vue'
import EditUser from '@/components/office/user/EditUser.vue'
import PageNotFound from '@/components/office/NotFound/PageNotFound.vue'
import IndexOwner from '@/components/office/owner/IndexOwner.vue'
import AddOwner from '@/components/office/owner/AddOwner.vue'
import EditOwner from '@/components/office/owner/EditOwner.vue'

import IndexSetting from '@/components/office/myAccount/IndexSetting.vue'
import OfficeSetting from '@/components/office/myAccount/OfficeSetting.vue'
import OfficeUpdate from '@/components/office/myAccount/OfficeUpdate'

import Dashboard from '@/components/admin/layout/Dashboard.vue'
import MyOffices from '@/components/office/myOffices/MyOffices.vue'

import MyServices from '@/components/office/myServices/MyServices.vue'

import IndexService from '@/components/office/myServices/service/IndexService.vue'
import AddService from '@/components/office/myServices/service/AddService.vue'
import EditService from '@/components/office/myServices/service/EditService.vue'

import IndexContract from '@/components/office/myServices/contract/IndexContract.vue'
import AddContract from '@/components/office/myServices/contract/AddContract.vue'

import IndexCity from '@/components/office/city/IndexCity.vue'
import AddCity from '@/components/office/city/AddCity.vue'
import EditCity from '@/components/office/city/EditCity.vue'

import IndexNeighborhood from '@/components/office/neighborhood/IndexNeighborhood.vue'
import AddNeighborhood from '@/components/office/neighborhood/AddNeighborhood.vue'
import EditNeighborhood from '@/components/office/neighborhood/EditNeighborhood.vue'

// Admin Import
import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    {name: 'DashBoard', path: '/dashboard', component: DashBoard, meta: {needsAuth: true}},
    {
        name: 'IndexCustomer', 
        path: '/customer', 
        component: IndexCustomer,
        children: [
            {
                name: 'AddCustomer', path: 'add', component: AddCustomer, meta: {needsAuth: true}
            }
        ],
        meta: {needsAuth: true}
    },
    {name: 'AddCustomer', path: '/customer/add', component: AddCustomer, meta: {needsAuth: true}},
    {name: 'EditCustomer', path: '/customer/edit/:id?', component: EditCustomer, meta: {needsAuth: true}},
    {name: 'IndexOwner', path: '/owner', component: IndexOwner, meta: {needsAuth: true}},
    {name: 'AddOwner', path: '/owner/add', component: AddOwner, meta: {needsAuth: true}},
    {name: 'EditOwner', path: '/owner/edit/:id?', component: EditOwner, meta: {needsAuth: true}},
    {name: 'IndexRealestate', path: '/realestate', component: IndexRealestate, meta: {needsAuth: true}},
    {name: 'AddRealestate', path: '/realestate/add', component: AddRealestate, meta: {needsAuth: true}},
    {name: 'EditRealestate', path: '/realestate/edit/:id?', component: EditRealestate, meta: {needsAuth: true}},
    {name: 'IndexReminderOrder', path: '/reminderOrder', component: IndexReminderOrder, meta: {needsAuth: true}},
    {name: 'AddReminderOrder', path: '/reminderOrder/add', component: AddReminderOrder, meta: {needsAuth: true}},
    {name: 'EditReminderOrder', path: '/reminderOrder/edit/:id?', component: EditReminderOrder, meta: {needsAuth: true}},
    {name: 'IndexRentOrder', path: '/rentOrder', component: IndexRentOrder, meta: {needsAuth: true}},
    {name: 'AddRentOrder', path: '/rentOrder/add', component: AddRentOrder, meta: {needsAuth: true}},
    {name: 'EditRentOrder', path: '/rentOrder/edit/:id?', component: EditRentOrder, meta: {needsAuth: true}},
    {name: 'SetReminder', path: '/rentOrder/setReminder/:id?', component: SetReminder, meta: {needsAuth: true}},
    {name: 'SetInstallment', path: '/rentOrder/:id?/setInstallment', component: SetInstallment, meta: {needsAuth: true}},
    {name: 'RentDetails', path: '/rentOrder/details/:id?', component: RentDetails, meta: {needsAuth: true}},
    {name: 'IndexQReminder', path: '/quickReminder', component: IndexQReminder, meta: {needsAuth: true}},
    {name: 'AddQReminder', path: '/quickReminder/add', component: AddQReminder, meta: {needsAuth: true}},
    {name: 'EditQReminder', path: '/quickReminder/edit/:id?', component: EditQReminder, meta: {needsAuth: true}},
    {name: 'IndexContact', path: '/contact', component: IndexContact, meta: {needsAuth: true}},
    {name: 'AddContact', path: '/contact/add', component: AddContact, meta: {needsAuth: true}},
    {name: 'EditContact', path: '/contact/edit/:id?', component: EditContact, meta: {needsAuth: true}},
    {name: 'IndexUser', path: '/user', component: IndexUser, meta: {needsAuth: true}},
    {name: 'AddUser', path: '/user/add', component: AddUser, meta: {needsAuth: true}},
    {name: 'EditUser', path: '/user/edit/:id?', component: EditUser, meta: {needsAuth: true}},

    {name: 'MyServices', path: '/my-services', component: MyServices, meta: {needsAuth: true}},

    {name: 'IndexService', path: '/service', component: IndexService, meta: {needsAuth: true}},
    {name: 'AddService', path: '/service/add', component: AddService, meta: {needsAuth: true}},
    {name: 'EditService', path: '/service/edit/:id?', component: EditService, meta: {needsAuth: true}},

    {name: 'IndexContract', path: '/contract', component: IndexContract, meta: {needsAuth: true}},
    {name: 'AddContract', path: '/contract/add', component: AddContract, meta: {needsAuth: true}},
    {name: 'IndexCity', path: '/city', component: IndexCity, meta: {needsAuth: true}},
    {name: 'AddCity', path: '/city/add', component: AddCity, meta: {needsAuth: true}},
    {name: 'EditCity', path: '/city/edit/:id?', component: EditCity, meta: {needsAuth: true}},

    {name: 'IndexNeighborhood', path: '/neighborhood', component: IndexNeighborhood, meta: {needsAuth: true}},
    {name: 'AddNeighborhood', path: '/neighborhood/add', component: AddNeighborhood, meta: {needsAuth: true}},
    {name: 'EditNeighborhood', path: '/neighborhood/edit/:id?', component: EditNeighborhood, meta: {needsAuth: true}},

    {name: 'IndexSetting', path: '/my-account', component: IndexSetting, meta: {needsAuth: true}},
    {name: 'OfficeSetting', path: '/office-settings', component: OfficeSetting, meta: {needsAuth: true}},
    {name: 'OfficeUpdate', path: '/office-update', component: OfficeUpdate, meta: {needsAuth: true}},

    {name: 'MyOffices', path: '/myOffices', component: MyOffices, meta: {needsAuth: true}},
    
    {name: 'Login', path: '/', component: Login, meta: {isLogged: true}},
    {name: 'ForgotPassword', path: '/forgotPassword', component: ForgotPassword, meta: {isLogged: true}},
    {name: 'ResetPassword', path: '/reset/:token', component: ResetPassword, meta: {isLogged: true}},
    {name: 'Register', path: '/register', component: Register, meta: {isLogged: true}},
    
    // Admin Routes
    {name: 'Dashboard', path: '/admin', component: Dashboard},
];

const Router = createRouter({
    history: new createWebHistory(),
    routes,
    linkExactActiveClass: "active" 
});



Router.beforeEach((to, from, next) => {
    if(to.meta.isLogged){
        if(localStorage.getItem('token')){
            next('/dashboard') 
        }else{
            next()
        }
    }else{
        next()
    }
})

Router.beforeEach((to, from, next) => {
    if(to.meta.needsAuth){
        if(localStorage.getItem('token')){
            next() 
        }else{
            next('/')
        }
    }else{
        next()
    }
})
export default Router;