<template>
    <div class="bg-white px-4 pt-4 pb-5 rounded-4 mb-3">
            <h3 class="fs-5 mb-4">
                <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        width="1em"
                        viewBox="0 0 24 15"
                >
                    <g
                            id="Arrow_-_Right"
                            data-name="Arrow - Right"
                            transform="translate(0 -9)"
                    >
                        <path
                                id="Arrow_-_Right-2"
                                data-name="Arrow - Right"
                                d="M13.411,15h0a2.824,2.824,0,0,1-1.213-.275A2.41,2.41,0,0,1,11.12,13.6a11.846,11.846,0,0,1-.348-1.331,25.874,25.874,0,0,1-.323-4.314l0-.442a32.417,32.417,0,0,1,.288-4.61l.151-.68a4.454,4.454,0,0,1,.311-.98A2.434,2.434,0,0,1,13.334,0h.077A6.865,6.865,0,0,1,15.2.509,30.162,30.162,0,0,1,22.66,5.246l.505.5a4.551,4.551,0,0,1,.373.417A2.128,2.128,0,0,1,24,7.49a2.223,2.223,0,0,1-.5,1.422l-.518.525-.116.113a29.575,29.575,0,0,1-7.855,5.03l-.325.117A6.012,6.012,0,0,1,13.411,15ZM2.005,9.4h0A1.956,1.956,0,0,1,0,7.5,1.956,1.956,0,0,1,2.005,5.6l4.932.41A1.534,1.534,0,0,1,8.509,7.5,1.534,1.534,0,0,1,6.937,8.988Z"
                                transform="translate(0 9)"
                                fill="currentColor"
                        />
                    </g>
                </svg>
                <li v-for="error in errors" class="text-danger fs-6">{{ error[0] }}</li>
                <b class="align-middle">تعديل وحدة عقارية</b>
            </h3>
            <form @submit.prevent="updateRealestate" novalidate>
                <div class="row">
                    <div class="col-lg-6 mb-4">
                        <label class="mb-1 text-muted" for="name">اسم العقار</label>
                        <input
                                v-model="realestate.name"
                                type="text"
                                id="name"
                                placeholder=""
                                class="form-control py-2"
                        />
                    </div>
                    <div class="col-lg-6 mb-4">
                        <label class="mb-1 text-muted" for="type">نوع العقار</label>
                        <select v-model="realestate.type" type="text" id="type" class="form-select py-2">
                            <option selected value="">اختر</option>
                            <option value="apartment">شقة</option>
                            <option value="building">عمارة</option>
                            <option value="villa">فيلا</option>
                            <option value="shop">محل</option>
                        </select>
                    </div>
                    <div class="col-lg-6 mb-4">
                        <label class="mb-1 text-muted" for="city">المدينة</label>
                        <select v-model="realestate.neighborhood.city.id" @Change="onChangeCity" type="text" id="city" class="form-select py-2">
                            <option value="">اختر</option>
                            <template v-for="(city,index) in cities" :key="city.id">
                                <option :value="city.id">{{city.name}}</option>
                            </template>
                        </select>
                    </div>
                    <div class="col-lg-6 mb-4">
                        <label class="mb-1 text-muted" for="neighborhood">الحي</label>
                        <select v-model="realestate.neighborhood.id" type="text" id="neighborhood" class="form-select py-2">
                            <option value="">اختر</option>
                            <option v-for="(neighborhood, index) in neighborhoods" :key="index" :value="neighborhood.id">{{ neighborhood.name }}</option>                                
                        </select>
                    </div>
                    <div class="col-lg-6 mb-4">
                        <label class="mb-1 text-muted" for="street">الشارع</label>
                        <input
                                v-model="realestate.street"
                                type="text"
                                id="street"
                                placeholder=""
                                class="form-control py-2"
                        />
                    </div>
                    <div v-if="realestate.type == 'villa' || realestate.type == 'building'" class="col-lg-6 mb-4">
                        <label class="mb-1 text-muted" for="floors"
                        >عدد الطوابق</label
                        >
                        <select v-model="realestate.floors" type="text" id="floors" class="form-select py-2">
                            <option value="">اختر</option>
                            <option v-for="i in 10" :value="i">{{ i }}</option>
                        </select>
                    </div>
                    <div class="col-lg-6 mb-4">
                        <label class="mb-1 text-muted" for="unit-no"
                        >رقم العقار</label
                        >
                        <input
                                v-model="realestate.realestate_number"
                                id="unit-no"
                                placeholder=""
                                class="form-control py-2"
                        />
                    </div>
                    <div v-if="realestate.type == 'apartment' || realestate.type == 'shop'" class="col-lg-6 mb-4">
                        <label class="mb-1 text-muted" for="unit-no"
                        >رقم الوحدة</label
                        >
                        <input
                                v-model="realestate.unit_number"
                                id="unit-no"
                                placeholder=""
                                class="form-control py-2"
                        />
                    </div>
                    <div v-if="realestate.type == 'apartment' || realestate.type == 'shop'" class="col-lg-6 mb-4">
                        <label class="mb-1 text-muted" for="floor"
                        >الدور</label
                        >
                        <select v-model="realestate.floor" type="text" id="floor" class="form-select py-2">
                            <option value="">اختر</option>
                            <option v-for="i in 10" :value="i">{{ i }}</option>
                        </select>
                    </div>
                    <div class="col-lg-6 mb-4">
                        <label class="mb-1 text-muted" for="owner">مالك العقار</label>
                        <!-- <span @click.prevent="addOwner" class="text-primary float-end">إضافة مالك</span> -->
                        <select v-model="realestate.owner_id" type="text" id="owner" class="form-select py-2">
                            <option value="">اختر</option>
                            <option v-for="item in owners" :key="item.id" :value="item.id" v-text="item.name"></option>
                        </select>
                    </div>
                    <div class="col-lg-6 mb-4">
                        <label class="mb-1 text-muted" for="area">المساحة</label>
                        <input
                                v-model="realestate.area"
                                id="area"
                                placeholder=""
                                class="form-control py-2"
                        />
                    </div>
                    <div class="col-lg-6 mb-4">
                        <label class="mb-1 text-muted" for="no">رقم العداد</label>
                        <input
                                v-model="realestate.electric_meter"
                                id="no"
                                placeholder=""
                                class="form-control py-2"
                        />
                    </div>
                    <div class="col-lg-6 mb-4">
                        <label class="mb-1 text-muted" for="price">السعر</label>
                        <input
                                v-model="realestate.price"
                                id="price"
                                placeholder=""
                                class="form-control py-2"
                        />
                    </div>
                    <div class="mb-4">
                        <label class="mb-1 text-muted" for="notes">ملاحظات</label>
                        <textarea
                                v-model="realestate.note"
                                id="notes"
                                placeholder=""
                                class="form-control py-2"
                        ></textarea>
                    </div>
                </div>
                <!-- <file-pond
                    name="image" 
                    ref="pond"
                    label-idle="Click to chose image or drag"
                    :files="image"
                    allow-multiple="true"
                    @init="filepondInitialized"
                    accepted-file-types="image/*"
                />  -->
                <div class="d-flex gap-2 pt-2" style="max-width: 50%">
                    <button
                            type="submit"
                            class="btn btn-sm btn-arrow btn-primary w-100"
                    >
                        <span>تعديل</span>
                        <i class="bi bi-arrow-left"></i>
                    </button>
                    <button
                            @click.prevent="cancel"
                            type="button"
                            class="btn btn-sm btn-outline-primary w-100"
                    >
                        رجوع
                    </button>
                </div>
            </form>
        </div>
</template>

<script>
import axios from 'axios'

export default{
name: 'EditRealestate',
data(){
    return {
        realestate: {},
        owners: {},
        cities: {},
        neighborhoods: '',
        fullPage: false,
        errors: [],
    }
},
created(){
    this.getRealestate();
    this.getCities();
    this.getNeighborhoods();
    this.getOwners();
},
methods: {
        async getRealestate(){
            let loader = this.$loading.show({
                color: '#0d6efd',
                loader: 'dots',
                backgroundColor: '#e4e4e4',
            });
            let url = `${localStorage.getItem('default_office')}/realestate/${this.$route.params.id}`;
            await axios.get(url).then((response) => {
                this.realestate = response.data.data;
                // console.log(response)
                loader.hide();
                this.getNeighborhoods()
            });
        },

        async getOwners(){
            let url = `${localStorage.getItem('default_office')}/owner?noPaginate=none`;
            await axios.get(url).then(response => {
                this.owners = response.data.data;
            });
        },

        async updateRealestate(){
            try {
                this.errors = []
                let url = `${localStorage.getItem('default_office')}/realestate/${this.$route.params.id}`;
            await axios.put(url, {
                area: this.realestate.area,
                // city: this.realestate.city,
                electric_meter: this.realestate.electric_meter,
                floor: this.realestate.floor,
                floors: this.realestate.floors,
                name: this.realestate.name,
                neighborhood_id: this.realestate.neighborhood.id,
                note: this.realestate.note,
                owner_id: this.realestate.owner_id,
                price: this.realestate.price,
                realestate_number: this.realestate.realestate_number,
                status: this.realestate.status,
                street: this.realestate.street,
                type: this.realestate.type,
                unit_number: this.realestate.unit_number,
            }).then(response => {
                this.$swal.fire({
                    toast: true,
                    position: 'top',
                    icon: 'success',
                    title: 'تم تعديل البيانات بنجاح',
                    showConfirmButton: false,
                    timer: 2500,
                    timerProgressBar: true,
                        didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })
                this.$router.push('/realestate')
            });
            } catch (error) {
                this.errors = error.response.data.errors
            }
        },
        async getCities(){
            const response = await axios.get(`${localStorage.getItem('default_office')}/city`) 
            this.cities = response.data.data
        },
        async getNeighborhoods(){
            const city_id = this.realestate.neighborhood.city.id;
            const response = await axios.get(`${localStorage.getItem('default_office')}/city/${city_id}`)
            this.neighborhoods = response.data.data.neighborhoods

        },

        async onChangeCity(){
            const city_id = this.realestate.neighborhood.city.id;
            const response = await axios.get(`${localStorage.getItem('default_office')}/city/${city_id}`)
            this.neighborhoods = response.data.data.neighborhoods
        },
        cancel(){
            history.back();
        },
    }
}
</script>

<style>

</style>