<template>
    <div class="bg-white px-4 pt-4 pb-5 rounded-4 mb-3">
            <h3 class="fs-5 mb-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                width="1em"
                viewBox="0 0 24 15"
              >
                <g
                  id="Arrow_-_Right"
                  data-name="Arrow - Right"
                  transform="translate(0 -9)"
                >
                  <path
                    id="Arrow_-_Right-2"
                    data-name="Arrow - Right"
                    d="M13.411,15h0a2.824,2.824,0,0,1-1.213-.275A2.41,2.41,0,0,1,11.12,13.6a11.846,11.846,0,0,1-.348-1.331,25.874,25.874,0,0,1-.323-4.314l0-.442a32.417,32.417,0,0,1,.288-4.61l.151-.68a4.454,4.454,0,0,1,.311-.98A2.434,2.434,0,0,1,13.334,0h.077A6.865,6.865,0,0,1,15.2.509,30.162,30.162,0,0,1,22.66,5.246l.505.5a4.551,4.551,0,0,1,.373.417A2.128,2.128,0,0,1,24,7.49a2.223,2.223,0,0,1-.5,1.422l-.518.525-.116.113a29.575,29.575,0,0,1-7.855,5.03l-.325.117A6.012,6.012,0,0,1,13.411,15ZM2.005,9.4h0A1.956,1.956,0,0,1,0,7.5,1.956,1.956,0,0,1,2.005,5.6l4.932.41A1.534,1.534,0,0,1,8.509,7.5,1.534,1.534,0,0,1,6.937,8.988Z"
                    transform="translate(0 9)"
                    fill="currentColor"
                  />
                </g>
              </svg>
              <li v-for="error in errors" class="text-danger fs-6">{{ error[0] }}</li>
              <b class="align-middle">تعديل تأجير وحدة</b>
            </h3>
            <form @submit.prevent="editRentOrder()" novalidate style="max-width: 580px">             
              <template v-if="!rent.customer || !rent.realestate || !rent.realestate.owner">
                <h5>لا يمكن التعديل على هذا العقد </h5>
                <span style="color:red;"> المستأجر او العقار او المالك تم حذفه</span>
              </template>
              <template v-else>
                <label class="mb-1 text-muted" for="renter">المستأجر</label>
                <select v-model="rent.customer.id" type="text" id="renter" class="form-select py-2 mb-4">
                  <option value="">اختر</option>
                  <option v-for="customer in customers" :key="customer.id" :value="customer.id">{{ customer.name }} </option>
                </select>
                <label class="mb-1 text-muted" for="unit">الوحدة العقارية</label>
                <select v-model="rent.realestate.id" type="text" id="renter" class="form-select py-2 mb-4">
                  <option value="">اختر</option>
                  <template v-for="realestate in realestates" :key="realestate.id">
                      <option :value="realestate.id" v-if="realestate.status == 1 || realestate.id == rent.realestate.id">{{ realestate.name }} </option>
                  </template>
                </select>
                <label class="mb-1 text-muted" for="price">سعر التأجير</label>
                <input
                  v-model="rent.rent_price"
                  type="text"
                  id="price"
                  required
                  placeholder="سعر التأجير"
                  class="form-control py-2 mb-4"
                />
                <label class="mb-1 text-muted" for="from">الفترة من</label>
                <input
                  v-model="rent.date_from" 
                  type="date"
                  id="from"
                  required
                  class="form-control py-2 mb-4"
                />
                <label class="mb-1 text-muted" for="to">الفترة إلى</label>
                <input
                  v-model="rent.date_to"
                  type="date"
                  id="to"
                  required
                  class="form-control py-2 mb-4"
                />
                <!-- <label class="mb-1 text-muted" for="to">عدد التذكيرات</label>
                <input
                  v-model="rent.reminder_count"
                  type="text"
                  id="reminder_count"
                  required
                  class="form-control py-2 mb-4"
                /> -->

                <label class="mb-1 text-muted" for="to">ملاحظة</label>
                <textarea
                  v-model="rent.note" 
                  type="text"
                  id="note"
                  required
                  class="form-control py-2 mb-4"
                ></textarea>

                    
                  <label class="mb-1 text-muted" for="to">رفع ملف</label>
                  <file-pond 
                    name= 'file'
                    ref= 'pond'
                    label-idle= 'Click to choose file or drage here...'
                    accepted-file-types = "application/pdf"
                    :allow-multiple="false "
                    v-bind:files="myFiles"
                    @init="filepondInitialized"
                    :server= "{
                      url: '',
                      timeout: 7000,
                      process: {
                        url: api_url + `/api/v1/${default_office}/upload`,
                        method:'POST',
                        withCredentials: false,
                        onload: handelFilepondLoad,
                        onerror: () => {}
                      },
                      remove: handelFilepondRemove,
                      revert: handelFilepondRevert
                    }"
                    :file="myFiles"
                    :init="handelFilepondInit"
                  >
                  </file-pond>


                  <a v-if="rent.file" target="blank"
                      :href="rent.file"
                    >
                    <i class="bi bi-file-pdf" style="font-size:xx-large"> </i>
                    </a>



                <div class="d-flex gap-2 pt-2">
                  <button
                    type="submit"
                    class="btn btn-sm btn-arrow btn-primary w-100"
                  >
                    <span>تعديل</span>
                    <i class="bi bi-arrow-left"></i>
                  </button>
                  <button
                    @click.prevent="cancel"
                    type="button"
                    class="btn btn-sm btn-outline-primary w-100"
                  >
                    رجوع
                  </button>
                </div>
              </template>
            </form>
          </div>
</template>

<script>
// Import Vue FilePond
import vueFilePond, {setOptions} from "vue-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import FilePond plugins
// Please note that you need to install these plugins separately

// Import image preview plugin styles
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import axios from 'axios';
import { saveAs } from 'file-saver'

// Create component
const FilePond = vueFilePond(
FilePondPluginFileValidateType,
FilePondPluginImagePreview,
);


export default{
    name: 'EditRentOrder',
    components: { Datepicker, FilePond, },
    data(){
        return {
             dateFrom: {},
             dateTo: {},
             customers: {},
             realestates: {},
             realestate: {},
             realestate_old_obj: {},
             rentOrder: {},
             rent: {},
             fullPage: false,
             errors: [],
             old_realestate: 0,
             file: '',
             myFiles: [],
             api_url: this.API_URL,
             default_office: localStorage.getItem('default_office')

        }
    },
    created(){
        this.getRenters();
        this.getRealestates();
        this.getRent();
        
    },
   
    methods: {
  
        handelFilepondInit(){

        },
        handelFilepondLoad(response){
          this.file = response
          // console.log(response)
        },
        handelFilepondRemove(source, load, error){
          this.file = ''
          load()
        },
        handelFilepondRevert(uniqueId, load, error){
          axios.post(`${localStorage.getItem('default_office')}/upload-revert`, {
            file: this.file
          })
          load()
          
        },
        async getRenters(){
            let loader = this.$loading.show({
                color: '#0d6efd',
                loader: 'dots',
                backgroundColor: '#e4e4e4',
            });
            let url = `${localStorage.getItem('default_office')}/customer?noPaginate=true`;
            await axios.get(url)
            .then(response => {
                this.customers = response.data.data;
                loader.hide();
            });
        },
        async getRealestates(){
            let url = `${localStorage.getItem('default_office')}/realestate?noPaginate=true`;
            await axios.get(url)
            .then(response => {
                this.realestates = response.data.data;
                // console.log(response.data.data)
            })
        },

        async getRent(){
          let id = this.$route.params.id
          let response = await axios.get(`${localStorage.getItem('default_office')}/rentOrder/${this.$route.params.id}`);
          // console.log('rent item is a ' + response.data.data)
          this.rent = response.data.data
          this.old_realestate = this.rent.realestate.id
        },

        async editRentOrder(){
          try {
            this.errors = []
            if(this.rent.date_to < this.rent.date_from){
              this.$swal.fire({
                    toast: true,
                    position: 'top',
                    icon: 'error',
                    title: ' تاريخ الفترة إلى، يجب ان يكون اكبر من تاريخ الفترة من',
                    showConfirmButton: false,
                    // timer: 2500,
                    timerProgressBar: false,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
              });
            }else{

              const old_realestate = axios.get(`${localStorage.getItem('default_office')}/realestate/${this.old_realestate}`).then(response => {
                this.realestate_old_obj = response.data.data
                // console.log(this.realestate_old_obj)
                const realestateRes = axios.put(`${localStorage.getItem('default_office')}/realestate/` + this.realestate_old_obj.id, {
                name: this.realestate_old_obj.name,
                type: this.realestate_old_obj.type,
                neighborhood_id: this.realestate_old_obj.neighborhood.id,
                street: this.realestate_old_obj.street,
                realestate_number: this.realestate_old_obj.realestate_number,
                area: this.realestate_old_obj.area,
                floor: this.realestate_old_obj.floor,
                electric_meter: this.realestate_old_obj.electric_meter,
                status: 1,
                price: this.realestate_old_obj.price,
                owner_id: this.realestate_old_obj.owner.id,
                })
          })
          
            const response = await axios.put(`${localStorage.getItem('default_office')}/rentOrder/${this.$route.params.id}`, {
              customer_id: this.rent.customer.id,
              realestate_id: this.rent.realestate.id,
              rent_price: this.rent.rent_price,
              reminder_count: this.rent.reminder_count,
              date_from: this.rent.date_from,
              date_to: this.rent.date_to,
              note: this.rent.note,
              file: this.file,
          })
          
          


          const realestate = axios.get(`${localStorage.getItem('default_office')}/realestate/${this.rent.realestate.id}`).then(response => {
            this.realestate = response.data.data
                const realestateResponse = axios.put(`${localStorage.getItem('default_office')}/realestate/` + this.realestate.id, {
                  name: this.realestate.name,
                  type: this.realestate.type,
                  neighborhood_id: this.realestate.neighborhood.id,
                  street: this.realestate.street,
                  realestate_number: this.realestate.realestate_number,
                  area: this.realestate.area,
                  floor: this.realestate.floor,
                  electric_meter: this.realestate.electric_meter,
                  status: 2,
                  price: this.realestate.price,
                  owner_id: this.realestate.owner.id,
                })
          })

          
                this.$swal.fire({
                    toast: true,
                    position: 'top',
                    icon: 'success',
                    title: 'تم حفظ البيانات بنجاح',
                    showConfirmButton: false,
                    timer: 2500,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                });
              this.$router.push('/rentOrder')

            }
          
          } catch (error) {
            // this.errors = error.response.data.errors
          }
        },
        cancel(){
            history.back();
        },
        reset(){
            this.rentOrder.customer_id = '';
            this.rentOrder.realestate_id = '';
            this.rentOrder.rent_price = '';
            this.rentOrder.paid = '';
            this.dateFrom = '';
            this.dateTo = '';
        }
    }
}
</script>

<style>

</style>
