<template>
  <div class="login-page">
    <div class="container">
      <div class="row">
          <div class="col-lg-6 position-relative pb-5 pt-4 ">
              <div class="h-100 d-lg-flex align-items-center rounded-4" style="background-color: rgba(255, 255, 255, 0.5); box-shadow: 2px 0 10px rgb(0 0 0 / 10%)">
                  <div class="login-form-container w-100 p-4" >

                      <div class="text-center mb-5">
                          <img src="assets/images/logo-base.png" width="171" height="54" alt="Bill"/>
                      </div>
                      <nav class="d-flex gap-2 mb-4">
                        <button 
                                  @click.prevent="loginForm = 'mobile'; form.passwordEmail=''; form.email= ''"
                                  class="btn btn-sm btn-outline-primary w-100"
                                  id="show-phone-login"
                          >
                              الدخول عن طريق رقم الجوال
                          </button>
                          <button
                                  @click.prevent="loginForm = 'mail'; form.passwordMobile=''; form.mobile= ''"
                                  class="btn btn-sm btn-outline-secondary w-100"
                                  id="show-email-login"
                          >
                              الدخول عن طريق البريد الإلكتروني
                          </button>
                      </nav>
                      <li v-if="errors.length" class="text-danger fs-6">{{ errors }}</li>

                      <span v-if="loginForm == 'mobile'" class="text-primary">رقم الجوال</span>
                      <span v-if="loginForm == 'mail'">البريد الالكتروني</span>
                      <form @submit.prevent="submit" v-if="loginForm == 'mobile'" id="phone-based-login">
                          <input
                                  v-model="form.mobile"
                                  type="tel"
                                  placeholder="رقم الجوال"
                                  aria-label="رقم الجوال"
                                  required=""
                                  class="form-control text-start mb-4 bg-light"
                          />
                          <input
                                  v-model="form.passwordMobile"
                                  type="password"
                                  placeholder="كلمة المرور"
                                  aria-label="كلمة المرور"
                                  required=""
                                  class="form-control mb-4 bg-light"
                          />
                          <!-- <input type="checkbox" id="remember-me" class="form-check-input mb-4"/>
                          <label for="remember-me">تذكرني</label> -->
                          <br/>
                          <button type="submit" class="btn btn-arrow btn-primary w-75">
                              <span>تسجيل الدخول</span>
                              <i class="bi bi-arrow-left"></i>
                          </button>
                      </form>
                      
                      <form @submit.prevent="submit" v-if="loginForm == 'mail'" id="phone-based-login">
                          <input
                                  v-model="form.email"
                                  type="email"
                                  placeholder="البريد الالكتروني"
                                  aria-label="البريد الالكتروني"
                                  required=""
                                  class="form-control text-start mb-4 bg-light"
                          />
                          <input
                                  v-model="form.passwordEmail"
                                  type="password"
                                  placeholder="كلمة المرور"
                                  aria-label="كلمة المرور"
                                  class="form-control mb-4 bg-light"
                          />
                          <!-- <input type="checkbox" id="remember-me" class="form-check-input mb-4"/>
                          <label for="remember-me">تذكرني</label> -->
                          <br/>
                          <button type="submit" class="btn btn-arrow btn-primary w-75">
                              <span>تسجيل الدخول</span>
                              <i class="bi bi-arrow-left"></i>
                          </button>
                      </form>

                      <p class="text-muted mt-3">
                          <small>
                              ليس لديك حساب؟ <router-link :to="{ name: 'Register' }">إنشاء حساب</router-link>
                          </small>
                          <small>
                            <router-link :to="{ name: 'ForgotPassword' }"> | نسيت كلمة المرور؟ </router-link>
                          </small>
                      </p>
                  </div>
              </div>
              <footer class="text-muted">جميع الحقوق محفوظة © 2023</footer>
          </div>
          <div class="col-lg-6 d-lg-block d-none">
              <img src="assets/images/login-graphics.png" alt="Login Image" class="img-fluid" width="1622"
                  height="2020" style="width: 540px;"/>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'

  export default{
    name: 'Login',
    data(){
      return {
        form: {
          loginName: '',
          passwordMobile: null,
          passwordEmail: null,
        },
        loginType: 'mobile',
        errors: '',
        loginForm: 'mobile'
      }
    },
    created(){
      
    },
    methods: {
      ...mapActions({
        singIn: 'auth/signIn'
      }),
      async submit(){
      
        let signIn = await this.singIn(this.form).then( () => {
          this.$router.replace('/dashboard')
        })

        
        if(!signIn){
          this.errors = 'اسم المستخدم أو كلمة المرور خاطئة'
          // this.$swal.fire({
          //       toast: true,
          //       position: 'top',
          //       icon: 'error',
          //       title: this.errors,
          //       showConfirmButton: false,
          //       timer: 2500,
          //       timerProgressBar: true,
          //       didOpen: (toast) => {
          //           toast.addEventListener('mouseenter', Swal.stopTimer)
          //           toast.addEventListener('mouseleave', Swal.resumeTimer)
          //       }
          //   })
        }
      },
    }
  }
</script>