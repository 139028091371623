import { createApp } from 'vue'
import App from './App.vue'
import routes from '@/routes/routes'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import store from './store'
import axios from 'axios'
import Vue from 'vue'
import Select2 from 'vue3-select2-component';




require('@/store/subscriber')
// axios.defaults.baseURL= `http://127.0.0.1:8000/api/v1/`;
axios.defaults.baseURL= `https://reminder-backend.wide-techno.com/api/v1/`;

store.dispatch('auth/attempt', localStorage.getItem('token')).then( () => {
    const app = createApp(App)
    app.use(routes)
    app.use(VueSweetalert2)
    app.use(VueLoading)
    app.use(store)
    // app.config.globalProperties.API_URL = 'http://127.0.0.1:8000'
    app.config.globalProperties.API_URL = 'https://reminder-backend.wide-techno.com'
    app.use(('Select2', Select2))
    app.mount('#app')
})




