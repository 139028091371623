<template>
      <header
            class="d-flex justify-content-between align-items-center mb-3"
            >
                <div class="d-flex align-items-center gap-1">
                    <button
                            aria-label="Menu"
                            class="d-lg-none btn btn-link link-dark px-2 py-0"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#navCanvas"
                            aria-controls="navCanvas"
                    >
                        <i class="bi bi-list fs-3"></i>
                    </button>
                    <h1 class="fs-5 fw-bold">
                        مرحبا، <span class="fw-normal text-muted">{{ user.name }}</span>
                    </h1>
                </div>
                <div class="d-none d-lg-flex align-items-center gap-3">
                    <!-- <button
                            aria-label="Notifications"
                            class="btn btn-link link-dark px-1 py-0"
                    >
                        <i class="bi bi-bell fs-4"></i>
                    </button> -->
                    <router-link
                            to=""
                            class="d-inline-flex align-items-center link-dark gap-2"
                    >
                        <img src="assets/images/profile.png" alt="" width="120" height="120"
                             style="width: 48px; height: 48px;"/>
                        <div class="text-start">
                            <strong>{{ user.name }}</strong>
                            <br/>
                            <small class="text-muted">
                                <router-link :to="{ name: 'OfficeSetting' }">
                                    الملف الشخصي
                                </router-link>
                            </small>
                        </div>
                    </router-link>
                    <button
                            aria-label="Logout"
                            class="btn btn-link link-dark px-1 py-0"
                            data-bs-toggle="modal"
                            data-bs-target="#logoutConf"
                    >
                        <i class="bi bi-box-arrow-left fs-4"></i>
                    </button>
                </div>
            </header>
            <!-- Logout confirmation -->
            <div
                    class="modal fade"
                    id="logoutConf"
                    tabindex="-1"
                    role="dialog"
                    aria-hidden="true"
            >
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <button
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                        ></button>

                        <div class="modal-body">
                            <h5 class="text-center fw-bold mt-3 mb-5">
                                هل تريد الخروج من النظام؟
                            </h5>
                            <div class="d-flex gap-2">
                                <button class="btn btn-sm btn-arrow btn-primary w-100" @click.prevent="signOut">
                                    <span>نعم</span>
                                    <i class="bi bi-arrow-left"></i>
                                </button>
                                <button
                                        class="btn btn-sm btn-outline-primary w-100"
                                        data-bs-dismiss="modal"
                                >
                                    تراجع
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'

export default{
name:'NavBar',
computed: {
  ...mapGetters({
    authenticated: 'auth/authenticated',
    user: 'auth/user'
  }),
},

methods: {
  ...mapActions({
    signOutAction: 'auth/signOut'
  }),

signOut(){
        // this.$router.push('/dashboard').then( () => {
        //   this.signOutAction().then( () => {
        //     // this.$router.push('/')
        //     window.location.href = '/'
        //   })
        // }) 
                
        this.signOutAction().then( () => {
            // this.$router.push('/')
            window.location.href = '/'
        })
    }
}
}
</script>

