<template>
    <div class="bg-white px-4 pt-4 pb-5 rounded-4 mb-3">
                <h3 class="fs-5 mb-4">
                    <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            width="1em"
                            viewBox="0 0 24 15"
                    >
                        <g
                                id="Arrow_-_Right"
                                data-name="Arrow - Right"
                                transform="translate(0 -9)"
                        >
                            <path
                                    id="Arrow_-_Right-2"
                                    data-name="Arrow - Right"
                                    d="M13.411,15h0a2.824,2.824,0,0,1-1.213-.275A2.41,2.41,0,0,1,11.12,13.6a11.846,11.846,0,0,1-.348-1.331,25.874,25.874,0,0,1-.323-4.314l0-.442a32.417,32.417,0,0,1,.288-4.61l.151-.68a4.454,4.454,0,0,1,.311-.98A2.434,2.434,0,0,1,13.334,0h.077A6.865,6.865,0,0,1,15.2.509,30.162,30.162,0,0,1,22.66,5.246l.505.5a4.551,4.551,0,0,1,.373.417A2.128,2.128,0,0,1,24,7.49a2.223,2.223,0,0,1-.5,1.422l-.518.525-.116.113a29.575,29.575,0,0,1-7.855,5.03l-.325.117A6.012,6.012,0,0,1,13.411,15ZM2.005,9.4h0A1.956,1.956,0,0,1,0,7.5,1.956,1.956,0,0,1,2.005,5.6l4.932.41A1.534,1.534,0,0,1,8.509,7.5,1.534,1.534,0,0,1,6.937,8.988Z"
                                    transform="translate(0 9)"
                                    fill="currentColor"
                            />
                        </g>
                    </svg>
                    <b class="align-middle">تذكير</b>
                </h3>
                <form @submit.prevent="addReminder" novalidate class="mb-5">
                    <div class="row">
                        <div class="col-lg-6">
                            <label class="mb-1 text-muted" for="date">التاريخ</label>
                            <input
                                    v-model="reminder_date"
                                    type="date"
                                    id="date"
                                    required
                                    class="form-control py-2 mb-4"
                            />
                        </div>
                        <div class="col-lg-6">
                            <label class="mb-1 text-muted" for="message">الرسالة</label>
                            <select v-model="reminder_id" id="message" required class="form-select py-2 mb-4">
                                <option value="">اختر</option>
                                <option v-for="reminderItem in reminderOrders" :value="reminderItem.id">{{ reminderItem.message }}</option>
                            </select>
                        </div>
                    </div>
                    <button
                            type="submit"
                            class="btn btn-sm btn-arrow btn-primary"
                            style="min-width: 300px"
                    >
                        <span>إضافة</span>
                        <i class="bi bi-arrow-left"></i>
                    </button>
                </form>
                <div>
                    <h3 class="fs-5 fw-bold mb-3">سجل التذكيرات</h3>
                    <div class="row">
                        <div v-for="remenderItem in rent.reminderOrder" class="col-lg-6 mb-4">
                            <div
                                    class="d-flex gap-3 align-items-center border rounded-4 px-3 py-2"
                                >
                                <div class="px-2 py-1 rounded-3 bg-primary text-white fs-4">
                                    <i class="bi bi-app-indicator"></i>
                                </div>
                                <div>
                                    <small class="text-muted"
                                    >{{ remenderItem.message }}</small
                                    >
                                    <br/>
                                    <button @click.prevent="deleteReminder(rent.id, remenderItem.id, remenderItem.reminder_date)"
                                            class="btn btn-link bg-danger link-danger py-1 px-2"
                                            style="--bs-bg-opacity: 0.08"
                                            >
                                        <i class="bi bi-trash"></i>
                                    </button>
                                    <time class="fw-bold fs-5">{{ remenderItem.reminder_date }}</time>
                                </div>
                                <!-- <p class="mb-0 text-muted ms-auto">باقي : 2 يوم للتذكير</p> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
</template>

<script>
import axios from 'axios';

export default{
    name: 'SetReminder',
    data: function(){
        return {
            rent: {},
            reminderOrders: {},
            reminder_id: null,
            reminder_date: null,
            fullPage: false,
        }
    },
    created(){
        this.getRent();
        this.getReminderOrders();
    },
    methods: {
        getRent(){
            let url = `${localStorage.getItem('default_office')}/rentOrder/${this.$route.params.id}`;
            axios.get(url).then(response => {
                this.rent = response.data.data
            });
        },
        getReminderOrders(){
            let loader = this.$loading.show({
                color: '#0d6efd',
                loader: 'dots',
                backgroundColor: '#e4e4e4',
            });
            let url = `${localStorage.getItem('default_office')}/reminderOrder?noPaginate=true`;
            axios.get(url).then(response => {
                // console.log(response.data.data);
                this.reminderOrders = response.data.data
                loader.hide();
            });
        },
        addReminder(){
            let formData = new FormData();
            formData.append('rent_order_id', this.rent.id);
            formData.append('reminder_order_id', this.reminder_id);
            formData.append('reminder_date', this.reminder_date);
            let url = `${localStorage.getItem('default_office')}/rentOrder/setReminder`;
            axios.post(url, formData).then(response => {
                this.$swal.fire({
                            toast: true,
                            position: 'top',
                            icon: 'success',
                            title: 'تم إضافة تنبيه جديد',
                            showConfirmButton: false,
                            timer: 2500,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', Swal.stopTimer)
                                toast.addEventListener('mouseleave', Swal.resumeTimer)
                            }
                        })
                this.getRent();
            });
        },
        deleteReminder(rent_id, reminder_id, reminder_date){
            let formData = new FormData();
            formData.append('rent_order_id', rent_id);
            formData.append('reminder_order_id', reminder_id);
            formData.append('reminder_date', reminder_date);
            let url = `${localStorage.getItem('default_office')}/rentOrder/removeReminder`;
            this.$swal.fire({
            // toast: true,
            title: 'هل تريد الحذف',
            icon: 'question',
            iconHtml: '؟',
            confirmButtonText: 'نعم',
            cancelButtonText: 'لا',
            showCancelButton: true,
            showCloseButton: true
            }).then(result=>{
            if(result.isConfirmed){
                    axios.post(url, formData).then(response => {
                    // console.log(response);
                    this.getRent();
                    });
                }
            })
            
        }
    }
}
</script>

<style>

</style>