<template>
    <div
            class="d-flex flex-md-row flex-column gap-3 align-items-md-center justify-content-between"
          >
          <!-- <form @submit.prevent="filter" novalidate>
            <div
              class="d-flex align-items-sm-center flex-column flex-sm-row gap-2"
            >
              <input
                v-model="search"
                type="search"
                placeholder="بحث"
                class="form-control border-white"
              />
              <select
                v-model="sortField"
                class="form-select border-white w-auto"
                style="min-width: 120px"
              >
                <option value="">الفرز بـ</option>
                <option value="name">الرسالة</option>
                <option value="created_at">عدد التذكيرات</option>
              </select>
              <select
                v-model="sort"
                class="form-select border-white w-auto"
                style="min-width: 120px"
              >
                <option value="">الترتيب</option>
                <option value="DESC">الأخير</option>
                <option value="ASC">الأول</option>
              </select>
              <select
                class="form-select border-white w-auto"
                style="min-width: 120px"
              >
                <option value="">الحالة</option>
              </select>
              <button class="btn btn-primary">
                <i class="bi bi-search"></i>
              </button>
            </div>
          </form> -->
            <div>
              <!-- <button class="btn py-2 btn-success">
                <i class="bi bi-file-earmark-spreadsheet-fill fs-5"></i>
              </button> -->
              <router-link :to="{ name: 'AddReminderOrder' }" class="btn py-2 ms-1 btn-primary mb-2">
                <i class="bi bi-plus-circle fs-5"></i>
              </router-link>
            </div>
          </div>
          <div class="table-responsive-lg mt-4 d-none d-sm-block">
            <table class="table table-borderless bg-white rounded-4">
              <thead class="border-bottom">
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">الرسالة</th>
                  <!-- <th scope="col">عدد التذكيرات</th>
                  <th scope="col">التذكيرات المستخدمة</th>
                  <th scope="col">المتبقي</th> -->
                  <th scope="col">الحالة</th>
                  <th scope="col">الإفتراضي</th>
                  <th scope="col">التعديل</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item,index in reminderOrders.data" :key="item.id">
                  <th scope="row">{{ item.id }}</th>
                  <td v-text="item.message.slice(0, 50) + '...'"></td>
                  <!-- <td>{{ item.reminder_count }}</td>
                  <td>{{ item.used_count }}</td>
                  <td v-text="item.reminder_count - item.used_count"></td> -->
                  <td>
                    <small v-if="item.status == 0" class="px-2 py-1 bg-warning rounded-3 border border-warning text-warning" style="--bs-bg-opacity: 0.08; white-space: nowrap">جاري الإطلاع</small>
                    <small v-if="item.status == 1" class="px-3 py-1 bg-success rounded-3 border border-success text-success" style="--bs-bg-opacity: 0.08; white-space: nowrap">مقبول</small>
                    <small v-if="item.status == 2" class="px-3 py-1 bg-secondary rounded-3 border border-secondary text-secondary" style="--bs-bg-opacity: 0.08; white-space: nowrap">ملغي</small>
                    <small v-if="item.status == 3" class="px-3 py-1 bg-danger rounded-3 border border-danger text-danger" style="--bs-bg-opacity: 0.08; white-space: nowrap">مرفوض</small>
                  </td>
                  <td v-if="item.status == 0" class="actions">
                    <button
                      @click.prevent="cancelOrder(item.id)"
                      class="btn btn-link bg-success link-success py-1 px-2 me-2"
                      style="--bs-bg-opacity: 0.08"
                    >
                      <i class="bi bi-check-circle-fill"></i>
                    </button>
                    <button
                      class="btn btn-link bg-danger link-danger py-1 px-2"
                      style="--bs-bg-opacity: 0.08"
                    >
                      <i class="bi bi-x-circle-fill"></i>
                    </button>
                  </td>
                  <td >
                    <input type="hidden" v-if="item.default == 1 ? activeDefault = item.id : null" v-model="handelActiveDefault">
                    <input @click.prevent="updateOrder(item.id, item)" type="radio" id="default" name="default" value="1" :checked="item.default == 1">
                    <!-- <p v-if="item.default == 1">الافتراضي</p> -->
                    <!-- <td><button @click.prevent="updateOrder(item.id, item)">Update</button></td> -->
                  </td>
                  <td >
                    <router-link
                            :to="{ name: 'EditReminderOrder', params: {id: item.id} }"
                            class="btn btn-link bg-primary py-1 px-2 me-2"
                            style="--bs-bg-opacity: 0.08"
                    >
                        <i class="bi bi-pencil-square"></i>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="card bg-white rounded-2 border border-0 mb-2 d-lg-none .d-xl-block" v-for="item,index in reminderOrders.data" :key="item.id">
          <div class="card-header bg-white">
            
            <div class="row">
              <div class="col-5">
                ( {{ index + 1 }} )
              </div>
              <div class="col-2">
                <router-link
                            :to="{ name: 'EditReminderOrder', params: {id: item.id} }"
                            class="btn btn-link bg-primary py-1 px-2 me-2"
                            style="--bs-bg-opacity: 0.08"
                    >
                        <i class="bi bi-pencil-square"></i>
                    </router-link>
              </div>
              <div class="col-2">
                    <input type="hidden" v-if="item.default == 1 ? activeDefault = item.id : null" v-model="handelActiveDefault">
                    <input @click.prevent="updateOrder(item.id, item)" type="radio" id="defaultMobile" name="defaultMobile" value="1" :checked="item.default == 1">
                    <!-- <p v-if="item.default == 1">الافتراضي</p> -->
                    <!-- <td><button @click.prevent="updateOrder(item.id, item)">Update</button></td> -->
              </div>
              <div class="col-3">
                    <small v-if="item.status == 0" class="px-2 py-1 bg-warning rounded-3 border border-warning text-warning" style="--bs-bg-opacity: 0.08; white-space: nowrap">جاري الإطلاع</small>
                    <small v-if="item.status == 1" class="px-3 py-1 bg-success rounded-3 border border-success text-success" style="--bs-bg-opacity: 0.08; white-space: nowrap">مقبول</small>
                    <small v-if="item.status == 2" class="px-3 py-1 bg-secondary rounded-3 border border-secondary text-secondary" style="--bs-bg-opacity: 0.08; white-space: nowrap">ملغي</small>
                    <small v-if="item.status == 3" class="px-3 py-1 bg-danger rounded-3 border border-danger text-danger" style="--bs-bg-opacity: 0.08; white-space: nowrap">مرفوض</small>
              </div>
              
            </div>
          </div>
          <div class="card-body">
            <blockquote class="blockquote mb-0">
                 <div class="text-primary">رقم الطلب <span class="text-dark">{{ item.id }}</span></div>      
                 <div class="text-primary">الرسالة <span class="text-dark" v-text="item.message.slice(0, 50) + '...'"></span></div>  
                 <!-- <div class="text-primary">عدد التذكيرات <span class="text-dark">{{ item.reminder_count }}</span></div>    
                 <div class="text-primary">المستخدم <span class="text-dark">{{ item.used_count }}</span></div>    
                 <div class="text-primary">المتبقي <span class="text-dark" v-text="item.reminder_count - item.used_count"></span></div>     -->
            </blockquote>
          </div>
        </div>
        <div>
          <Pagination :data="reminderOrders" align="right" @pagination-change-page="getReminderOrders" />
        </div>
</template>

<script>
import LaravelVuePagination from 'laravel-vue-pagination'
import axios from 'axios';

export default{
    name: 'IndexReminderOrder',
    components: {
      'Pagination': LaravelVuePagination
    },
    data: function(){
        return {
            reminderOrders: {},
            reminderOrder: {},
            fullPage: false,
            paginateNumber: 20,
            search:'',
            sortField: 'created_at',
            sort: 'DESC',
            activeDefault: null,
        }
    },
    created(){
        this.getReminderOrders();
    },
    computed: {
      handelActiveDefault(){
        this.activeDefault = 1
      },
    },
    methods: {
        async getReminderOrders(page = 1){
            let loader = this.$loading.show({
                color: '#0d6efd',
                loader: 'dots',
                backgroundColor: '#e4e4e4',
            });
            let url = `${localStorage.getItem('default_office')}/reminderOrder?page=${page}`;
            await axios.get(url).then(response => {
                this.reminderOrders = response.data;
                // console.log(response)
                loader.hide();
            });
        },

        async updateOrder(id, item){
          try {
              axios.get(`${localStorage.getItem('default_office')}/reminderOrderActive`).then(response => {
                if(response.data.message == 'success'){
                  let url = `${localStorage.getItem('default_office')}/reminderOrder/${id}`;
                    axios.put(url, {
                    "reminder_count" : item.reminder_count,
                    "message" : item.message,
                    "used_count" : item.used_count,
                    "status" : item.status,
                    "default" : 1,
                    })
                    this.getReminderOrders();
                }
              })
              
            
          } catch (error) {
            // console.log(error)
          }
        },

        async findDefault(){
          
        },

        async getReminderOrder(id){
            
        },

        async cancelOrder(id){

            let url = `${localStorage.getItem('default_office')}/reminderOrder/${id}`;
            await axios.get(url).then(response => {
                this.reminderOrder = response.data.data;
                this.reminderOrder.status = 2;
                this.$swal.fire({
                toast: true,
                title: 'هل تريد الغاء الطلب',
                icon: 'question',
                iconHtml: '؟',
                confirmButtonText: 'نعم',
                cancelButtonText: 'لا',
                showCancelButton: true,
                showCloseButton: true
                }).then(result=>{
                if(result.isConfirmed){
                    axios.put(url, this.reminderOrder).then(response => {
                    this.getReminderOrders();
                });
                }
                })
            });
        },
        async filter(page = 1){
          const response = await axios.get(`${localStorage.getItem('default_office')}/reminderOrderFilter?paginateNumber=` + this.paginateNumber + '&sortField=' + this.sortField + '&sort=' + this.sort + '&search=' + this.search + '&page=' + page)
          this.reminderOrders = response.data
        },
    },
    
}
</script>

<style scoped>
    .refuse{
        color:red,
    }
</style>