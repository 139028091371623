<template>
<div class="container mx-auto mt-0 bg-clip-content p-6  border-1 border-gray-100 border-dashed">
    <div class="p-6">
        <form @submit.prevent="addContact">
            <div class="mb-3">
                <label for="mail" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">الايميل<strong class="text-red-500">*</strong></label>
                <input type="email" placeholder="" id= "id_card" v-model="contact.email" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">

            </div>
            <div class="mb-6">
                <label for="type" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">نوع التواصل<strong class="text-red-500">*</strong></label>
                <select v-model="contact.contact_type" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    <option value="support">دعم</option>
                    <option value="suggest">اقتراح</option>
                </select>
            </div>
            <div class="mb-6">
                
                <label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">الرسالة<strong class="text-red-500">*</strong></label>
                <textarea id="message" v-model="contact.message" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="...اترك رسالتك"></textarea>

            </div>

            <div class="mb-6">
                
            </div>
            <div>
                <button type="submit" class="mr-3 inline-block px-6 py-3 font-bold text-center bg-gradient-to-tl from-purple-700 to-pink-500 uppercase align-middle transition-all rounded-lg cursor-pointer leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs text-white">إرسال</button>
            </div>
        </form>
    </div>
</div>

</template>

<script>
import axios from 'axios'

export default{
    name: 'EditContact',
    data(){
        return {
            contact: {},
            fullPage: false,
        }
    },
    created(){
        let loader = this.$loading.show({
            color: '#0d6efd',
            loader: 'dots',
            backgroundColor: '#e4e4e4',
        });
        loader.hide();
    },
    methods: {
        editContact(){
            let formData = new FormData();
                formData.append('email', this.contact.email);
                formData.append('contact_type', this.contact.contact_type);
                formData.append('message', this.contact.message);
                let url = `${localStorage.getItem('default_office')}/contact`;
                axios.post(url, formData).then(response => {
                    // console.log(response.data.data);
                    this.$swal.fire({
                                toast: false,
                                position: 'top',
                                icon: 'success',
                                title: 'تم إرسال طلبك رقم ' + response.data.data.id,
                                showConfirmButton: true,
                                // timer: 2500,
                                // timerProgressBar: true,
                                // didOpen: (toast) => {
                                //     toast.addEventListener('mouseenter', Swal.stopTimer)
                                //     toast.addEventListener('mouseleave', Swal.resumeTimer)
                                // }
                            })
                            this.reset();
                    });
        },
        reset(){
            this.contact.email = '';
            this.contact.contact_type = '';
            this.contact.message = '';
        }
    }
}
</script>

<style>

</style>