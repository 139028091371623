<template>
  <div class="bg-white px-4 pt-4 pb-5 rounded-4 mb-3">
          <h3 class="fs-5 mb-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              width="1em"
              viewBox="0 0 24 15"
            >
              <g
                id="Arrow_-_Right"
                data-name="Arrow - Right"
                transform="translate(0 -9)"
              >
                <path
                  id="Arrow_-_Right-2"
                  data-name="Arrow - Right"
                  d="M13.411,15h0a2.824,2.824,0,0,1-1.213-.275A2.41,2.41,0,0,1,11.12,13.6a11.846,11.846,0,0,1-.348-1.331,25.874,25.874,0,0,1-.323-4.314l0-.442a32.417,32.417,0,0,1,.288-4.61l.151-.68a4.454,4.454,0,0,1,.311-.98A2.434,2.434,0,0,1,13.334,0h.077A6.865,6.865,0,0,1,15.2.509,30.162,30.162,0,0,1,22.66,5.246l.505.5a4.551,4.551,0,0,1,.373.417A2.128,2.128,0,0,1,24,7.49a2.223,2.223,0,0,1-.5,1.422l-.518.525-.116.113a29.575,29.575,0,0,1-7.855,5.03l-.325.117A6.012,6.012,0,0,1,13.411,15ZM2.005,9.4h0A1.956,1.956,0,0,1,0,7.5,1.956,1.956,0,0,1,2.005,5.6l4.932.41A1.534,1.534,0,0,1,8.509,7.5,1.534,1.534,0,0,1,6.937,8.988Z"
                  transform="translate(0 9)"
                  fill="currentColor"
                />
              </g>
            </svg>
            <li v-for="error in errors" class="text-danger fs-6">{{ error[0] }}</li>
            <b class="align-middle">عقد خدمات مع مالك الوحدة العقارية</b>
          </h3>
          <form @submit.prevent="storeContract()" novalidate style="max-width: 580px">
            <label class="mb-1 text-muted" for="owner">المالك</label>
            <select v-model="contract.owner_id" @change="onChangeOwner" type="text" id="owner" class="form-select py-2 mb-4">
              <option value="">اختر</option>
              <option v-for="item in owners" :key="item.id" :value="item.id">{{ item.name }}</option>
            </select>
            <label class="mb-1 text-muted" for="unit">الوحدة العقارية</label>
            <select v-model="contract.realestate" type="text" id="unit" class="form-select py-2 mb-4">
              <option value="">اختر</option>
              <option  v-for="(item, index) in realestates.realestates" :value="item.name" :key="item.id">{{ (index+1) + '- ' + item.name }}</option>
            </select>
            <label class="mb-1 text-muted" for="type">نوع الخدمة</label>
            <select v-model="contract.service_id" type="text" id="type" class="form-select py-2 mb-4">
              <option value="">اختر</option>
              <option  v-for="(service, index) in services" :value="service.id" :key="service.id">{{ service.name }}</option>
            </select>
            <label class="mb-1 text-muted" for="value">قيمة الخدمة</label>
            <input
              v-model="contract.price"
              type="text"
              id="value"
              placeholder="قيمة الخدمة"
              class="form-control py-2 mb-4"
            />
            <label class="mb-1 text-muted" for="from">الفترة من</label>
            <input v-model="contract.date_from" type="date" id="from" class="form-control py-2 mb-4" />
            <label class="mb-1 text-muted" for="to">الفترة إلى</label>
            <input v-model="contract.date_to" type="date" id="to" class="form-control py-2 mb-4" />
            <div class="d-flex gap-2 pt-2">
              <button
                type="submit"
                class="btn btn-sm btn-arrow btn-primary w-100"
              >
                <span>حفظ</span>
                <i class="bi bi-arrow-left"></i>
              </button>
              <button
                @click.prevent="cancel"
                type="button"
                class="btn btn-sm btn-outline-primary w-100"
              >
                رجوع
              </button>
            </div>
          </form>
        </div>
</template>
<script>

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import axios from 'axios';
import Select2 from 'vue3-select2-component';
import moment from 'moment'

export default{
  name: 'AddRentOrder',
  components: { Datepicker, Select2 },
  data(){
      return {
           dateFrom: {},
           dateTo: {},
           owners: {},
           services: {},
           realestates: {},
           contract:{},
           fullPage: false,
           myValue: '',
           errors: [],
           myOptions: ['op1', 'op2', 'op3'] // or [{id: key, text: value}, {id: key, text: value}]
      }
  },
  created(){
      this.getOwners();
      this.getServices();
      this.getRealestates();
  },
  methods: {
      dateTime(){
        return moment().format('YYYY-MM-DD')
      },
      async getOwners(){
          let loader = this.$loading.show({
              color: '#0d6efd',
              loader: 'dots',
              backgroundColor: '#e4e4e4',
          });
          let url = `${localStorage.getItem('default_office')}/owner?noPaginate=true`;
          await axios.get(url)
          .then(response => {
              this.owners = response.data.data;
              // console.log(response.data.data);
              loader.hide();
          });
      },

      async getServices(){
          const response = await axios.get(`${localStorage.getItem('default_office')}/service`)
          this.services = response.data.data
          
      },

      async getRealestates(){
          const response = await axios.get(`${localStorage.getItem('default_office')}/realestate`)
          this.realestates = response.data.data
          // console.log(this.realestates)
      },

      async storeContract(){
          try {
            this.errors = []

            if(this.contract.date_from <= this.dateTime()){
            this.$swal.fire({
                  toast: true,
                  position: 'top',
                  icon: 'error',
                  title: ' تاريخ الفترة من، يجب ان يكون اكبر من تاريخ اليوم',
                  showConfirmButton: false,
                  // timer: 2500,
                  timerProgressBar: false,
                  didOpen: (toast) => {
                      toast.addEventListener('mouseenter', Swal.stopTimer)
                      toast.addEventListener('mouseleave', Swal.resumeTimer)
                  }
            });

            
            
        }else{
          if(this.contract.date_to < this.contract.date_from){
            this.$swal.fire({
                  toast: true,
                  position: 'top',
                  icon: 'error',
                  title: ' تاريخ الفترة إلى، يجب ان يكون اكبر من تاريخ الفترة من',
                  showConfirmButton: false,
                  // timer: 2500,
                  timerProgressBar: false,
                  didOpen: (toast) => {
                      toast.addEventListener('mouseenter', Swal.stopTimer)
                      toast.addEventListener('mouseleave', Swal.resumeTimer)
                  }
            });
          }else{

            const formData = new FormData();
            let url = `${localStorage.getItem('default_office')}/contract`;
            await axios.post(url, {
                'owner_id': this.contract.owner_id,
                'service_id': this.contract.service_id,
                'realestate': this.contract.realestate,
                'price': this.contract.price,
                'date_from': this.contract.date_from,
                'date_to': this.contract.date_to,
            }).then(response => {
                this.$swal.fire({
                toast: true,
                position: 'top',
                icon: 'success',
                title: 'تم حفظ البيانات بنجاح',
                showConfirmButton: false,
                timer: 2500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            });
            this.reset();
            this.$router.push('/contract')
            })


          }
        }


            
          } catch (error) {
            this.errors = error.response.data.errors
          }
      },

      reset(){
          this.dateFrom = '';
          this.dateTo = '';
      },

      async onChangeOwner(){
          const response = await axios.get(`${localStorage.getItem('default_office')}/owner/${this.contract.owner_id}`)
          this.realestates = response.data.data
          // console.log(response)
      },

      async onChangeRealestate(){
          if(this.realestate_type == 'building'){
              const response = await axios.get(`${localStorage.getItem('default_office')}/building`)
              this.realestates = response.data.data
              // console.log(response)
          }else if(this.realestate_type == 'appartment'){
              const response = await axios.get(`${localStorage.getItem('default_office')}/appartment`)
              this.realestates = response.data.data
          }else if(this.realestate_type == 'shop'){
              const response = await axios.get(`${localStorage.getItem('default_office')}/shop`)
              this.realestates = response.data.data
          }else if(this.realestate_type == 'villa'){
              const response = await axios.get(`${localStorage.getItem('default_office')}/villa`)
              this.realestates = response.data.data
          }
      },
      cancel(){
          history.back();
      }
  }
}
</script>

<style>

</style>
